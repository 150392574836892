import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../app/modules/auth';

function PolicyChecker({ policies, children }) {
    const { currentUser } = useAuth();
    const [userPolicies, setUserPolicies] = useState([]);

    useEffect(() => {
        if (currentUser && currentUser.policyIds) {
            setUserPolicies(currentUser.policyIds);
        }
    }, [currentUser]);

    if (userPolicies.some(policy => policies.includes(policy))) {
        return children;
    }

    return null;
}

export default PolicyChecker;