// @flow
import types from './profileConstants';

const INIT_STATE = {
    profile: {},
    loading: false,
    success: null
};

const Profile = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //Get Profile
        case types.GET_PROFILE:
            return {
                ...state,
                loading: true
            };
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_PROFILE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Profile

        //Change Password
        case types.PATCH_PROFILE_PASSWORD:
            return {
                ...state,
                loading: true
            };
        case types.PATCH_PROFILE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'PASSWORD.CHANGE.SUCESS',
                error: null
            };
        case types.PATCH_PROFILE_PASSWORD_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Change Password

        default:
            return state;
    }
};

export default Profile;
