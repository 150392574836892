// @flow
import types from '../localization/localizationConstants';

const INIT_STATE = {
    items: [],
    contents: [],
    loading: false,
    success: null,
    error: null
};

const Localization = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }

        case types.GET_LOOKUPS:
        case types.GET_CATEGORIES:
        case types.GET_OPTIONS:
        case types.GET_RESOURCES:
            return {
                ...state,
                items: action.payload.data,
                loading: false
            };

        case types.PATCH_RESOURCES:
        case types.POST_RESOURCES:
            return {
                ...state,
                success: null,
                loading: true
            };

        case types.PATCH_RESOURCES_DONE:
        case types.POST_RESOURCES_DONE:
            return {
                ...state,
                success: action.payload.isSuccess ? "GENERAL.SUCCESS" : "GENERAL.FAIL",
                loading: false
            };

        case types.GET_CONTENTS:
            return {
                ...state,
                contents: action.payload.data,
                loading: false
            };

        default:
            return state;
    }
};

export default Localization;
