export enum enumProductTypes {
    Goot = 1,
    Muurplaat = 2,
    Frontpaneel_Cube = 3,
    Frontpaneel_Deluxe = 4,
    Staander = 5,
    Sierklik = 6,
    Middenligger = 7,
    Middenligger_versterkt = 8,
    Zijligger = 9,
    Middenligger_Deksel = 10,
    Zijligger_Deksel = 11,
    Goot_Zijkap_Classic = 12,
    Muurplaat_Zijkap = 13,
    F_profiel = 14,
    Ligger_Deksel = 15,
    Glazen_Schuifwand_railset_3_spoors = 16,
    Glazen_Schuifwand_railset_4_spoors = 17,
    Glazen_Schuifwand_railset_5_spoors = 18,
    Glazen_Schuifwand_railset_6_spoors = 19,
    Handvat = 20,
    Tochtstrip = 27,
    Frontglass = 28,
    Muurplaat_Rubber = 29,
    Bladvanger = 30,
    Schroef = 31,
    Polycarbonaat = 33,
    Led_Normaal = 34,
    Led_Dimbaar = 35,
    Zonwering = 36,
    Spie_Glas = 38,
    Spie_Polycarbonaat = 39,
    Glazen_Schuifwand = 40,
    Schuifpui = 41,
    Vast_Kozijn = 42,
    Aluminium_Rabat = 43,
    Geheel_Polycarbonaat = 44,
    RS_Screen = 45,
    Pergola = 46,
    Frontpaneel_Classic = 47,
    Led_Smart_Google = 48,
    Led_Smart_Somfy = 49,
    Guillotine_Glassysteem = 50,
    Meenemer = 51,
    H_profiel_incl_wiel = 52,
    Helder_glas_10mm = 53,
    Getint_glas_10mm = 54,
    L_profiel = 55,
    H_profiel = 56,
    Koker_5x10_cm = 57,
    Funderingskoker_4x13_cm = 58,
    Statiekkoker = 59,
    Statiekkoker_Sierklik = 60,
    Aluminium_rabatdelen = 61,
    Sandwichpanelen = 62,
    U_profiel_sandwichpanelen = 63,
    Helder_glas_4_4_2 = 64,
    Opaal_glas_4_4_2 = 65,
    Getint_glas_4_4_2 = 66,
    Dubel_glas = 67,
    Modelglas = 68,
    Voetanker = 69,
    Gootverbindstuk = 70,
    Led_Dimbaar_Controller = 71,
    Glas_Profiel_Wiel = 72,
    Glas_Onder_Profiel = 73,
    Glas_Onder_Profiel_Zijkap = 74,
    Goot_Zijkap_Deluxe = 75,
    Goot_Zijkap_Cube = 76,
};
