import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const GET_USER_URL = `${API_URL}/auth/user`
export const LOGIN_URL = `${API_URL}/auth`
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/change-password`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const ADDRESS_VERIFY_URL = `${API_URL}/verifies`

// Server should return AuthModel
export function login(email: string, password: string, captcha: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    captcha
  })
}

export function changePass(email: string, tempPassword: string, password: string, captcha: string) {
  return axios.post<AuthModel>(CHANGE_PASSWORD_URL, {
    email,
    tempPassword,
    password,
    captcha
  })
}

// Server should return AuthModel
export function register(data) {
  return axios.post(REGISTER_URL, data)
}

export function addressVerify(country, postalcode) {
  return axios.get(`${ADDRESS_VERIFY_URL}?countryId=${country}&postalCode=${postalcode}`)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, captcha: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email, captcha
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function getUser() {
  return axios.get<UserModel>(GET_USER_URL)
}
