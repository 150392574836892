import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Loader, KTIcon } from '../../../../_metronic/helpers'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import Barcode from 'react-barcode'
import ReactToPrint from "react-to-print";
import { clearState, getVariant, getVariantList, getSuppliers } from './stockActions';

const Print = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    const navigate = useNavigate();

    //inputs from redux
    const {
        variant,
        loading,
        variantList,
        suppliers,
        success
    } = props;

    //actions
    const {
        clearState,
        getVariant,
        getVariantList,
        getSuppliers
    } = props;

    const componentRef = useRef();
    const [supplierCode, setSupplierCode] = useState('');

    useEffect(() => {
        if (params.id > 0) {
            getVariant(params.id);
        }

        getVariantList();


        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (variant && variant.id > 0) {
            getSuppliers(variant.productId)
            setSupplierCode('');
        }
    }, [variant])

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'STOCK.MANAGEMENT.TITLE' }),
            path: '/stock-management/stocks',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const ComponentToPrint = React.forwardRef((props, ref) => {
        const { code } = props;
        return (
            <div className="card preview-container" ref={ref}>
                <div className='card-body text-center'>
                    <Row>
                        <Col>
                            <h3>{intl.formatMessage({ id: 'STOCK.FIELD.VARIANTCODE' })}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Barcode value={code} />
                        </Col>
                    </Row>
                    {supplierCode ? <><Row className='mt-10'><Col><h3>Supplier Code</h3></Col></Row><Row><Col><Barcode value={supplierCode} /></Col></Row></> : <></>}
                </div>
            </div>
        );
    });

    const onDownloadClick = (name) => {
        const pageHTML = document.querySelector(".preview-container").outerHTML;
        const blob = new Blob([pageHTML], { type: "text/html" });
        const url = URL.createObjectURL(blob);
        const tempEl = document.createElement("a");
        document.body.appendChild(tempEl);
        tempEl.href = url;
        tempEl.download = name + ".html";
        tempEl.click();
        setTimeout(() => {
            URL.revokeObjectURL(url);
            tempEl.parentNode.removeChild(tempEl);
        }, 2000);
    }

    const onSupplierChange = (value) => {
        setSupplierCode(value);
    }

    const onVariantChange = (value) => {
        getVariant(value);
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'STOCK.ITEM.BREADCRUMB' })}</PageTitle>
            <Card>
                <CardHeader>
                    <CardTitle>
                        {intl.formatMessage({ id: 'STOCK.FIELD.PRINTBARCODE' })}
                    </CardTitle>
                    <div className="card-toolbar">
                        <ReactToPrint
                            trigger={() => <button className="btn btn-light-info m-1" type='button' > <KTIcon iconName='printer' className='fs-2x'></KTIcon> {intl.formatMessage({ id: 'GENERAL.PRINT' })}</button>}
                            content={() => componentRef.current}
                        />

                        <button onClick={() => onDownloadClick(variant.code)} className="btn btn-light-info m-1" type='button' > <KTIcon iconName='cloud-download' className='fs-2x'></KTIcon> {intl.formatMessage({ id: 'GENERAL.DOWNLOAD' })}</button>
                    </div>
                </CardHeader>
                <CardBody>
                    {/* <button onClick={() => navigate(-1)} type="button" className="btn btn-light mb-10"><KTIcon iconName='arrow-left' className='fs-2x'></KTIcon></button> */}

                    <Row>
                        <Col>
                            <div className='fv-row mb-2'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                {intl.formatMessage({ id: 'STOCK.FIELD.VARIANTS' })}
                                </label>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    onChange={(e) => onVariantChange(e.target.value)}
                                >
                                    <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                    {variantList && variantList.sort(function (a, b) {
                                        const bandA = a.productName + " - " + a.name;
                                        const bandB = b.productName + " - " + b.name;

                                        let comparison = 0;
                                        if (bandA > bandB) {
                                            comparison = 1;
                                        } else if (bandA < bandB) {
                                            comparison = -1;
                                        }
                                        return comparison;
                                    }).map((item, index) => {
                                        return (
                                            <option key={`variants_${item.id}`} value={item.id} selected={params.id == item.id} >{item.productName} - {item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className='fv-row mb-2'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                {intl.formatMessage({ id: 'STOCK.FIELD.SUPPLIER' })}
                                </label>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    onChange={(e) => onSupplierChange(e.target.value)}
                                >
                                    <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                    {suppliers && suppliers.map((item, index) => {
                                        return (
                                            <option key={`suppler_${item.id}`} value={item.code}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </Col>
                    </Row>


                    <ComponentToPrint ref={componentRef} code={variant.code} />
                </CardBody>
            </Card>
            {loading && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        variant: state.Stock.variantPrint,
        suppliers: state.Stock.suppliers,
        variantList: state.Stock.variantList,
        loading: state.Stock.loading,
        success: state.Stock.success
    };
};

const mapActionsToProps = {
    clearState, getVariant, getSuppliers, getVariantList
}

export default connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true })(Print)

