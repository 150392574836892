/* PROFILE */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'PROFILE/CLEAR_STATE',

    GET_PROFILE: 'PROFILE/GET_PROFILE',
    GET_PROFILE_SUCCESS: 'PROFILE/GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILED: 'PROFILE/GET_PROFILE_FAILED',

    PATCH_PROFILE_PASSWORD: 'PROFILE/PATCH_PROFILE_PASSWORD',
    PATCH_PROFILE_PASSWORD_SUCCESS: 'PROFILE/PATCH_PROFILE_PASSWORD_SUCCESS',
    PATCH_PROFILE_PASSWORD_FAILED: 'PROFILE/PATCH_PROFILE_PASSWORD_FAILED',
});

export default actionTypes;