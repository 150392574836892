// @flow
import types from './productConstants';

const INIT_STATE = {
    productList: [],
    product: {},
    variants: [],
    images: [],
    uploadedImageId: null,
    optionValues: [],
    categories: [],
    suppliers: [],
    models: [],
    productTypes: [],
    loading: false,
    success: null,
    error: null
};

const Product = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //List
        case types.GET_PRODUCTS:
            return {
                ...state,
                loading: true
            };
        case types.GET_PRODUCTS_SUCCESS:

            return {
                ...state,
                productList: action.payload.data.items,
                loading: false,
                error: null
            };
        case types.GET_PRODUCTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //Change Status
        case types.PATCH_PRODUCT_STATUS:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_PRODUCT_STATUS_SUCCESS:

            var list = [...state.productList].map((item, index) => {
                if (item.id == action.payload.id) {
                    return {
                        ...item,
                        isActive: action.payload.isActive
                    }
                }
                return item;
            });

            return {
                ...state,
                productList: list,
                loading: false,
                success: 'PRODUCT.STATUS.UPDATE.SUCESS',
                error: null
            };
        case types.PATCH_PRODUCT_STATUS_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Change Status

        //product
        case types.GET_PRODUCT:
            return {
                ...state,
                loading: true
            };
        case types.GET_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload.data,
                variants: action.payload.data.variants,
                images: action.payload.data.images,
                loading: false,
                error: null
            };
        case types.GET_PRODUCT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case types.POST_PRODUCT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_PRODUCT_SUCCESS:
            return {
                ...state,
                success: 'PRODUCT.CREATED.SUCESS',
                product: action.payload,
                loading: false,
                error: null
            };
        case types.POST_PRODUCT_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        case types.POST_PRODUCT_CLONE:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_PRODUCT_CLONE_SUCCESS:
            return {
                ...state,
                success: 'PRODUCT.CLONE.SUCESS',
                // product: action.payload,
                loading: false,
                error: null
            };
        case types.POST_PRODUCT_CLONE_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        case types.PATCH_PRODUCT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_PRODUCT_SUCCESS:
            return {
                ...state,
                success: 'PRODUCT.UPDATED.SUCESS',
                loading: false,
                error: null
            };
        case types.PATCH_PRODUCT_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //product

        //productimage
        case types.POST_PRODUCT_IMAGE:
            return {
                ...state,
                success: null,
                uploadedImageId: null,
                loading: true
            };
        case types.POST_PRODUCT_IMAGE_SUCCESS:
            return {
                ...state,
                uploadedImageId: action.payload,
                success: 'PRODUCT.IMAGE.UPLOAD.SUCESS',
                loading: false,
                error: null
            };
        case types.POST_PRODUCT_IMAGE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                uploadedImageId: null,
                loading: false
            };
        case types.GET_PRODUCT_IMAGES:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.GET_PRODUCT_IMAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                images: action.payload,
                error: null
            };
        case types.GET_PRODUCT_IMAGES_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        case types.DELETE_PRODUCT_IMAGE:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.DELETE_PRODUCT_IMAGE_SUCCESS:
            var list = [...state.images].filter(s => s.id != action.payload.id);
            return {
                ...state,
                loading: false,
                images: list,
                success: 'PRODUCT.IMAGE.DELETED.SUCESS',
                error: null
            };
        case types.DELETE_PRODUCT_IMAGE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        case types.PATCH_PRODUCT_IMAGE:
            return {
                ...state,
                loading: true
            };
        case types.PATCH_PRODUCT_IMAGE_SUCCESS:
        case types.PATCH_PRODUCT_IMAGE_FAILED:
            return {
                ...state,
                loading: false
            };
        //productimage

        //variant
        case types.GET_VARIANTS:
            return {
                ...state,
                loading: true
            };
        case types.GET_VARIANTS_SUCCESS:
            return {
                ...state,
                variants: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_VARIANTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.POST_VARIANT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_VARIANT_SUCCESS:
            return {
                ...state,
                success: 'VARIANT.CREATED.SUCESS',
                loading: false,
                error: null
            };
        case types.POST_VARIANT_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        case types.PATCH_VARIANT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_VARIANT_SUCCESS:
            return {
                ...state,
                success: 'VARIANT.UPDATED.SUCESS',
                loading: false,
                error: null
            };
        case types.PATCH_VARIANT_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //variant

        case types.GET_OPTION_VALUES:
            return {
                ...state,
                optionValues: action.payload.data,
                //loading: false,
                error: null
            };
        case types.GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload.data,
                //loading: false,
                error: null
            };
        case types.GET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.payload.data.items,
                //loading: false,
                error: null
            };
        case types.GET_MODELS:
            return {
                ...state,
                models: action.payload.data.items,
                //loading: false,
                error: null
            };
        case types.GET_LOOKUP_VALUES:
            return {
                ...state,
                productTypes: action.payload.data,
                //loading: false,
                error: null
            };
        default:
            return state;
    }
};

export default Product;
