// @flow
import types from './checkoutConstants';
import { getAsync, postAsync, deleteAsync, upload } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getForm = () => async dispatch => {

    dispatch({
        type: types.GET_FORM
    })

    const response = await getAsync(`/checkout`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_FORM_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_FORM_FAILED,
            payload: response,
        })
    }
}

export const getCart = () => async dispatch => {

    dispatch({
        type: types.GET_CART
    })

    const response = await getAsync(`/carts`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CART_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_CART_FAILED,
            payload: response,
        })
    }
}

export const postCart = (stringModel) => async dispatch => {

    dispatch({
        type: types.POST_CART
    })

    const response = await postAsync(`/carts`, { data: stringModel });


    if (response.isSuccess) {
        dispatch(getCart())
        dispatch(getCartSummary())
        dispatch({
            type: types.POST_CART_SUCCESS,
            payload: JSON.parse(response.data.data),
        })
    } else {
        dispatch({
            type: types.POST_CART_FAILED,
            payload: response,
        })
    }

    return response;
}

export const postCalculateCart = (stringModel) => async dispatch => {

    dispatch({
        type: types.POST_CART
    })

    const response = await postAsync(`/carts/calculate`, { data: stringModel });


    if (response.isSuccess) {
        dispatch({
            type: types.POST_CART_SUCCESS,
            payload: JSON.parse(response.data.data),
        })
    } else {
        dispatch({
            type: types.POST_CART_FAILED,
            payload: response,
        })
    }

    return response;
}

export const getCartSummary = (dealerId) => async dispatch => {

    dispatch({
        type: types.GET_CART_SUMMARY
    })

    const response = await getAsync(`/carts/summary?dealerId=${dealerId}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CART_SUMMARY_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_CART_SUMMARY_FAILED,
            payload: response,
        })
    }
}

export const deleteItem = (uuid) => async dispatch => {

    dispatch({
        type: types.DELETE_CART_ITEM
    })

    const response = await deleteAsync(`/carts/${uuid}`);

    if (response.isSuccess) {
        dispatch(getCart())
        dispatch(getCartSummary())
        dispatch({
            type: types.DELETE_CART_ITEM_SUCCESS,
            payload: response.data,
        })
    } else {
        dispatch({
            type: types.DELETE_CART_ITEM_FAILED,
            payload: response,
        })
    }

    return response;
}

export const deleteCart = () => async dispatch => {

    dispatch({
        type: types.DELETE_CART
    })

    const response = await deleteAsync(`/carts`);

    if (response.isSuccess) {
        dispatch({
            type: types.DELETE_CART_SUCCESS,
            payload: response.data,
        })
    } else {
        dispatch({
            type: types.DELETE_CART_FAILED,
            payload: response,
        })
    }

    return response;
}

export const uploadFile = (file) => async dispatch => {

    var request = {
        fileName: file.name,
        fileExtension: file.extension,
        actionType: file.actionType
    };

    const response = await postAsync(`/signed-url/upload`, request);

    var data = {
        id: file.id,
        file: file.file
    }

    if (response.isSuccess) {
        var x = await upload(response.data.url, data);
        return response;
    }

    return response;
}

export const postOrder = (cart) => async dispatch => {
    dispatch({
        type: types.POST_ORDER
    })

    const response = await postAsync(`/orders`, cart);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_ORDER_SUCCESS,
            payload: response.data,
        })
    } else {
        dispatch({
            type: types.POST_ORDER_FAILED,
            payload: response,
        })
    }
}



export const getPaymentMethods = () => async dispatch => {

    dispatch({
        type: types.GET_PAYMENT_METHODS
    })

    const response = await getAsync(`/payments/methods`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_PAYMENT_METHODS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_PAYMENT_METHODS_FAILED,
            payload: response,
        })
    }
}

export const thanksPage = (id) => async dispatch => {

    dispatch({
        type: types.GET_THANKS_PAGE
    })

    const response = await getAsync(`/thanks-page/${id}`, false);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_THANKS_PAGE_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_THANKS_PAGE_FAILED,
            payload: response,
        })
    }
}

export const getCheckoutPay = (boid) => async dispatch => {
    dispatch({
        type: types.GET_CHECKOUT_PAY
    })

    var path = `/checkout/pay`;
    if (boid)
        path += `?boid=${boid}`

    const response = await getAsync(path);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CHECKOUT_PAY_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_CHECKOUT_PAY_FAILED,
            payload: response,
        })
    }
}

export const postCheckoutPay = (paymentMethod) => async dispatch => {

    dispatch({
        type: types.POST_CHECKOUT_PAY
    })

    const response = await postAsync(`/checkout/pay?paymentMethod=${paymentMethod}`);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_CHECKOUT_PAY_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.POST_CHECKOUT_PAY_FAILED,
            payload: response,
        })
    }
}