// @flow
import types from '../supplier/supplierConstants';
import { deleteAsync, getAsync, patchAsync, postAsync, uploadAsync } from '../../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getSupplierList = (keyword = '') => async dispatch => {

    dispatch({
        type: types.GET_SUPPLIERS
    })

    const response = await getAsync(`/suppliers?keyword=${keyword}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_SUPPLIERS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_SUPPLIERS_FAILED,
            payload: response,
        })
    }
}

export const changeStatus = (id, isActive) => async dispatch => {

    dispatch({
        type: types.PATCH_SUPPLIER_STATUS
    })

    var request = {
        isActive: isActive
    };

    const response = await patchAsync(`/suppliers/${id}`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_SUPPLIER_STATUS_SUCCESS,
            payload: { id, isActive }
        })
    } else {
        dispatch({
            type: types.PATCH_SUPPLIER_STATUS_FAILED,
            payload: id,
        })
    }
}

export const getSupplier = (id) => async dispatch => {
    dispatch({
        type: types.GET_SUPPLIER
    })

    const response = await getAsync(`/suppliers/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_SUPPLIER_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_SUPPLIER_FAILED,
            payload: response,
        })
    }
}

export const postSupplier = (supplier) => async dispatch => {
    dispatch({
        type: types.POST_SUPPLIER
    })

    const response = await postAsync(`/suppliers`, supplier);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_SUPPLIER_SUCCESS,
            payload: response.data,
        })
    } else {
        dispatch({
            type: types.POST_SUPPLIER_FAILED,
            payload: response,
        })
    }
}

export const patchSupplier = (id, supplier) => async dispatch => {
    dispatch({
        type: types.PATCH_SUPPLIER
    })

    const response = await patchAsync(`/suppliers/${id}`, supplier);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_SUPPLIER_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_SUPPLIER_FAILED,
            payload: response,
        })
    }
}

export const getSuppliers = () => async dispatch => {

    const response = await getAsync(`/suppliers`);
    dispatch({
        type: types.GET_SUPPLIERS,
        payload: response,
    })
}

