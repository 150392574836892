import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, KTIcon, Loader, toAbsoluteUrl } from '../../../../_metronic/helpers'

import { clearState, getVariantList } from './stockActions';

const Stocks = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        variantList,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getVariantList
    } = props;

    const[filteredList,setFilteredList] = useState([]);


    useEffect(() => {

        getVariantList();

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        MenuComponent.reinitialization();
        setFilteredList(variantList);
    }, [variantList])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    const progressColorClass = (color) => {
        if (color == 1)
            return "bg-danger";

        if (color == 0)
            return "bg-warning";

        return "bg-success";
    }

    const progressPercentage = (item) => {
        return item.percentage + "%";
    }

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'STOCK.MANAGEMENT.TITLE' }),
            path: '/stock-management/stocks',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onSearch = (value) => {
        const timeOutId = setTimeout(() => {
            var newList = variantList.filter((item) => {
                return item.productName.toLowerCase().includes(value.toLowerCase()) 
                || item.productCode.toLowerCase().includes(value.toLowerCase()) 
                || item.code.toLowerCase().includes(value.toLowerCase()) 
                || item.categoryName.toLowerCase().includes(value.toLowerCase());
            });
            setFilteredList(newList);
        }, 400);
        return () => clearTimeout(timeOutId);
    }

    const renderProductImage = (item) => {
        if (item && item.productImageLink) {
            return (
                <div className='symbol symbol-45px me-5'>
                    <img src={item.productImageLink} alt='' />
                </div>
            )
        }

        return (
            <div className='symbol symbol-45px me-5'>
                <KTIcon iconName='parcel-tracking' className='fs-3x' />
            </div>
        )
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'STOCK.LIST.BREADCRUMB' })}</PageTitle>

            <div className={`card `}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({ id: 'STOCK.FIELD.LIST' })}</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>{`${variantList && variantList.length} ${intl.formatMessage({ id: 'STOCK.VARIANTS.LISTED' })}`}</span>
                    </h3>
                    <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                        <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder={intl.formatMessage({ id: 'STOCK.SEARCH' })} onChange={(e) => onSearch(e.target.value)} />
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        #
                                    </th>
                                    <th className='min-w-100px'>{intl.formatMessage({ id: 'STOCK.FIELD.PRODUCT-VARIANT' })}</th>
                                    <th className='min-w-140px'>{intl.formatMessage({ id: 'STOCK.FIELD.CODE' })}</th>
                                    <th className='min-w-120px'>{intl.formatMessage({ id: 'STOCK.FIELD.CATEGORY' })}</th>
                                    <th className='min-w-100px'>{intl.formatMessage({ id: 'STOCK.FIELD.PROGRESS' })}</th>
                                    <th className='min-w-100px text-end'>{intl.formatMessage({ id: 'STOCK.FIELD.ACTIONS' })}</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {filteredList && filteredList.map((item, index) => {
                                    return (
                                        <tr key={`variant_row_${index}`}>
                                            <td>
                                                {++index}
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    {renderProductImage(item)}
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <Link to={`/catalog-management/products/${item.productId}/detail`} className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {item.productName}
                                                        </Link>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {item.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                    {item.productCode}
                                                </span>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {item.code}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                    {item.categoryName}
                                                </span>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {item.isActive ? <div className="badge badge-light-success fw-bolder">{intl.formatMessage({ id: 'STOCK.ACTIVE.ENABLED' })}</div> : <div className="badge badge-light-danger">{intl.formatMessage({ id: 'STOCK.ACTIVE.LOCKED' })}</div>}
                                                </span>
                                            </td>
                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-muted me-2 fs-7 fw-semibold'>{progressPercentage(item)}</span>
                                                    </div>
                                                    <div className='progress h-6px w-100'>
                                                        <div
                                                            className={`progress-bar ${progressColorClass(item.stockColor)} `}
                                                            role='progressbar'
                                                            style={{ width: progressPercentage(item) }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-end flex-shrink-0'>
                                                    <Link
                                                        to={`/stock-management/stocks/${item.id}`}
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='pencil' className='fs-3' />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        variantList: state.Stock.variantList,
        loading: state.Stock.loading,
        success: state.Stock.success
    };
};

const mapActionsToProps = {
    clearState, getVariantList
}

export default connect(mapStateToProps, mapActionsToProps)(Stocks)