import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../../app/modules/auth'
import { KTCard, KTCardBody, Loader, toAbsoluteUrl, KTIcon } from '../../../_metronic/helpers'
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { Button, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Item2 } from '../../../_metronic/partials/content/activity/Item2'
import IBAN from 'iban'
import Resizer from "react-image-file-resizer";

import { clearState, getProfile, passwordChange } from './profileActions';
import { patchUser, uploadAvatar, getContact, postContact, putContact } from '../user-management/userActions';
import { getNotifications } from '../notifications/notificationActions';
import { getCities, getCountries, getPhoneCodes } from '../locations/locationActions';



const Profile = (props) => {
    //inputs from url
    const intl = useIntl();
    const { currentUser } = useAuth()
    const location = useLocation();

    //inputs from redux
    const {
        profile,
        newAvatar,
        notifications,
        contact,
        countryList,
        cityList,
        phoneCodeList,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        getProfile,
        passwordChange,
        patchUser,
        uploadAvatar,
        getContact,
        postContact,
        putContact,
        getNotifications,
        getCountries,
        getCities,
        getPhoneCodes
    } = props;

    const [avatar, setAvatar] = useState('');
    const [passwordModel, setPasswordModel] = useState({});

    const tabsModel = [
        { id: 1, name: intl.formatMessage({ id: 'PROFILE.OVERVIEW.TITLE' }), active: "active", hash: "#profile_tab_1", show: 'show' },
        { id: 2, name: intl.formatMessage({ id: 'PROFILE.PROFILE.TITLE' }), active: "", hash: "#profile_tab_2", show: '' },
        { id: 3, name: intl.formatMessage({ id: 'PROFILE.CONTACT.TITLE' }), active: "", hash: "#profile_tab_3", show: '' },
        { id: 4, name: intl.formatMessage({ id: 'PROFILE.PASSWORDCHANGE.TITLE' }), active: "", hash: "#profile_tab_4", show: '' },
        { id: 5, name: intl.formatMessage({ id: 'PROFILE.ACTIVITY.TITLE' }), active: "", hash: "#profile_tab_5", show: '' }
    ]
    const [tabs, setTabs] = useState(tabsModel);

    useEffect(() => {

        getProfile(currentUser.id);
        getContact(currentUser.id);
        getNotifications();
        getCountries();
        getPhoneCodes();

        tabSelector();

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {

        setAvatar(profile && profile.avatarLink);

    }, [profile]);

    useEffect(() => {

        if (contact && contact.countryId > 0)
            getCities(contact.countryId)

    }, [contact]);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'PROFILE.BREADCRUMB.TITLE' }),
            path: '/profile',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const handleValidSubmit = (event, values) => {
        if (newAvatar && avatar != profile.avatarLink) {
            values = { ...values, avatarLink: newAvatar };
        }

        patchUser({ ...values })
    }

    const handleValidContactSubmit = (event, values) => {

        if (contact && contact.id > 0) {
            putContact({ ...values })
        } else {
            postContact({ ...values })
        }
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });


    const onAvatarChange = async (e) => {
        var f = e.target.files[0];

        var image = await resizeFile(f);
        image.name = f.name;

        var file = {
            data: image,
            id: profile.id,
            name: image.name,
            extension: image.type.split('/')[1],
            actionType: 1
        }

        loadImage(f);

        uploadAvatar(file);
    }

    const loadImage = file => {

        var reader = new FileReader();
        reader.onloadend = function () {
            setAvatar(reader.result)
        }
        reader.readAsDataURL(file);

    };

    const cancelAvatar = () => {
        setAvatar(profile.avatarLink)
    }

    const onCountryChange = (value) => {
        if (value > 0)
            getCities(value)
    }

    const validation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }

    const ibanValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        },
        validateIban: function validateIban(value, context, constraint = {}) {
            if (!value) return true;
            return IBAN.isValid(value) || intl.formatMessage({ id: 'VALIDATION.IBAN_INVALID' });
        },
        maxLength: {
            value: 30,
            errorMessage: intl.formatMessage({ id: 'VALIDATION.IBAN_INVALID' })
        }
    }

    const emailValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        },
        email: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.EMAIL_INVALID' })
        }
    }

    const phoneValidation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }

    const passwordFormValidationSchema = Yup.object().shape({
        password: Yup.string()
            .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_LENGTH_FIELD' }) + 3)
            .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }) + 50)
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
        newPassword: Yup.string()
            .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_LENGTH_FIELD' }) + 3)
            .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }) + 50)
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .notOneOf([Yup.ref('password')], 'New password cannot be the same as old password'),
        confirmPassword: Yup.string()
            .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_LENGTH_FIELD' }) + 3)
            .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_LENGTH_FIELD' }) + 50)
            .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
            .oneOf([Yup.ref('newPassword')], intl.formatMessage({ id: 'VALIDATION.PASSWORD.MATCH' }))
            .notOneOf([Yup.ref('password')], intl.formatMessage({ id: 'VALIDATION.PASSWORD.NOTSAME' }))
    })

    const formik2 = useFormik({
        initialValues: {
            ...passwordModel,
        },
        validationSchema: passwordFormValidationSchema,
        onSubmit: (values) => {
            passwordChange(currentUser.id, values)
        },
    })


    const renderOverview = (tab) => {
        return (
            <>
                <div
                    className={`tab-pane fade ${tab.active} ${tab.show}`}
                    id="profile_tab_1"
                    role="tabpanel"
                >
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'PROFILE.OVERVIEW.TITLE' })}</h3>
                            </div>
                        </div>

                        <div className='card-body p-9'>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'PROFILE.FIELD.FULLNAME' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{profile.name} {profile.surname}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'PROFILE.FIELD.TITLE' })}</label>
                                <div className='col-lg-8 fv-row'>
                                    <span className='fw-bold fs-6'>{profile.title}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'PROFILE.FIELD.PHONE' })}</label>
                                <div className='col-lg-8 fv-row'>
                                    <span className='fw-bold fs-6'>{profile.phone}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'PROFILE.FIELD.EMAIL' })}</label>
                                <div className='col-lg-8 fv-row'>
                                    <span className='fw-bold fs-6'>{profile.email}</span>
                                </div>
                            </div>


                            <hr></hr>
                            <h3 className='mt-5 mb-5'>{intl.formatMessage({ id: 'PROFILE.CONTACT.INFORMATION' })}</h3>
                            <hr></hr>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'PROFILE.FIELD.COMPANY' })}</label>
                                <div className='col-lg-8 fv-row'>
                                    <span className='fw-bold fs-6'>{contact && contact.company}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                    {intl.formatMessage({ id: 'PROFILE.FIELD.ADDRESS' })}
                                </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{contact.street} {contact.building} {contact.zipCode} </span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                    {intl.formatMessage({ id: 'PROFILE.FIELD.CITY' })}
                                </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{contact.cityName}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                    {intl.formatMessage({ id: 'PROFILE.FIELD.COUNTRY' })}
                                </label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 text-dark'>{contact.countryName}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'PROFILE.FIELD.KVK' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 me-2'>{contact.kvkNumber}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'PROFILE.FIELD.IBAN' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 me-2'>{contact.iban}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'PROFILE.FIELD.BTW' })}</label>
                                <div className='col-lg-8'>
                                    <span className='fw-bolder fs-6 me-2'>{contact.btwNumber}</span>
                                </div>
                            </div>
                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                    {intl.formatMessage({ id: 'PROFILE.FIELD.PHONE' })}
                                </label>
                                <div className='col-lg-8 d-flex align-items-center'>
                                    <span className='fw-bolder fs-6 me-2'>{contact.phone}</span>
                                </div>
                            </div>

                            <div className='row mb-7'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                    {intl.formatMessage({ id: 'PROFILE.FIELD.EMAIL' })}
                                </label>
                                <div className='col-lg-8 d-flex align-items-center'>
                                    <span className='fw-bolder fs-6 me-2'>{contact.email}</span>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </>
        )
    }

    const renderEditUser = (tab) => {
        return (
            <>
                <div className={`tab-pane fade ${tab.active} ${tab.show}`} id="profile_tab_2" role="tabpanel">
                    <KTCard>

                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'PROFILE.PROFILE.TITLE' })}</h3>
                            </div>
                        </div>
                        <KTCardBody>
                            <AvForm key={1} onValidSubmit={handleValidSubmit}>

                                <div className='fv-row mb-7'>
                                    <label className='d-block fw-bold fs-6 mb-5'>{intl.formatMessage({ id: 'PROFILE.FIELD.AVATAR' })}</label>

                                    <div
                                        className='image-input image-input-outline'
                                        data-kt-image-input='true'
                                        style={{ backgroundImage: `url('/media/svg/avatars/blank.svg')` }}
                                    >
                                        <div
                                            className='image-input-wrapper w-125px h-125px'
                                            style={{ backgroundImage: `url('${avatar}')` }}
                                        ></div>

                                        <label
                                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                            data-kt-image-input-action='change'
                                            data-bs-toggle='tooltip'
                                            title='Change avatar'
                                        >
                                            <i className='bi bi-pencil-fill fs-7'></i>

                                            <input type='file' name='avatar' accept='.png, .jpg, .jpeg' onChange={(e) => onAvatarChange(e)} />
                                            <input type='hidden' name='avatar_remove' />
                                        </label>
                                        <span
                                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                            data-kt-image-input-action='cancel'
                                            data-bs-toggle='tooltip'
                                            title='Cancel avatar'
                                        >
                                            <i className='bi bi-x fs-2'></i>
                                        </span>
                                        <span
                                            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                            data-kt-image-input-action='remove'
                                            data-bs-toggle='tooltip'
                                            title='Remove avatar'
                                            onClick={() => cancelAvatar()}
                                        >
                                            <i className='bi bi-x fs-2'></i>
                                        </span>
                                    </div>
                                    <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                                </div>

                                <AvField name="name" label={intl.formatMessage({ id: 'PROFILE.FIELD.NAME' })} type="text" value={profile && profile.name} validate={validation} />
                                <AvField name="surname" label={intl.formatMessage({ id: 'PROFILE.FIELD.SURNAME' })} type="text" value={profile && profile.surname} validate={validation} />
                                <AvField name="title" label={intl.formatMessage({ id: 'PROFILE.FIELD.TITLE' })} type="text" value={profile && profile.title} validate={validation} />
                                <AvField name="email" label={intl.formatMessage({ id: 'PROFILE.FIELD.EMAIL' })} type="text" value={profile && profile.email} validate={emailValidation} />
                                <Row>
                                    <Col>
                                        <AvField name="phoneCode" label={intl.formatMessage({ id: 'PROFILE.FIELD.PHONECODE' })} type="select" value={profile && profile.phoneCode} validate={validation}>
                                            <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                            {phoneCodeList && phoneCodeList.map((item, index) => {
                                                return (
                                                    <option key={`code_${item.id}`} value={item.phoneCode}>{item.phoneCode}</option>
                                                )
                                            })}
                                        </AvField>
                                    </Col>
                                    <Col>
                                        <AvField name="phone" label={intl.formatMessage({ id: 'PROFILE.FIELD.PHONE' })} type="text" value={profile && profile.phone} validate={phoneValidation} />
                                    </Col>
                                </Row>

                                <AvField type="hidden" name="id" value={profile && profile.id} />
                                <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</Button>
                            </AvForm>
                        </KTCardBody>
                    </KTCard >
                </div></>
        )
    }

    const renderEditContact = (tab) => {
        return (
            <>
                <div className={`tab-pane fade ${tab.active} ${tab.show}`} id="profile_tab_3" role="tabpanel">
                    <KTCard className='mt-10'>

                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'PROFILE.CONTACT.TITLE' })}</h3>
                            </div>
                        </div>

                        <KTCardBody>

                            <AvForm key={1} onValidSubmit={handleValidContactSubmit}>

                                <AvField name="company" label={intl.formatMessage({ id: 'PROFILE.FIELD.COMPANY' })} type="text" value={contact && contact.company} validate={validation} />
                                <AvField name="street" label={intl.formatMessage({ id: 'PROFILE.FIELD.STREET' })} type="text" value={contact && contact.street} validate={validation} />

                                <Row>
                                    <Col>
                                        <AvField name="building" label={intl.formatMessage({ id: 'PROFILE.FIELD.BULDING' })} type="text" value={contact && contact.building} validate={validation} />
                                    </Col>
                                    <Col>
                                        <AvField name="zipCode" label={intl.formatMessage({ id: 'PROFILE.FIELD.POSTALCODE' })} type="text" value={contact && contact.zipCode} validate={validation} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AvField name="cityId" label={intl.formatMessage({ id: 'PROFILE.FIELD.CITY' })} type="select" value={contact && contact.cityId} validate={validation} >
                                            <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                            {cityList && cityList.map((item, index) => {
                                                return (
                                                    <option key={`city_${item.id}`} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </AvField>
                                    </Col>
                                    <Col>
                                        <AvField name="countryId" label={intl.formatMessage({ id: 'PROFILE.FIELD.COUNTRY' })} type="select" value={contact && contact.countryId} validate={validation} onChange={(e) => { onCountryChange(e.target.value) }}>
                                            <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                            {countryList && countryList.map((item, index) => {
                                                return (
                                                    <option key={`country_${item.id}`} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </AvField>
                                    </Col>
                                </Row>

                                <AvField name="iban" label={intl.formatMessage({ id: 'PROFILE.FIELD.IBAN' })} type="text" value={contact && contact.iban} validate={ibanValidation} />

                                <Row>
                                    <Col>
                                        <AvField name="email" label={intl.formatMessage({ id: 'PROFILE.FIELD.EMAIL' })} type="text" value={contact && contact.email} validate={emailValidation} />
                                    </Col>
                                    <Col>

                                        <Row>
                                            <Col md={4}>
                                                <AvField name="phoneCode" label={intl.formatMessage({ id: 'PROFILE.FIELD.PHONECODE' })} type="select" value={contact && contact.phoneCode} validate={validation}>
                                                    <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                                    {phoneCodeList && phoneCodeList.map((item, index) => {
                                                        return (
                                                            <option key={`code_${item.id}`} value={item.phoneCode}>{item.phoneCode}</option>
                                                        )
                                                    })}
                                                </AvField>
                                            </Col>
                                            <Col md={8}>
                                                <AvField name="phone" label={intl.formatMessage({ id: 'PROFILE.FIELD.PHONE' })} type="text" value={contact && contact.phone} validate={validation} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <AvField type="hidden" name="userId" value={profile && profile.id} />
                                <AvField type="hidden" name="id" value={contact && contact.id} />

                                <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</Button>
                            </AvForm>
                        </KTCardBody>
                    </KTCard >
                </div></>
        )
    }

    const renderEditPassword = (tab) => {
        return (
            <>
                <div className={`tab-pane fade ${tab.active} ${tab.show}`} id="profile_tab_4" role="tabpanel">
                    <KTCard>

                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'PROFILE.PASSWORDCHANGE.TITLE' })}</h3>
                            </div>
                        </div>
                        <KTCardBody>
                            <form
                                onSubmit={formik2.handleSubmit}
                                id='kt_signin_change_password'
                                className='form'
                                noValidate
                            >
                                <div className='row mb-1'>
                                    <div className='col-lg-4'>
                                        <div className='fv-row mb-0'>
                                            <label htmlFor='password' className='form-label fs-6 fw-bolder mb-3'>
                                            {intl.formatMessage({ id: 'PROFILE.FIELD.CURRENTPASSWORD' })}
                                            </label>
                                            <input
                                                type='password'
                                                className='form-control form-control-lg form-control-solid '
                                                id='password'
                                                {...formik2.getFieldProps('password')}
                                            />
                                            {formik2.touched.currentPassword && formik2.errors.currentPassword && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik2.errors.currentPassword}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='col-lg-4'>
                                        <div className='fv-row mb-0'>
                                            <label htmlFor='newPassword' className='form-label fs-6 fw-bolder mb-3'>
                                            {intl.formatMessage({ id: 'PROFILE.FIELD.NEWPASSWORD' })}
                                            </label>
                                            <input
                                                type='password'
                                                className='form-control form-control-lg form-control-solid '
                                                id='newPassword'
                                                {...formik2.getFieldProps('newPassword')}
                                            />
                                            {formik2.touched.newPassword && formik2.errors.newPassword && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik2.errors.newPassword}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='col-lg-4'>
                                        <div className='fv-row mb-0'>
                                            <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                                            {intl.formatMessage({ id: 'PROFILE.FIELD.CONFIRMPASSWORD' })}
                                            </label>
                                            <input
                                                type='password'
                                                className='form-control form-control-lg form-control-solid '
                                                id='confirmPassword'
                                                {...formik2.getFieldProps('confirmPassword')}
                                            />
                                            {formik2.touched.confirmPassword && formik2.errors.confirmPassword && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik2.errors.confirmPassword}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex'>
                                    <button
                                        id='kt_password_submit'
                                        type='submit'
                                        className='btn btn-primary me-2 px-6'
                                    >
                                        {intl.formatMessage({ id: 'GENERAL.SUBMIT' })}
                                    </button>
                                </div>
                            </form>
                        </KTCardBody>
                    </KTCard >
                </div></>
        )
    }

    const renderActivity = (tab) => {
        return (
            <div className={`tab-pane fade ${tab.active} ${tab.show}`} id="profile_tab_5" role="tabpanel">
                <div className='card'>
                    <div className='card-header'>
                        <h3 className='card-title fw-bolder text-dark'>{intl.formatMessage({ id: 'PROFILE.ACTIVITY.TITLE' })}</h3>

                        <div className='card-toolbar'>

                        </div>
                    </div>
                    <div className='card-body position-relative'>
                        <div className='position-relative scroll-y me-n5 pe-5' >
                            <div className='timeline'>

                                {
                                    notifications && notifications.map((item, index) => {
                                        return (

                                            <Item2 key={`notification_${index}`} item={item} />

                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const tabSelector = () => {

        var hash = window.location.hash;
        if (hash) {
            tabs.map((tab, index) => {
                tab.active = '';
                tab.show = '';
                if (tab.hash == hash) {
                    tab.active = 'active';
                    tab.show = 'show';
                }
            })
            setTabs(tabs);
        }
    }

    const onTabClick = (e) => {
        var url = e.target.href;
        window.history.pushState({}, null, url);
    }



    const renderTabs = (tab) => {
        return (
            <li key={`tab_${tab.id}`} className='nav-item'>
                <a className={`nav-link ${tab.active}`} data-bs-toggle="tab" href={tab.hash} onClick={(e) => onTabClick(e)}>{tab.name}</a>
            </li>
        )
    }

    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'PROFILE.BREADCRUMB.DESCRIPTION' })}</PageTitle>

            <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                        <div className='me-7 mb-4'>
                            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                <img src={profile.avatarLink} alt='Metornic' />
                                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                            </div>
                        </div>

                        <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                            {profile.name} {profile.surname}
                                        </a>
                                    </div>

                                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                        <a
                                            href='#'
                                            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                        >
                                            <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                                            {profile.typeName}
                                        </a>
                                        <a
                                            href='#'
                                            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                        >
                                            <KTIcon iconName='geolocation' className='fs-4 me-1' />
                                            {contact && contact.cityName}, {contact && contact.countryName}
                                        </a>
                                        <a
                                            href='#'
                                            className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                        >
                                            <KTIcon iconName='sms' className='fs-4 me-1' />
                                            {profile.email}
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex flex-wrap flex-stack'>
                                {/* <div className='d-flex flex-column flex-grow-1 pe-8'>
                                    <div className='d-flex flex-wrap'>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                            <div className='d-flex align-items-center'>
                                                <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                                <div className='fs-2 fw-bolder'>4500$</div>
                                            </div>

                                            <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                                        </div>

                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                            <div className='d-flex align-items-center'>
                                                <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                                                <div className='fs-2 fw-bolder'>75</div>
                                            </div>

                                            <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                                        </div>

                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                            <div className='d-flex align-items-center'>
                                                <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                                <div className='fs-2 fw-bolder'>60%</div>
                                            </div>

                                            <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                                    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                                        <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                                        <span className='fw-bolder fs-6'>50%</span>
                                    </div>
                                    <div className='h-5px mx-3 w-100 bg-light mb-3'>
                                        <div
                                            className='bg-success rounded h-5px'
                                            role='progressbar'
                                            style={{ width: '50%' }}
                                        ></div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className='d-flex overflow-auto h-55px'>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                            {tabs.map((tab, index) => {
                                return (
                                    renderTabs(tab)
                                )
                            })}
                        </ul>
                    </div>



                </div>

            </div>

            <div className="tab-content" id="myTabContent">
                {renderOverview(tabs[0])}
                {renderEditUser(tabs[1])}
                {renderEditContact(tabs[2])}
                {renderEditPassword(tabs[3])}
                {renderActivity(tabs[4])}
            </div>

            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        profile: state.Profile.profile,
        loading: state.Profile.loading || state.User.loading,
        newAvatar: state.User.newAvatar,
        contact: state.User.contact,
        countryList: state.Location.countryList,
        cityList: state.Location.cityList,
        phoneCodeList: state.Location.phoneCodeList,
        success: state.Profile.success || state.User.success,
        notifications: state.Notification.notifications
    };
};

const mapActionsToProps = {
    clearState, getProfile, passwordChange, patchUser, uploadAvatar, getContact, postContact, putContact, getNotifications, getCountries, getCities, getPhoneCodes
}

export default connect(mapStateToProps, mapActionsToProps)(Profile)