import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify';
import { useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import Resizer from "react-image-file-resizer";
import { PageTitle } from '../../../_metronic/layout/core'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, Loader, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Button } from 'reactstrap';

import { clearState, getDevSupportTicket, postDevSupportTicket, patchDevSupportTicket, uploadImage } from './devSupportActions';

const DevSupportTicket = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl();
    const [inLoading, setInloading] = useState(false);
    const navigate = useNavigate();
    const [stateList, setStateList] = useState([
        { 'id': 0, 'name': 'Created'},
        { 'id': 1, 'name': 'In Progress'},
        { 'id': 2, 'name': 'Resolved'},
        { 'id': 3, 'name': 'Rejected'},
        { 'id': 4, 'name': 'Deleted'},
        { 'id': 5, 'name': 'Closed'}
    ]);
    const [image, setImage] = useState('');
    const [inputFile, setFile] = useState(undefined);
    MenuComponent.reinitialization();
    
    //inputs from redux
    const {
        devSupportTicket,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getDevSupportTicket,
        postDevSupportTicket,
        patchDevSupportTicket,
        uploadImage
    } = props;

    useEffect(() => {

        if (params.id > 0)
            getDevSupportTicket(params.id);

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (params.id === '0' && devSupportTicket && devSupportTicket.id > 0) {
            navigate(`/dev-support/tickets/${devSupportTicket.id}`);
        } else {
            formik.setValues(devSupportTicket)
            if (devSupportTicket.imageLink != undefined) {
                setImage(devSupportTicket.imageLink)
            }
        }        

    }, [devSupportTicket])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'DEV.SUPPORT.TITLE' }),
            path: '/dev-support/tickets',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const ticketValues = { title: '', description: '', file: null, id: params.id, state: 0 };
    const ticketSchema = Yup.object().shape({
        title: Yup.string()
            .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        description: Yup.string()
            .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
    })

    const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            600,
            600,
            "png",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

    const resizeAndUploadImage = async (id, file) => {

        var resizedFile = await resizeFile(file);
        resizedFile.name = file.name;

        var file = {
            id: id,
            file: resizedFile,
            name: resizedFile.name,
            actionType: 4,
            extension: resizedFile.type.split('/')[1],
        }

        return uploadImage(file);
    }

    const formik = useFormik({
        initialValues: ticketValues,
        validationSchema: ticketSchema,
        onSubmit: async (values) => {

            setInloading(true);

            if (params.id == 0) {
                var response = await resizeAndUploadImage(values.id, inputFile);
                if (response.isSuccess) {
                    var request = {
                        title: values.title,
                        description: values.description,
                        state: values.state,
                        imagePath: response.data.path
                    }
                    await postDevSupportTicket(request);
                }
            } else {
                if (inputFile != undefined)
                {
                    var response = await resizeAndUploadImage(values.id, inputFile);
                    if (response.isSuccess) {
                        var request = {
                            title: values.title,
                            description: values.description,
                            state: values.state,
                            imagePath: response.data.path
                        }
                        await patchDevSupportTicket(values.id, request);
                    }
                }
                else {
                    var request = {
                        title: values.title,
                        description: values.description,
                        state: values.state
                    }
                    await patchDevSupportTicket(values.id, request);
                }
            }
            
            setInloading(false);
        },
    })
    
    function onStateChange(value) {
        formik.setFieldValue('state', value);
    }
  
    const onImageChange = async (e) => {

        var file = e.target.files[0];
        setFile(file);
        loadImage(file);
    }

    const loadImage = file => {

        var reader = new FileReader();
        reader.onloadend = function () {
            setImage(reader.result)
        }
        reader.readAsDataURL(file);
    };

    const cancelImage = () => {
        setImage(devSupportTicket.imageLink)
    }

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'DEV.SUPPORT.ITEM.BREADCRUMB' })}</PageTitle>

            <KTCard>
                <div className="card-header">
                    <h3 className="card-title">{intl.formatMessage({ id: 'DEV.SUPPORT.TICKET.PAGE.TITLE' })}</h3>
                </div>
                <KTCardBody>

                    <form 
                        onSubmit={formik.handleSubmit}
                        className='form w-100' 
                        noValidate
                    >
                        <div className='fv-row mb-3'>
                            <label className='d-block fw-bold fs-6 mb-5'>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.TITLE' })}</label>
                            <input
                                type='textarea'
                                autoComplete='off'
                                {...formik.getFieldProps('title')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.title && formik.errors.title,
                                    },
                                    {
                                        'is-valid': formik.touched.title && !formik.errors.title,
                                    }
                                )}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.title}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-3'>
                            <label className='d-block fw-bold fs-6 mb-5'>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.DESCRIPTION' })}</label>
                            <input
                                type='textarea'
                                autoComplete='off'
                                {...formik.getFieldProps('description')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.description && formik.errors.description,
                                    },
                                    {
                                        'is-valid': formik.touched.description && !formik.errors.description,
                                    }
                                )}
                            />
                            {formik.touched.description && formik.errors.description && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.description}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='fv-row mb-3' hidden={params.id == 0}>
                            <label className='d-block fw-bold fs-6 mb-5'>
                            {intl.formatMessage({ id: "DEV.SUPPORT.TICKETS.LIST.STATE" })}
                            </label>

                            <select
                                className='form-select form-select-solid form-select-lg fw-bold'
                                {...formik.getFieldProps('state')}
                                onChange={(e) => onStateChange(e.target.value)}
                            >
                                <option value=''>{intl.formatMessage({ id: "DEV.SUPPORT.TICKETS.SELECT.STATE.DEFAULT" })}</option>
                                {stateList.map((item, index) => {
                                    return (
                                    <option key={`state_${item.id}`} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>
                            {formik.touched.state && formik.errors.state && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.state}</div>
                                </div>
                            )}
                        </div>

                        <div className='fv-row mb-3'>
                            <label className='d-block fw-bold fs-6 mb-5'>{intl.formatMessage({ id: "DEV.SUPPORT.TICKETS.LIST.FILE" })}</label>

                            <div
                                className='image-input image-input-outline'
                                data-kt-image-input='true'
                                style={{ backgroundImage: `url('${toAbsoluteUrl('/media/svg/avatars/blank.svg')}')` }}
                            >
                                <div
                                    className='image-input-wrapper w-250px h-250px'
                                    style={{ backgroundImage: `url('${image}')` }}
                                >
                                    { image && 
                                        <a className='symbol symbol-200px symbol-fixed position-relative' href={image} target='_blank' download={true}>
                                            <img src={image} alt='imagename' className='w-250px h-250px'/>
                                        </a>
                                    }
                                </div>

                                <label
                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                    data-kt-image-input-action='change'
                                    data-bs-toggle='tooltip'
                                    title='Change avatar'
                                >
                                    <i className='bi bi-pencil-fill fs-7'></i>

                                    <input type='file' name='avatar' accept='.png, .jpg, .jpeg' onChange={(e) => onImageChange(e)} />
                                    <input type='hidden' name='avatar_remove' />
                                </label>
                                <span
                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                    data-kt-image-input-action='cancel'
                                    data-bs-toggle='tooltip'
                                    title='Cancel avatar'
                                >
                                    <i className='bi bi-x fs-2'></i>
                                </span>
                                <span
                                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                    data-kt-image-input-action='remove'
                                    data-bs-toggle='tooltip'
                                    title='Remove avatar'
                                    onClick={() => cancelImage()}
                                >
                                    <i className='bi bi-x fs-2'></i>
                                </span>
                            </div>
                            <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                            {formik.touched.file && formik.errors.file && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.file}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        
                        <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT.BUTTON' })}</Button>
                    </form>
                </KTCardBody>
            </KTCard >

            {(loading || inLoading) && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        devSupportTicket: state.DevSupport.devSupportTicket,
        loading: state.DevSupport.loading,
        success: state.DevSupport.success
    };
};

const mapActionsToProps = {
    clearState, getDevSupportTicket, postDevSupportTicket, patchDevSupportTicket, uploadImage
}

export default connect(mapStateToProps, mapActionsToProps)(DevSupportTicket)