/* NOTIFICATION */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'NOTIFICATIONS/CLEAR_STATE',

    GET_NOTIFICATIONS: 'NOTIFICATIONS/GET_NOTIFICATIONS',
    GET_NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS/GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_FAILED: 'NOTIFICATIONS/GET_NOTIFICATIONS_FAILED',

    GET_NOTIFICATIONS_UNREAD: 'NOTIFICATIONS/GET_NOTIFICATIONS_UNREAD',
    GET_NOTIFICATIONS_UNREAD_SUCCESS: 'NOTIFICATIONS/GET_NOTIFICATIONS_UNREAD_SUCCESS',
    GET_NOTIFICATIONS_UNREAD_FAILED: 'NOTIFICATIONS/GET_NOTIFICATIONS_UNREAD_FAILED',

    PATCH_NOTIFICATION: 'NOTIFICATIONS/PATCH_NOTIFICATION',
    PATCH_NOTIFICATION_SUCCESS: 'NOTIFICATIONS/PATCH_NOTIFICATION_SUCCESS',
    PATCH_NOTIFICATION_FAILED: 'NOTIFICATIONS/PATCH_NOTIFICATION_FAILED',
});

export default actionTypes;