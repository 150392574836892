/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { useIntl } from 'react-intl'

type Props = {
  className: string,
  chartData: any
}

const ChartsWidget4: React.FC<Props> = ({ className, chartData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const intl = useIntl()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartData))
    if (chart) {
      chart.render()
    }
    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartData])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({ id: 'STOCK.FIELD.HISTORY' })}</span>
        </h3>
        <div className='card-toolbar' >
          <a
            className='btn btn-sm btn-color-muted  active px-4 me-1'
            id='kt_charts_widget_4_year_btn'
          >
            {intl.formatMessage({ id: 'STOCK.FIELD.CURRENT' })}: {chartData.total}
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_4_month_btn'
          >
            {intl.formatMessage({ id: 'STOCK.FIELD.TOTALINCOME' })}: {chartData.totalIncome}
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_4_week_btn'
          >
            {intl.formatMessage({ id: 'STOCK.FIELD.TOTALOUTCOME' })}: {chartData.totalOutcome}
          </a>
        </div>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_4_chart' style={{ height: '350px' }}></div>
      </div>
    </div>
  )
}

export { ChartsWidget4 }

function getChartOptions(height: number, chartData: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const baseColor = getCSSVariableValue('--bs-success')
  const baseLightColor = getCSSVariableValue('--bs-success-light')

  const secondaryColor = getCSSVariableValue('--bs-danger')
  const secondaryLightColor = getCSSVariableValue('--bs-danger-light')

  const thirdColor = getCSSVariableValue('--bs-info')
  const thirdLightColor = getCSSVariableValue('--bs-info-light')


  return {
    series: [
      {
        name: 'Current',
        data: chartData.currents,
      },
      {
        name: 'In',
        data: chartData.incomes,
      },
      {
        name: 'Out',
        data: chartData.outcomes,
      }

    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: chartData.dates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ' quantity'
        },
      },
    },
    colors: [thirdColor, baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      colors: [thirdLightColor, baseLightColor, secondaryLightColor],
      strokeColors: [thirdLightColor, baseLightColor, secondaryLightColor],
      strokeWidth: 3,
    },
  }
}
