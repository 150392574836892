import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { Loader, KTIcon } from '../../../../_metronic/helpers'
import { Row, Col } from 'reactstrap';
import Barcode from 'react-barcode'
import Moment from 'react-moment'

import { clearState, getProduct } from './productActions';

const Product = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    const navigate = useNavigate();
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        product,
        variants,
        images,
        loading,
    } = props;

    //actions
    const {
        clearState,
        getProduct,
    } = props;

    useEffect(() => {

        getProduct(params.id);

        return () => {
            clearState()
        }
    }, []);

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CATALOG.MANAGEMENT.TITLE' }),
            path: '/catalog-management/products',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'PRODUCT.DETAIL.BREADCRUMB' })}</PageTitle>

            <Row>
                <Col md={3}>
                    <div className="card card-flush py-4">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Images</h2>
                            </div>
                        </div>
                        {images && images.map((item, index) => {
                            return (
                                <div key={item.id} className="card-body text-center">
                                    <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3 " >
                                        <div className='image-input-wrapper'>
                                            <img src={item.link} height="100%" width="100%"></img>
                                        </div>
                                    </div>
                                    <div className="text-muted fs-7"></div>
                                </div>
                            )
                        })}
                        {images && images.length == 0 ? <div className='symbol symbol-75px me-5 text-center'><KTIcon iconName='parcel-tracking' className='fs-5x' /></div> : null}
                    </div>
                </Col>
                <Col md={9}>

                    <div className="card card-flush py-4">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Product</h2>
                            </div>
                            <div className="card-toolbar">
                                <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                    <a type="button" className="btn btn-success" href={`/catalog-management/products/${product.id}`}>
                                        <i className="ki-duotone ki-pencil fs-2">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                        Edit Product
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">Id</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{product.id}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">Name</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{product.name}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">Description</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{product.description}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">Sku</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{product.sku}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">Category</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{product.category && product.category.name}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">{product.optionValue && product.optionValue.option && product.optionValue.option.name}</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{product.optionValue && product.optionValue.name}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">Weight</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{product.weight} {product.weightUnit}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">Created On</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800"><Moment>{product.createdOn}</Moment></span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">Modified On</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800"><Moment>{product.modifiedOn}</Moment> </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card-flush mt-10 py-4">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Variants ({variants && variants.length})</h2>
                            </div>
                        </div>
                    </div>
                    {variants && variants.map((variant, index) => {
                        return (
                            <div key={variant.id} className='card'>
                                <div className='card-header'>
                                    <div className="card-title">
                                        <h2>Variant : {variant.code}</h2>
                                    </div>
                                    <div className="card-toolbar">
                                        <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                            <Barcode value={variant.code} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end mb-5" data-kt-user-table-toolbar="base">
                                                <Link to={`/stock-management/stocks-print/${variant.id}`} className="btn btn-light-info m-1"> <KTIcon iconName='printer' className='fs-2x'></KTIcon> Print</Link>
                                                <Link to={`/stock-management/stocks-operation/${variant.id}`} className="btn btn-light-info m-1"> <KTIcon iconName='scan-barcode' className='fs-2x'></KTIcon> Add or Drop Stock</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='border border-primary rounded p-10'>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">id</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.id}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Name</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.name}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Code</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.code}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Stock Quantity</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.stockQuantity}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Weight</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.weight} {variant.weightUnit}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Width</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.width} {variant.measurementUnit}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Height</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.height} {variant.measurementUnit}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Created On</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800"><Moment>{variant.createdOn}</Moment> </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Modified On</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800"><Moment>{variant.modifiedOn}</Moment> </span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={1}>
                                        </Col>
                                        <Col className='border border-primary rounded p-10'>

                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Price</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.prices && variant.prices[0] && variant.prices[0].price}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Sales Price</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.prices && variant.prices[0] && variant.prices[0].salesPrice}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Vat</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800">{variant.prices && variant.prices[0] && variant.prices[0].vat}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-md-4 fw-semibold text-muted">Created On</label>
                                                <div className="col-md-8">
                                                    <span className="fw-bold fs-6 text-gray-800"> <Moment>{variant.prices && variant.prices[0] && variant.prices[0].createdOn}</Moment>  </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        )
                    })}



                </Col>
            </Row>


            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        product: state.Product.product,
        variants: state.Product.variants,
        images: state.Product.images,
        loading: state.Product.loading,
        success: state.Product.success
    };
};

const mapActionsToProps = {
    clearState, getProduct
}

export default connect(mapStateToProps, mapActionsToProps)(Product)