// @flow
import types from './orderConstants';
import { getAsync, postAsync, deleteAsync, patchAsync, putAsync, uploadAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getOrders = (filter) => async dispatch => {

    dispatch({
        type: types.GET_ORDERS
    })

    var path = `/orders?`;

    if (filter) {
        path += "keyword=" + filter.keyword + "&";
        path += "date=" + filter.date + "&";
        path += "status=" + filter.status + "&";
        path += "isApproved=" + filter.isApproved + "&";
        path += "isPaid=" + filter.isPaid;
    }

    const response = await getAsync(path);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_ORDERS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_ORDERS_FAILED,
            payload: response,
        })
    }
}

export const getOrder = (id) => async dispatch => {

    dispatch({
        type: types.GET_ORDER
    })

    const response = await getAsync(`/orders/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_ORDER_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_ORDER_FAILED,
            payload: response,
        })
    }
}

export const putOrder = (id, request) => async dispatch => {

    await putAsync(`/orders/${id}`, request);
}

export const getOrderItems = (id) => async dispatch => {

    dispatch({
        type: types.GET_ORDER_ITEMS
    })

    const response = await getAsync(`/orders/${id}/items`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_ORDER_ITEMS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_ORDER_ITEMS_FAILED,
            payload: response,
        })
    }
}

export const patchOrderContentStatus = (id, contentId) => async dispatch => {

    dispatch({
        type: types.PATCH_ORDER_CONTENT_STATUS
    })

    const response = await patchAsync(`/orders/${id}/contents/${contentId}/status`);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_ORDER_CONTENT_STATUS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_ORDER_CONTENT_STATUS_FAILED,
            payload: response,
        })
    }
}

export const postOrderContent = (id, orderItemId) => async dispatch => {
    dispatch({
        type: types.POST_ORDER_CONTENT
    })

    var response = await postAsync(`/orders/${id}/items/${orderItemId}/contents`);

    if (response.isSuccess) {
        dispatch(getOrderItems(id))
        dispatch({
            type: types.POST_ORDER_CONTENT_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.POST_ORDER_CONTENT_FAILED,
            payload: response,
        })
    }
}

export const putOrderContent = (id, content) => async dispatch => {
    dispatch({
        type: types.PUT_ORDER_CONTENT
    })

    var response = await putAsync(`/orders/${id}/items/${content.orderItemId}/contents`, content);

    if (response.isSuccess) {
        dispatch(getOrderItems(id))
        dispatch({
            type: types.PUT_ORDER_CONTENT_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PUT_ORDER_CONTENT_FAILED,
            payload: response,
        })
    }
}

export const deleteOrderContent = (id, contentId) => async dispatch => {

    dispatch({
        type: types.DELETE_ORDER_CONTENT
    })

    var response = await deleteAsync(`/orders/${id}/contents/${contentId}`);

    if (response.isSuccess) {
        dispatch(getOrderItems(id))
        dispatch({
            type: types.DELETE_ORDER_CONTENT_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.DELETE_ORDER_CONTENT_FAILED,
            payload: response,
        })
    }

}

export const getStatus = (id) => async dispatch => {
    var response = await getAsync(`/orders/${id}/status/triggers`);
    dispatch({
        type: types.GET_STATUS,
        payload: response,
    })
}

export const postStatus = (id, status) => async dispatch => {

    dispatch({
        type: types.POST_STATUS,
        payload: response,
    })

    var response = await postAsync(`/orders/${id}/status/triggers`, { id: status });
    if (response.isSuccess) {
        dispatch(getOrder(id))
        dispatch(getStatus(id))
        dispatch({
            type: types.POST_STATUS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.POST_STATUS_FAILED,
            payload: response,
        })
    }

}

export const getHistory = (id) => async dispatch => {
    var response = await getAsync(`/orders/${id}/history`);
    dispatch({
        type: types.GET_HISTORY,
        payload: response,
    })
}

export const getStatusFilter = () => async dispatch => {
    const response = await getAsync(`/lookup/10/values`);
    dispatch({
        type: types.GET_STATUS_FILTER,
        payload: response,
    })
}

export const getImages = (id) => async dispatch => {
    const response = await getAsync(`/orders/${id}/images`);
    dispatch({
        type: types.GET_IMAGES,
        payload: response,
    })
}

export const uploadImage = (id, file) => async dispatch => {


    dispatch({
        type: types.POST_IMAGE,
    })

    var request = {
        identifier: id,
        fileName: file.name,
        fileExtension: file.extension,
        actionType: file.actionType
    };

    const response = await postAsync(`/signed-url/upload`, request);

    var data = {
        id: file.id,
        file: file.file
    }

    if (response.isSuccess) {
        await uploadAsync(response.data.url, data, async () => {
            var uploadResponse = await postAsync(`/orders/${id}/images`, {
                path: response.data.path,
                sequence: 1
            })

            if (uploadResponse.isSuccess) {

                dispatch({
                    type: types.POST_IMAGE_SUCCESS,
                    payload: file.id
                })

                dispatch(getImages(id))
            }
        });
    } else {
        dispatch({
            type: types.POST_IMAGE_FAILED,
        })
    }
}


export const deleteImage = (id, imageId) => async dispatch => {

    dispatch({
        type: types.DELETE_IMAGE,
    })

    const response = await deleteAsync(`/orders/${id}/images/${imageId}`);

    if (response.isSuccess) {
        dispatch({
            type: types.DELETE_IMAGE_SUCCESS,
            payload: { id: imageId },
        })
    } else {
        dispatch({
            type: types.DELETE_IMAGE_FAILED,
            payload: response,
        })
    }
}