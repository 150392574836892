/* SUPPLIERS */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'SUPPLIERS/CLEAR_STATE',

    GET_SUPPLIERS: 'SUPPLIERS/GET_SUPPLIERS',
    GET_SUPPLIERS_SUCCESS: 'SUPPLIERS/GET_SUPPLIERS_SUCCESS',
    GET_SUPPLIERS_FAILED: 'SUPPLIERS/GET_SUPPLIERS_FAILED',

    PATCH_SUPPLIER_STATUS: 'SUPPLIERS/PATCH_SUPPLIER_STATUS',
    PATCH_SUPPLIER_STATUS_SUCCESS: 'SUPPLIERS/PATCH_SUPPLIER_STATUS_SUCCESS',
    PATCH_SUPPLIER_STATUS_FAILED: 'SUPPLIERS/PATCH_SUPPLIER_STATUS_FAILED',

    GET_SUPPLIER: 'SUPPLIERS/GET_SUPPLIER',
    GET_SUPPLIER_SUCCESS: 'SUPPLIERS/GET_SUPPLIER_SUCCESS',
    GET_SUPPLIER_FAILED: 'SUPPLIERS/GET_SUPPLIER_FAILED',

    PATCH_SUPPLIER: 'SUPPLIERS/PATCH_SUPPLIER',
    PATCH_SUPPLIER_SUCCESS: 'SUPPLIERS/PATCH_SUPPLIER_SUCCESS',
    PATCH_SUPPLIER_FAILED: 'SUPPLIERS/PATCH_SUPPLIER_FAILED',

    POST_SUPPLIER: 'SUPPLIERS/POST_SUPPLIER',
    POST_SUPPLIER_SUCCESS: 'SUPPLIERS/POST_SUPPLIER_SUCCESS',
    POST_SUPPLIER_FAILED: 'SUPPLIERS/POST_SUPPLIER_FAILED',
});

export default actionTypes;