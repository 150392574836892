import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, KTIcon, Loader } from '../../../_metronic/helpers'
import { Content } from '../../../_metronic/helpers/components/Content';

import { clearState, getCategories, getLookupValues, getOptions, getContents } from './localizationActions';

const Contents = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        items,
        contents,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getCategories,
        getLookupValues,
        getOptions,
        getContents
    } = props;


    const [table, setTable] = useState(0);
    const [tableName, setTableName] = useState();

    useEffect(() => {

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        MenuComponent.reinitialization();
    }, [items])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])


    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'LOCALIZATION.TITLE' }),
            path: '/localization/contents',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onTableChanges = (value) => {
        setTable(value)
        if (value == 1) {
            setTableName('LookupTypeValue')
            getLookupValues();
            getContents('LookupTypeValue', 'Name');
        } else if (value == 2) {
            setTableName('OptionValue')
            getOptions();
            getContents('OptionValue', 'Name');
        }
        else if (value == 3) {
            setTableName('Category')
            getCategories();
            getContents('Category', 'Name');
        }
    }


    const contentStyle = {
        float: "left",
        marginLeft: "20px",
        color: "red"
    };


    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'LOCALIZATION.CONTENTS.BREADCRUMB' })}</PageTitle>

            <KTCard>

                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <select className="form-select form-select-solid fw-bold fs-6 border-0 bg-light w-150px" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" onChange={(e) => onTableChanges(e.target.value)} value={table}>
                                <option value={0}>Select</option>
                                <option value={1}>Lookups</option>
                                <option value={2}>Options</option>
                                <option value={3}>Categories</option>
                            </select>
                        </div>
                    </div>
                    <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                        </div>
                    </div>
                </div>
                <KTCardBody className='table-responsive m-grid-responsive-md'>

                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>#</th>
                                {tableName == "LookupTypeValue" && <th>{intl.formatMessage({ id: 'LOCALIZATION.CONTENT.TYPE' })}</th>}
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.CONTENT.NAME' })}</th>
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.CONTENT.TRANSLATEDNAME' })}</th>
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.CONTENT.ACTIONS' })}</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {
                                items && items.map((item, index) => {
                                    return (
                                        (
                                            <tr key={item.id} className='text-start'>
                                                <td>
                                                    {++index}
                                                </td>
                                                {tableName == "LookupTypeValue" && <td>{item.lookupValueName}</td>}
                                                <td>{item.name}</td>
                                                <td>{contents && contents.map((content, i) => {
                                                    if (content.identifier == item.id) {
                                                        return (
                                                            <>
                                                                <span><b style={{ fontSize: "15px", fontWeight: "bolder" }}>{content.languageCode}:</b>  {content.value}</span>
                                                                <br></br>
                                                            </>
                                                        )
                                                    }
                                                })}</td>
                                                <td>
                                                    <Content style={contentStyle} table={tableName} identifier={item.id} field={"Name"} />
                                                </td>
                                            </tr>
                                        )
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </KTCardBody>
            </KTCard >
            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        items: state.Localization.items,
        contents: state.Localization.contents,
        loading: state.Localization.loading,
        success: state.Localization.success
    };
};

const mapActionsToProps = {
    clearState, getCategories, getOptions, getLookupValues, getContents
}

export default connect(mapStateToProps, mapActionsToProps)(Contents)