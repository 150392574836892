/* STOCKS */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'STOCKS/CLEAR_STATE',

    GET_VARIANTS: 'STOCKS/GET_VARIANTS',
    GET_VARIANTS_SUCCESS: 'STOCKS/GET_VARIANTS_SUCCESS',
    GET_VARIANTS_FAILED: 'STOCKS/GET_VARIANTS_FAILED',

    GET_STOCKS: 'STOCKS/GET_STOCKS',
    GET_STOCKS_SUCCESS: 'STOCKS/GET_STOCKS_SUCCESS',
    GET_STOCKS_FAILED: 'STOCKS/GET_STOCKS_FAILED',

    GET_STOCKS_CHARTS: 'STOCKS/GET_STOCKS_CHARTS',

    POST_STOCKS: 'STOCKS/POST_STOCKS',
    POST_STOCKS_SUCCESS: 'STOCKS/POST_STOCKS_SUCCESS',
    POST_STOCKS_FAILED: 'STOCKS/POST_STOCKS_FAILED',

    GET_VARIANT: 'STOCKS/GET_VARIANT',
    GET_VARIANT_SUCCESS: 'STOCKS/GET_VARIANT_SUCCESS',
    GET_VARIANT_FAILED: 'STOCKS/GET_VARIANT_FAILED',

    GET_SUPPLIERS: 'STOCKS/GET_SUPPLIERS',
    

});

export default actionTypes;