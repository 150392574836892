/* PRODUCTS */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'PRODUCTS/CLEAR_STATE',

    GET_PRODUCTS: 'PRODUCTS/GET_PRODUCTS',
    GET_PRODUCTS_SUCCESS: 'PRODUCTS/GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILED: 'PRODUCTS/GET_PRODUCTS_FAILED',

    PATCH_PRODUCT_STATUS: 'USERS/PATCH_PRODUCT_STATUS',
    PATCH_PRODUCT_STATUS_SUCCESS: 'USERS/PATCH_PRODUCT_STATUS_SUCCESS',
    PATCH_PRODUCT_STATUS_FAILED: 'USERS/PATCH_PRODUCT_STATUS_FAILED',

    GET_PRODUCT: 'PRODUCTS/GET_PRODUCT',
    GET_PRODUCT_SUCCESS: 'PRODUCTS/GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILED: 'PRODUCTS/GET_PRODUCT_FAILED',

    PATCH_PRODUCT: 'PRODUCTS/PATCH_PRODUCT',
    PATCH_PRODUCT_SUCCESS: 'PRODUCTS/PATCH_PRODUCT_SUCCESS',
    PATCH_PRODUCT_FAILED: 'PRODUCTS/PATCH_PRODUCT_FAILED',

    POST_PRODUCT: 'PRODUCTS/POST_PRODUCT',
    POST_PRODUCT_SUCCESS: 'PRODUCTS/POST_PRODUCT_SUCCESS',
    POST_PRODUCT_FAILED: 'PRODUCTS/POST_PRODUCT_FAILED',

    POST_PRODUCT_CLONE: 'PRODUCTS/POST_PRODUCT_CLONE',
    POST_PRODUCT_CLONE_SUCCESS: 'PRODUCTS/POST_PRODUCT_CLONE_SUCCESS',
    POST_PRODUCT_CLONE_FAILED: 'PRODUCTS/POST_PRODUCT_CLONE_FAILED',

    POST_PRODUCT_IMAGE: 'PRODUCTS/POST_PRODUCT_IMAGE',
    POST_PRODUCT_IMAGE_SUCCESS: 'PRODUCTS/POST_PRODUCT_IMAGE_SUCCESS',
    POST_PRODUCT_IMAGE_FAILED: 'PRODUCTS/POST_PRODUCT_IMAGE_FAILED',

    GET_PRODUCT_IMAGES: 'PRODUCTS/GET_PRODUCT_IMAGES',
    GET_PRODUCT_IMAGES_SUCCESS: 'PRODUCTS/GET_PRODUCT_IMAGES_SUCCESS',
    GET_PRODUCT_IMAGES_FAILED: 'PRODUCTS/GET_PRODUCT_IMAGES_FAILED',

    DELETE_PRODUCT_IMAGE: 'PRODUCTS/DELETE_PRODUCT_IMAGE',
    DELETE_PRODUCT_IMAGE_SUCCESS: 'PRODUCTS/DELETE_PRODUCT_IMAGE_SUCCESS',
    DELETE_PRODUCT_IMAGE_FAILED: 'PRODUCTS/DELETE_PRODUCT_IMAGE_FAILED',

    PATCH_PRODUCT_IMAGE: 'PRODUCTS/PATCH_PRODUCT_IMAGE',
    PATCH_PRODUCT_IMAGE_SUCCESS: 'PRODUCTS/PATCH_PRODUCT_IMAGE_SUCCESS',
    PATCH_PRODUCT_IMAGE_FAILED: 'PRODUCTS/PATCH_PRODUCT_IMAGE_FAILED',

    GET_VARIANTS: 'PRODUCTS/GET_VARIANTS',
    GET_VARIANTS_SUCCESS: 'PRODUCTS/GET_VARIANTS_SUCCESS',
    GET_VARIANTS_FAILED: 'PRODUCTS/GET_VARIANTS_FAILED',

    POST_VARIANT: 'PRODUCTS/POST_VARIANT',
    POST_VARIANT_SUCCESS: 'PRODUCTS/POST_VARIANT_SUCCESS',
    POST_VARIANT_FAILED: 'PRODUCTS/POST_VARIANT_FAILED',

    PATCH_VARIANT: 'PRODUCTS/PATCH_VARIANT',
    PATCH_VARIANT_SUCCESS: 'PRODUCTS/PATCH_VARIANT_SUCCESS',
    PATCH_VARIANT_FAILED: 'PRODUCTS/PATCH_VARIANT_FAILED',

    GET_OPTION_VALUES: 'PRODUCTS/GET_OPTION_VALUES',
    GET_CATEGORIES: 'PRODUCTS/GET_CATEGORIES',
    GET_SUPPLIERS: 'PRODUCTS/GET_SUPPLIERS',
    GET_MODELS: 'PRODUCTS/GET_MODELS',
    GET_LOOKUP_VALUES: 'PRODUCTS/GET_LOOKUP_VALUES'

});

export default actionTypes;