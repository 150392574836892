import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import { clearState, getCartSummary } from '../../../../app/pages/checkout/checkoutActions';


const HeaderCartMenu = (props) => {

  var navigate = useNavigate();

  //inputs from redux
  const {
    cartSummary,
    loading
  } = props;

  //actions
  const {
    clearState,
    getCartSummary
  } = props;

  const [items, setItems] = useState([])

  useEffect(() => {
    getCartSummary();
    return () => {
      clearState()
    }
  }, []);

  useEffect(() => {

    if (cartSummary && cartSummary.items) {
      setItems(cartSummary.items)
    }
  }, [cartSummary])


  return (
    <div onClick={() => navigate("/checkout-management/checkout-summary")} style={{ marginRight: "10px" }}>
      <button className="btn  btn-icon position-relative me-5">
        <i className="ki-duotone ki-basket fs-2x"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></i>
        <span className="position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary">{items.length}</span>
      </button>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    cartSummary: state.Checkout.cartSummary,
    success: state.Checkout.success
  };
};

const mapActionsToProps = {
  clearState, getCartSummary
}

export default connect(mapStateToProps, mapActionsToProps)(HeaderCartMenu)
