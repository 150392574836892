// @flow
import { createStore, applyMiddleware, compose } from 'redux';

import reducers from './reducers';

import reduxThunk from 'redux-thunk';

export function configureStores() {

    const store = createStore(reducers, applyMiddleware(reduxThunk));
    return store;
}
