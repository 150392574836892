// @flow
import types from './locationConstants';
import { getAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getCountries = () => async dispatch => {
    const response = await getAsync(`/countries`);
    dispatch({
        type: types.GET_COUNTRIES,
        payload: response.data
    })
}

export const getCities = (countryId) => async dispatch => {
    const response = await getAsync(`/countries/${countryId}/cities`);
    dispatch({
        type: types.GET_CITIES,
        payload: response.data
    })

}

export const getPhoneCodes = () => async dispatch => {
    const response = await getAsync(`/countries`);
    dispatch({
        type: types.GET_PHONE_CODES,
        payload: response.data,
    })
}
