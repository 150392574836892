// @flow
import types from '../localization/localizationConstants';
import { getAsync, patchAsync, postAsync, deleteAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getLookupValues = () => async dispatch => {

    const response = await getAsync(`/lookup/values`);
    dispatch({
        type: types.GET_LOOKUPS,
        payload: response,
    })
}

export const getOptions = () => async dispatch => {

    const response = await getAsync(`/options/1/values`);
    dispatch({
        type: types.GET_OPTIONS,
        payload: response,
    })
}

export const getCategories = () => async dispatch => {

    const response = await getAsync(`/categories`);
    dispatch({
        type: types.GET_CATEGORIES,
        payload: response,
    })
}

export const getResources = () => async dispatch => {

    const response = await getAsync(`/resources`);
    dispatch({
        type: types.GET_RESOURCES,
        payload: response,
    })
}

export const patchResources = (item) => async dispatch => {

    dispatch({
        type: types.PATCH_RESOURCES
    })

    const response = await patchAsync(`/resources`, item);
    dispatch({
        type: types.PATCH_RESOURCES_DONE,
        payload: response,
    })
}

export const postResources = (item) => async dispatch => {

    dispatch({
        type: types.POST_RESOURCES
    })

    const response = await postAsync(`/resources`, item);
    dispatch({
        type: types.POST_RESOURCES_DONE,
        payload: response,
    })

    dispatch(getResources());
}

export const deleteResources = (id) => async dispatch => {

    dispatch({
        type: types.POST_RESOURCES
    })

    const response = await deleteAsync(`/resources/${id}`);
    dispatch({
        type: types.POST_RESOURCES_DONE,
        payload: response,
    })

    dispatch(getResources());
}

export const getContents = (table,field) => async dispatch => {

    const response = await getAsync(`/contents?table=${table}&field=${field}`);
    dispatch({
        type: types.GET_CONTENTS,
        payload: response,
    })
}


