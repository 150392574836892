// @flow
import types from '../dev-support/devSupportConstants';
import { deleteAsync, getAsync, patchAsync, postAsync, uploadAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getDevSupportTicketList = (keyword = '') => async dispatch => {

    dispatch({
        type: types.GET_DEV_SUPPORT_TICKETS
    })

    const response = await getAsync(`/dev-support/tickets/?keyword=${keyword}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_DEV_SUPPORT_TICKETS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_DEV_SUPPORT_TICKETS_FAILED,
            payload: response,
        })
    }
}

export const changeState = (id, newState) => async dispatch => {

    dispatch({
        type: types.PATCH_DEV_SUPPORT_TICKET_STATE
    })

    var request = {
        state: newState
    };

    const response = await patchAsync(`/dev-support/tickets/${id}`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_DEV_SUPPORT_TICKET_STATE_SUCCESS,
            payload: { id, newState }
        })
    } else {
        dispatch({
            type: types.PATCH_DEV_SUPPORT_TICKET_STATE_FAILED,
            payload: id,
        })
    }
}

export const getDevSupportTicket = (id) => async dispatch => {
    dispatch({
        type: types.GET_DEV_SUPPORT_TICKET
    })

    const response = await getAsync(`/dev-support/tickets/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_DEV_SUPPORT_TICKET_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_DEV_SUPPORT_TICKET_FAILED,
            payload: response,
        })
    }
}

export const postDevSupportTicket = (devSupportTicket) => async dispatch => {
    dispatch({
        type: types.POST_DEV_SUPPORT_TICKET
    })

    const response = await postAsync(`/dev-support/tickets`, devSupportTicket);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_DEV_SUPPORT_TICKET_SUCCESS,
            payload: response.data,
        })
    } else {
        dispatch({
            type: types.POST_DEV_SUPPORT_TICKET_FAILED,
            payload: response,
        })
    }
}

export const patchDevSupportTicket = (id, devSupportTicket) => async dispatch => {
    dispatch({
        type: types.PATCH_DEV_SUPPORT_TICKET
    })

    const response = await patchAsync(`/dev-support/tickets/${id}`, devSupportTicket);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_DEV_SUPPORT_TICKET_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_DEV_SUPPORT_TICKET_FAILED,
            payload: response,
        })
    }
}

export const getDevSupportTickets = () => async dispatch => {

    const response = await getAsync(`/dev-support/tickets`);
    dispatch({
        type: types.GET_DEV_SUPPORT_TICKETS,
        payload: response,
    })
}

export const uploadImage = (file) => async dispatch => {

    var request = {
        identifier: file.id,
        fileName: file.name,
        fileExtension: file.extension,
        actionType: file.actionType
    };

    const response = await postAsync(`/signed-url/upload`, request);

    var data = {
        id: file.id,
        file: file.file
    }

    if (response.isSuccess) {
        await uploadAsync(response.data.url, data, async () => { });
    }

    return response;
}

