/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useIntl } from 'react-intl'

import { clearState, getUnreadNotifications, patchNotification } from '../../../../app/pages/notifications/notificationActions';


const HeaderNotificationsMenu = (props) => {
  const intl = useIntl()

  //inputs from redux
  const {
    notifications,
    loading
  } = props;

  //actions
  const {
    clearState,
    getUnreadNotifications,
    patchNotification
  } = props;


  useEffect(() => {

    getUnreadNotifications();

    return () => {
      clearState()
    }
  }, []);

  const onNotificationClick = (item) => {

    patchNotification(item.id);

    setTimeout(() => {
      window.location.href = item.redirect;
    }, 500);
  }

  return (
    <div>
      <div
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"
        style={{ marginRight: "10px" }}
      >
        {
          notifications.length > 0 ?
            <a href="#" className="btn btn-icon btn-light pulse pulse-dark">
              <i className="ki-duotone ki-notification-status fs-2x"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></i>
              <span className="pulse-ring"></span>
            </a> :
            <i className="ki-duotone ki-subtitle fs-2x"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></i>
        }
      </div>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
        data-kt-menu='true'
      >
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
        >
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          {intl.formatMessage({ id: 'NOTIFICATION.HEADER.NAME' })} <span className='fs-8 opacity-75 ps-3'>{notifications.length > 0 ? `${notifications.length} reports` : ``} </span>
          </h3>

          <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
            <li className='nav-item'>
              <a
                className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_1'
              >
                {intl.formatMessage({ id: 'NOTIFICATION.HEADER.LOGS' })}
              </a>
            </li>
          </ul>
        </div>

        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div className='scroll-y mh-325px my-5 px-8'>

              {notifications.length == 0 ? <>{intl.formatMessage({ id: 'NOTIFICATION.HEADER.EMPTY_MESSAGES' })}</> : <></>}

              {notifications.map((alert, index) => (
                <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px me-4'>
                      <span className={clsx('symbol-label', `bg-light-primary`)}>
                        {' '}
                        <KTIcon iconName='graph-3' className={`fs-2 text-primary`} />
                      </span>
                    </div>

                    <div className='mb-0 me-2'>
                      <a href="#" onClick={() => { onNotificationClick(alert) }} className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                        {alert.subject}
                      </a>
                      <div className='text-gray-400 fs-7'>{alert.body}</div>
                    </div>
                  </div>

                  <span className='badge badge-light fs-8'>{alert.createdOnString}</span>
                </div>
              ))}
            </div>

            <div className='py-3 text-center border-top'>
              <Link
                to='/profile#profile_tab_5'
                className='btn btn-color-gray-600 btn-active-color-primary'
              >
                {intl.formatMessage({ id: 'NOTIFICATION.HEADER.VIEW_ALL' })} <KTIcon iconName='arrow-right' className='fs-5' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    notifications: state.Notification.unreadNotifications,
    success: state.Notification.success
  };
};

const mapActionsToProps = {
  clearState, getUnreadNotifications, patchNotification
}

export default connect(mapStateToProps, mapActionsToProps)(HeaderNotificationsMenu)
