// @flow
import types from './checkoutConstants';

const INIT_STATE = {
    data: {},
    cart: null,
    cartSummary: null,
    paymentMethods: [],
    order: null,
    paymentResult: null,
    payResult: null,
    loading: false,
    success: null
};

const Checkout = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //Get Checkout
        case types.GET_FORM:
            return {
                ...state,
                loading: true
            };
        case types.GET_FORM_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_FORM_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Checkout

        //Get Cart
        case types.GET_CART:
            return {
                ...state,
                loading: true
            };
        case types.GET_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                cart: action.payload.data,
                error: null
            };
        case types.GET_CART_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Get Cart


        //Get Cart Summary
        case types.GET_CART_SUMMARY:
            return {
                ...state,
                loading: true
            };
        case types.GET_CART_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                cartSummary: action.payload.data,
                error: null
            };
        case types.GET_CART_SUMMARY_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Get Cart Summary

        //Post Cart
        case types.POST_CART:
            return {
                ...state,
                success: null,
                error: null,
                loading: true
            };
        case types.POST_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.TotalPrice,
                error: null
            };
        case types.POST_CART_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Post Cart

        //Delete Cart
        case types.DELETE_CART:
            return {
                ...state,
                loading: true
            };
        case types.DELETE_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                cartSummary: { ...state.cartSummary, items: [] },
                error: null
            };
        case types.DELETE_CART_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Delete Cart

        //Post Order
        case types.POST_ORDER:
            return {
                ...state,
                loading: true
            };
        case types.POST_ORDER_SUCCESS:
            return {
                ...state,
                success: types.POST_ORDER_SUCCESS,
                loading: false,
                error: null
            };
        case types.POST_ORDER_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Post Order

        //Get checkout pay
        case types.GET_CHECKOUT_PAY:
            return {
                ...state,
                loading: true
            };
        case types.GET_CHECKOUT_PAY_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethods: action.payload.data.paymentMethods,
                paymentResult: action.payload.data.paymentResult,
                order: action.payload.data.order,
                error: null
            };
        case types.GET_CHECKOUT_PAY_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Get checkout pay


        //Post checkout pay
        case types.POST_CHECKOUT_PAY:
            return {
                ...state,
                loading: true
            };
        case types.POST_CHECKOUT_PAY_SUCCESS:
            return {
                ...state,
                loading: false,
                payResult: action.payload.data,
                error: null
            };
        case types.POST_CHECKOUT_PAY_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Post checkout pay

        //Get Payment Methods
        case types.GET_PAYMENT_METHODS:
            return {
                ...state,
                loading: true
            };
        case types.GET_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethods: action.payload.data,
                error: null
            };
        case types.GET_PAYMENT_METHODS_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Get Payment Methods

        //Get Check Order
        case types.GET_THANKS_PAGE:
            return {
                ...state,
                loading: true
            };
        case types.GET_THANKS_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                order: action.payload.data,
                error: null
            };
        case types.GET_THANKS_PAGE_FAILED:
            return {
                ...state,
                order: action.payload,
                success: null,
                loading: false
            };
        //Get Payment Methods



        default:
            return state;
    }
};

export default Checkout;
