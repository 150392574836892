// @flow
import types from './priceConstants';

const INIT_STATE = {
    verandaTypes: [],
    productTypes: [],
    productColors: [],
    verandaPrices: [],
    unitPrices: [],
    unitTypes: [],
    loading: false,
    success: null
};

const Price = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        case types.GET_VERANDA_TYPES:
            return {
                ...state,
                verandaTypes: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_PRODUCT_TYPES:
            return {
                ...state,
                productTypes: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_PRODUCT_COLORS:
            return {
                ...state,
                productColors: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_VERANDA_PRICES:
            return {
                ...state,
                verandaPrices: action.payload.data.items,
                loading: false,
                error: null
            };
        case types.PATCH_VERANDA_PRICES:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_VERANDA_PRICES_SUCCESS:
            return {
                ...state,
                success: 'VERANDA_PRICE.UPDATED.SUCESS',
                loading: false,
                error: null
            };
        case types.PATCH_VERANDA_PRICES_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };


        case types.GET_UNIT_PRICES:
            return {
                ...state,
                unitPrices: action.payload.data.items,
                loading: false,
                error: null
            };
        case types.GET_UNIT_TYPES:
            return {
                ...state,
                unitTypes: action.payload.data,
                loading: false,
                error: null
            };
        case types.PATCH_UNIT_PRICES:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_UNIT_PRICES_SUCCESS:
            return {
                ...state,
                success: 'UNIT_PRICE.UPDATED.SUCESS',
                loading: false,
                error: null
            };
        case types.PATCH_UNIT_PRICES_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default Price;
