/* USERS */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'USERS/CLEAR_STATE',

    GET_USERS: 'USERS/GET_USERS',
    GET_USERS_SUCCESS: 'USERS/GET_USERS_SUCCESS',
    GET_USERS_FAILED: 'USERS/GET_USERS_FAILED',

    PATCH_USER_STATUS: 'USERS/PATCH_USER_STATUS',
    PATCH_USER_STATUS_SUCCESS: 'USERS/PATCH_USER_STATUS_SUCCESS',
    PATCH_USER_STATUS_FAILED: 'USERS/PATCH_USER_STATUS_FAILED',

    GET_USER: 'USERS/GET_USER',
    GET_USER_SUCCESS: 'USERS/GET_USER_SUCCESS',
    GET_USER_FAILED: 'USERS/GET_USER_FAILED',

    POST_USER: 'USERS/POST_USER',
    POST_USER_SUCCESS: 'USERS/POST_USER_SUCCESS',
    POST_USER_FAILED: 'USERS/POST_USER_FAILED',

    PATCH_USER: 'USERS/PATCH_USER',
    PATCH_USER_SUCCESS: 'USERS/PATCH_USER_SUCCESS',
    PATCH_USER_FAILED: 'USERS/PATCH_USER_FAILED',

    PATCH_USER_POLICIY: 'USERS/PATCH_USER_POLICY',
    PATCH_USER_POLICIY_SUCCESS: 'USERS/PATCH_USER_POLICY_SUCCESS',
    PATCH_USER_POLICIY_FAILED: 'USERS/PATCH_USER_POLICY_FAILED',

    POST_USER_POLICIY: 'USERS/POST_USER_POLICY',
    POST_USER_POLICIY_SUCCESS: 'USERS/POST_USER_POLICY_SUCCESS',
    POST_USER_POLICIY_FAILED: 'USERS/POST_USER_POLICY_FAILED',

    GET_NEW_AVATAR: 'USERS/GET_NEW_AVATAR',

    GET_CONTACT: 'USERS/GET_CONTACT',
    GET_CONTACT_SUCCESS: 'USERS/GET_CONTACT_SUCCESS',
    GET_CONTACT_FAILED: 'USERS/GET_CONTACT_FAILED',

    POST_CONTACT: 'USERS/POST_CONTACT',
    POST_CONTACT_SUCCESS: 'USERS/POST_CONTACT_SUCCESS',
    POST_CONTACT_FAILED: 'USERS/POST_CONTACT_FAILED',

    PUT_CONTACT: 'USERS/PUT_CONTACT',
    PUT_CONTACT_SUCCESS: 'USERS/PUT_CONTACT_SUCCESS',
    PUT_CONTACT_FAILED: 'USERS/PUT_CONTACT_FAILED',

    GET_POLICIES: 'POLICIES/GET_POLICIES',
    GET_POLICIES_SUCCESS: 'POLICIES/GET_POLICIES_SUCCESS',
    GET_POLICIES_FAILED: 'POLICIES/GET_POLICIES_FAILED',
});

export default actionTypes;