// @flow
import types from './stockConstants';
import { getAsync, postAsync, uploadAsync } from '../../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getVariantList = () => async dispatch => {

    dispatch({
        type: types.GET_VARIANTS
    })

    const response = await getAsync(`/stocks`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_VARIANTS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_VARIANTS_FAILED,
            payload: response,
        })
    }
}

export const getVariantStockList = (id) => async dispatch => {

    dispatch({
        type: types.GET_STOCKS
    })

    const response = await getAsync(`/stocks/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_STOCKS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_STOCKS_FAILED,
            payload: response,
        })
    }
}

export const getChartData = (id) => async dispatch => {
    const response = await getAsync(`/stocks/${id}/charts`);

    dispatch({
        type: types.GET_STOCKS_CHARTS,
        payload: response,
    })
}

export const getVariant = (id = '', variantCode = '') => async dispatch => {

    dispatch({
        type: types.GET_VARIANT
    })

    const response = await getAsync(`/variants?id=${id}&code=${variantCode}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_VARIANT_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_VARIANT_FAILED,
            payload: response,
        })
    }
}

export const postStock = (variantId, quantity) => async dispatch => {

    dispatch({
        type: types.POST_STOCKS
    })
    const response = await postAsync(`/stocks`, { variantId, quantity });

    if (response.isSuccess) {
        dispatch({
            type: types.POST_STOCKS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.POST_STOCKS_FAILED,
            payload: response,
        })
    }
}

export const getSuppliers = (productId) => async dispatch => {
    const response = await getAsync(`/products/${productId}/suppliers`);

    dispatch({
        type: types.GET_SUPPLIERS,
        payload: response,
    })
}

export const uploadImage = (variantId, file) => async dispatch => {

    var request = {
        identifier: variantId,
        fileName: file.name,
        fileExtension: file.extension,
        actionType: file.actionType
    };

    const response = await postAsync(`/signed-url/upload`, request);

    var data = {
        id: file.id,
        file: file.file
    }

    if (response.isSuccess) {
        await uploadAsync(response.data.url, data, async () => { });
    }

    return response;
}

export const postCasualty = (request) => async dispatch => {

    const response = await postAsync(`/stocks`, request);
    return response;

}


