// @flow
import types from './userConstants';
import { getAsync, patchAsync, postAsync, putAsync, uploadAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getUserList = (keyword = '', type = '', isActive = '', isApprove = '') => async dispatch => {

    dispatch({
        type: types.GET_USERS
    })

    const response = await getAsync(`/users?keyword=${keyword}&type=${type}&isActive=${isActive}&isApprove=${isApprove}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_USERS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_USERS_FAILED,
            payload: response,
        })
    }
}

export const changeStatus = (id, isActive) => async dispatch => {

    dispatch({
        type: types.PATCH_USER_STATUS
    })

    var request = {
        isActive: isActive
    };

    const response = await patchAsync(`/users/${id}`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_USER_STATUS_SUCCESS,
            payload: { id, isActive }
        })
    } else {
        dispatch({
            type: types.PATCH_USER_STATUS_FAILED,
            payload: id,
        })
    }
}

export const getUser = (id) => async dispatch => {

    dispatch({
        type: types.GET_USER
    })

    const response = await getAsync(`/users/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_USER_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.GET_USER_FAILED,
            payload: response
        })
    }
}

export const postUser = (user) => async dispatch => {

    dispatch({
        type: types.POST_USER
    })

    const response = await postAsync(`/users`, user);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_USER_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.POST_USER_FAILED,
            payload: response
        })
    }
}

export const patchUser = (user) => async dispatch => {

    dispatch({
        type: types.PATCH_USER
    })

    const response = await patchAsync(`/users/${user.id}`, user);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_USER_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.PATCH_USER_FAILED,
            payload: response
        })
    }
}

export const uploadAvatar = (file) => async dispatch => {

    var request = {
        identifier: file.id,
        fileName: file.name,
        fileExtension: file.extension,
        actionType: file.actionType
    };

    const response = await postAsync(`/signed-url/upload`, request);

    var data = {
        id: file.id,
        file: file.data
    }

    if (response.isSuccess) {
        await uploadAsync(response.data.url, data, () => { });

        dispatch({
            type: types.GET_NEW_AVATAR,
            payload: response.data
        })
    }
}

export const getContact = (id) => async dispatch => {

    dispatch({
        type: types.GET_CONTACT
    })

    const response = await getAsync(`/users/${id}/contact`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CONTACT_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.GET_CONTACT_FAILED,
            payload: response
        })
    }
}

export const postContact = (contact) => async dispatch => {

    dispatch({
        type: types.POST_CONTACT
    })

    const response = await postAsync(`/users/${contact.userId}/contact`, contact);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_CONTACT_SUCCESS,
            payload: response
        })

        dispatch(getContact(contact.userId))
    } else {
        dispatch({
            type: types.POST_CONTACT_FAILED,
            payload: response
        })
    }
}

export const putContact = (contact) => async dispatch => {

    dispatch({
        type: types.PUT_CONTACT
    })

    const response = await putAsync(`/users/${contact.userId}/contact/${contact.id}`, contact);

    if (response.isSuccess) {
        dispatch({
            type: types.PUT_CONTACT_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.PUT_CONTACT_FAILED,
            payload: response
        })
    }
}

export const getPolicies = () => async dispatch => {

    const response = await getAsync(`/policies`);
    dispatch({
        type: types.GET_POLICIES,
        payload: response,
    })
}

