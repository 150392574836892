// @flow
import types from '../supplier/supplierConstants';

const INIT_STATE = {
    supplierList: [],
    supplier: {},
    loading: false,
    success: null,
    error: null
};

const Supplier = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //List
        case types.GET_SUPPLIERS:
            return {
                ...state,
                loading: true
            };
        case types.GET_SUPPLIERS_SUCCESS:

            return {
                ...state,
                supplierList: action.payload.data.items,
                loading: false,
                error: null
            };
        case types.GET_SUPPLIERS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //Change Status
        case types.PATCH_SUPPLIER_STATUS:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_SUPPLIER_STATUS_SUCCESS:

            var list = [...state.supplierList].map((item, index) => {
                if (item.id == action.payload.id) {
                    return {
                        ...item,
                        isActive: action.payload.isActive
                    }
                }
                return item;
            });

            return {
                ...state,
                supplierList: list,
                loading: false,
                success: 'SUPPLIER.STATUS.UPDATE.SUCESS',
                error: null
            };
        case types.PATCH_SUPPLIER_STATUS_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Change Status

        //supplier
        case types.GET_SUPPLIER:
            return {
                ...state,
                loading: true
            };
        case types.GET_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_SUPPLIER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case types.POST_SUPPLIER:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_SUPPLIER_SUCCESS:
            return {
                ...state,
                success: 'SUPPLIER.CREATED.SUCESS',
                loading: false,
                error: null
            };
        case types.POST_SUPPLIER_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        case types.PATCH_SUPPLIER:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_SUPPLIER_SUCCESS:
            return {
                ...state,
                success: 'SUPPLIER.UPDATED.SUCESS',
                loading: false,
                error: null
            };
        case types.PATCH_SUPPLIER_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //supplier

        default:
            return state;
    }
};

export default Supplier;
