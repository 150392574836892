import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, KTIcon, Loader } from '../../../_metronic/helpers'

import { clearState, getResources, patchResources, postResources, deleteResources } from './localizationActions';

const Resources = (props) => {
    //inputs from url
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        items,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getResources,
        patchResources,
        postResources,
        deleteResources
    } = props;

    const [list, setList] = useState([]);

    useEffect(() => {

        getResources();
        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        MenuComponent.reinitialization();
        setList(items);
    }, [items])


    useEffect(() => {
        if (success) {
            toast.info(intl.formatMessage({ id: success }));
        }
    }, [success])


    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'LOCALIZATION.TITLE' }),
            path: '/localization/resources',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onValueChanged = (value, item) => {
        var newList = list.map((x) => {
            if (x.id === item.id) {
                x.value = value;
            }
            return x;
        })
        setList(newList)
    }

    const onLanguageChanged = (value, item) => {
        var newList = list.map((x) => {
            if (x.id === item.id) {
                x.languageCode = value;
            }
            return x;
        })
        setList(newList)
    }

    const onUpdateClicked = (item) => {
        patchResources(item);
    }

    const onAddClicked = (item) => {
        postResources(item);
    }

    const onDeleteClicked = (id) => {
        deleteResources(id);
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'LOCALIZATION.RESOURCES.BREADCRUMB' })}</PageTitle>

            <KTCard>

                <KTCardBody className='table-responsive m-grid-responsive-md'>

                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                            <tr className="text-center text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>#</th>
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.RESOURCE.NAME' })}</th>
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.RESOURCE.VALUE' })}</th>
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.RESOURCE.LANGUAGE' })}</th>
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.RESOURCE.UPDATE' })}</th>
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.RESOURCE.DELETE' })}</th>
                                <th>{intl.formatMessage({ id: 'LOCALIZATION.RESOURCE.CLONE' })}</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {
                                list && list.map((item, index) => {
                                    return (
                                        (
                                            <tr key={item.id} className='text-center'>
                                                <td>
                                                    {++index}
                                                </td>
                                                <td>{item.name}</td>
                                                <td><input type='text' className='form-control' value={item.value} onChange={(e) => onValueChanged(e.target.value, item)}></input></td>
                                                <td>
                                                    <select className='form-select' value={item.languageCode} onChange={(e) => onLanguageChanged(e.target.value, item)}>
                                                        <option value='tr'>tr</option>
                                                        <option value='en'>en</option>
                                                        <option value='de'>de</option>
                                                        <option value='nl'>nl</option>
                                                    </select>
                                                </td>
                                                <td><button className='btn btn-secondary' onClick={() => onUpdateClicked(item)}>{intl.formatMessage({ id: 'GENERAL.UPDATE' })}</button></td>
                                                <td><button className='btn btn-danger' onClick={() => onDeleteClicked(item.id)}>{intl.formatMessage({ id: 'GENERAL.DELETE' })}</button></td>
                                                <td><button className='btn btn-success' onClick={() => onAddClicked(item)}>{intl.formatMessage({ id: 'GENERAL.CLONE' })}</button></td>
                                            </tr>
                                        )
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </KTCardBody>
            </KTCard >
            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        items: state.Localization.items,
        loading: state.Localization.loading,
        success: state.Localization.success
    };
};

const mapActionsToProps = {
    clearState, getResources, patchResources, postResources, deleteResources
}

export default connect(mapStateToProps, mapActionsToProps)(Resources)