/* LOCALIZATION */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'LOCALIZATION/CLEAR_STATE',

    GET_LOOKUPS: 'LOCALIZATION/GET_LOOKUPS',
    GET_OPTIONS: 'LOCALIZATION/GET_OPTIONS',
    GET_CATEGORIES: 'LOCALIZATION/GET_CATEGORIES',
    GET_RESOURCES: 'LOCALIZATION/GET_RESOURCES',
    PATCH_RESOURCES: 'LOCALIZATION/PATCH_RESOURCES',
    PATCH_RESOURCES_DONE: 'LOCALIZATION/PATCH_RESOURCES_DONE',

    POST_RESOURCES: 'LOCALIZATION/POST_RESOURCES',
    POST_RESOURCES_DONE: 'LOCALIZATION/POST_RESOURCES_DONE',

    GET_CONTENTS: 'LOCALIZATION/GET_CONTENTS'

});

export default actionTypes;