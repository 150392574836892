// @flow
import types from './leadConstants';

const INIT_STATE = {
    campaigns: [],
    leads: [],
    users: [],
    historyList: [],
    lead: null,
    loading: false,
    success: null,
    error: null
};

const Lead = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //List
        case types.GET_LEADS:
            return {
                ...state,
                loading: true
            };
        case types.GET_LEADS_SUCCESS:
            return {
                ...state,
                leads: action.payload.data.items,
                loading: false,
                error: null
            };
        case types.GET_LEADS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //Detail
        case types.GET_LEAD:
            return {
                ...state,
                loading: true
            };
        case types.GET_LEAD_SUCCESS:
            return {
                ...state,
                lead: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_LEAD_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Detail

        //Campaigns
        case types.GET_CAMPAIGNS:
            return {
                ...state,
                loading: true
            };
        case types.GET_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                campaigns: action.payload.data.items,
                loading: false,
                error: null
            };
        case types.GET_CAMPAIGNS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //Patch
        case types.PATCH_LEAD:
            return {
                ...state,
                loading: true,
                success: null,
                error: null
            };
        case types.PATCH_LEAD_SUCCESS:

            var updatedLead = action.payload.data.items[0];

            state.leads.map((leadx, index) => {
                if (leadx.id === updatedLead.id) {
                    state.leads[index] = updatedLead;

                    if (state.lead && state.lead.id === updatedLead.id) {
                        state.lead.status = updatedLead.status;
                        state.lead.assignedUser = updatedLead.assignedUser;
                        state.lead.assignedBy = updatedLead.assignedBy;
                        state.lead.modifiedOn = updatedLead.modifiedOn;
                    }

                }
            });

            var newLeads = state.leads.sort((a, b) => b.sorting - a.sorting);

            return {
                ...state,
                leads: [...newLeads],
                success: "LEAD.PATCH_LEAD_SUCCESS",
                loading: false
            };
        case types.PATCH_LEAD_FAILED:
            return {
                ...state,
                error: "LEAD.PATCH_LEAD_FAILED",
                loading: false
            };
        //Patch

        case types.GET_USERS:
            return {
                ...state,
                users: action.payload.data
            };

        case types.GET_HISTORY:
            return {
                ...state,
                historyList: action.payload.data
            };

        //Comment
        case types.POST_COMMENT:
            return {
                ...state,
                loading: true,
                success: null,
                error: null
            };
        case types.POST_COMMENT_SUCCESS:

            if (state.lead) {
                state.lead.comments.push(action.payload);
            }

            return {
                ...state,
                lead: { ...state.lead },
                success: "LEAD.POST_COMMENT_SUCCESS",
                loading: false
            };
        case types.POST_COMMENT_FAILED:
            return {
                ...state,
                error: "LEAD.POST_COMMENT_FAILED",
                loading: false
            };
        //Comment

        default:
            return state;
    }
};

export default Lead;
