import { useDroppable } from '@dnd-kit/core';

function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: `droppable_${props.id}`,
    data: props.data
  });

  const style = {
    backgroundColor: isOver ? 'aliceblue' : undefined,
    ...props.style
  };

  return (
    <div ref={setNodeRef} style={style} className={props.className}>
      {props.children}
    </div>
  );
}

export default Droppable;