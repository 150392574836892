const OrderPrint = (props) => {
    const { items = [], order = null } = props;

    const renderContentItemName = (item, content) => {

        if (content && content.productId > 0) {
            return (
                <>
                    {content.productName}
                </>
            )
        }

        return (
            <><b>{item.name}</b></>
        )

    }

    const renderContentItemCode = (item, content) => {

        if (content && content.productId > 0) {
            return (
                <>
                    {content.variantName} - {content.variantCode}
                </>
            )
        }

        return null;
    }

    const renderContentItem = (item) => {
        if (item && item.contents && item.contents.length > 0) {
            return (
                <>
                    {
                        item.contents && item.contents.map((content) => {
                            var detail = content.additionalAttributesJson != '' && JSON.parse(content.additionalAttributesJson);
                            return (
                                <tr key={`item_content_tr_${content.id}`} style={{ fontWeight: "400", fontSize: "12px" }}>
                                    <td width={"20%"}>
                                        {renderContentItemName(item, content)}
                                    </td>
                                    <td width={"20%"}>
                                        {renderContentItemCode(item, content)}
                                    </td>
                                    <td width={"30%"}>
                                        {detail.Width ? <p>Width: {detail.Width}</p> : null}
                                        {detail.Depth ? <p>Depth: {detail.Depth}</p> : null}
                                        {detail.Height ? <p>Height: {detail.Height}</p> : null}
                                        {detail.FrameColorName ? <p>Frame Color: {detail.FrameColorName}</p> : null}
                                        {detail.OtherFrameColor ? <p>Other Frame Color: {detail.OtherFrameColor}</p> : null}
                                        {detail.Length ? <p>Length: {detail.Length}</p> : null}
                                        {detail.FrontHeight ? <p>Front Height: {detail.FrontHeight}</p> : null}
                                        {detail.RearHeight ? <p>Rear Height: {detail.RearHeight}</p> : null}
                                        {detail.OrderTypeName ? <p>Order Type: {detail.OrderTypeName}</p> : null}
                                        {detail.StandTypeName ? <p>Stand Type: {detail.StandTypeName}</p> : null}
                                        {detail.RoofTypeName ? <p>Roof Type: {detail.RoofTypeName}, {detail.ModelTypeName}</p> : null}
                                        {detail.LedTypeName ? <p>Led: {detail.LedTypeName}</p> : null}
                                        {detail.LedColorName ? <p>Led Color: {detail.LedColorName}</p> : null}
                                        {detail.NrOfLedPerColumn ? <p>Nr Of Led: {detail.NrOfLedPerColumn}</p> : null}
                                        {detail.MaterialColorName ? <p>Material Color: {detail.MaterialColorName}</p> : null}
                                        {detail.MaterialTypeName ? <p>Material Type: {detail.MaterialTypeName}</p> : null}
                                        {detail.SideName ? <p>Side: {detail.SideName}</p> : null}
                                        {detail.RailSetTypeName ? <p>RailSet: {detail.RailSetTypeName}</p> : null}
                                        {detail.IsMeenemerAdded ? <p>Has Meenemer: {detail.IsMeenemerAdded ? "Yes" : "No"}</p> : null}
                                        {detail.GlassWidth ? <p>Glass Width: {detail.GlassWidth}</p> : null}
                                        {detail.OtherGlassWidth ? <p>Other Glass Width: {detail.OtherGlassWidth}</p> : null}
                                        {detail.GlassHeight ? <p>Glass Height: {detail.GlassHeight}</p> : null}
                                        {detail.OtherGlassHeight ? <p>Other Glass Height: {detail.OtherGlassHeight}</p> : null}
                                        {detail.FreeText ? <p>Description: {detail.FreeText}</p> : null}
                                    </td>
                                    <td width={"10%"} style={{ textAlign: "center" }}>{content.quantity}</td>
                                    <td width={"10%"}></td>
                                    <td width={"10%"}></td>
                                </tr>
                            )
                        })
                    }
                </>
            )
        }
        return;
    }

    const renderItem = (item) => {
        var detail = JSON.parse(item.orderItemAttributesJson);
        return (
            <>
                {detail.Width ? <p>Width: {detail.Width}</p> : null}
                {detail.Depth ? <p>Depth: {detail.Depth}</p> : null}
                {detail.Height ? <p>Height: {detail.Height}</p> : null}
                {detail.FrameColorName ? <p>Frame Color: {detail.FrameColorName}</p> : null}
                {detail.OtherFrameColor ? <p>Other Frame Color: {detail.OtherFrameColor}</p> : null}
                {detail.Length ? <p>Length: {detail.Length}</p> : null}
                {detail.FrontHeight ? <p>Front Height: {detail.FrontHeight}</p> : null}
                {detail.RearHeight ? <p>Rear Height: {detail.RearHeight}</p> : null}
                {detail.OrderTypeName ? <p>Order Type: {detail.OrderTypeName}</p> : null}
                {detail.StandTypeName ? <p>Stand Type: {detail.StandTypeName}</p> : null}
                {detail.RoofTypeName ? <p>Roof Type: {detail.RoofTypeName}</p> : null}
                {detail.ModelTypeName ? <p>Model Type: {detail.ModelTypeName}</p> : null}
                {detail.LedTypeName ? <p>Led: {detail.LedTypeName}</p> : null}
                {detail.LedColorName ? <p>Led Color: {detail.LedColorName}</p> : null}
                {detail.NrOfLedPerColumn ? <p>Nr Of Led: {detail.NrOfLedPerColumn}</p> : null}
                {detail.MaterialColorName ? <p>Material Color: {detail.MaterialColorName}</p> : null}
                {detail.MaterialTypeName ? <p>Material Type: {detail.MaterialTypeName}</p> : null}
                {detail.SideName ? <p>Side: {detail.SideName}</p> : null}
                {detail.RailSetTypeName ? <p>RailSet: {detail.RailSetTypeName}</p> : null}
                {detail.IsMeenemerAdded ? <p>Has Meenemer: {detail.IsMeenemerAdded ? "Yes" : "No"}</p> : null}
                {detail.GlassWidth ? <p>Glass Width: {detail.GlassWidth}</p> : null}
                {detail.OtherGlassWidth ? <p>Other Glass Width: {detail.OtherGlassWidth}</p> : null}
                {detail.GlassHeight ? <p>Glass Height: {detail.GlassHeight}</p> : null}
                {detail.OtherGlassHeight ? <p>Other Glass Height: {detail.OtherGlassHeight}</p> : null}
            </>
        )
    }

    return (
        <>
            <title>&nbsp;</title>
            <style>
                {`
             html, #smprint div, #smprint span, #smprint applet, #smprint object, #smprint iframe, #smprint h1, #smprint h2, #smprint h3, #smprint h4, #smprint h5, #smprint h6, #smprint p, #smprint blockquote, #smprint pre, #smprint a, #smprint abbr, #smprint acronym, #smprint address, #smprint big, #smprint cite, #smprint code, #smprint del, #smprint dfn, #smprint em, #smprint img, #smprint ins, #smprint kbd, #smprint q, #smprint s, #smprint samp, #smprint small, #smprint strike, #smprint sub, #smprint sup, #smprint tt, #smprint var, #smprint b, #smprint u, #smprint i, #smprint center, #smprint dl, #smprint dt, #smprint dd, #smprint ol, #smprint ul, #smprint li, #smprint fieldset, #smprint form, #smprint label, #smprint legend, #smprint caption, #smprint tbody, #smprint tfoot, #smprint thead, #smprint tr, #smprint th, #smprint td, #smprint article, #smprint aside, #smprint canvas, #smprint details, #smprint embed, #smprint figure, #smprint figcaption, #smprint footer, #smprint header, #smprint hgroup, #smprint menu, #smprint nav, #smprint output, #smprint ruby, #smprint section, #smprint summary, #smprint time, #smprint mark, #smprint audio, #smprint video {
              margin: 0;
              padding: 0;
              border: 0;
              font-size: 100%;
              font: inherit;
              vertical-align: baseline;
            }
  
            /* HTML5 display-role reset for older browsers */
            article, aside, details, figcaption, figure,
            footer, header, hgroup, menu, nav, section {
                display: block;
            }
  
            body {
                margin: 5;
                padding: 5;
            }
  
            #smprint blockquote, #smprint q {
                quotes: none;
            }
  
            #smprint blockquote:before, #smprint blockquote:after,
            #smprint q:before, #smprint q:after {
                content: '';
                content: none;
            }
  
            b {
              font-weight: bolder !important;
            }

            #smprint table {
                font-family: arial, sans-serif;
                line-height: 15px !important;
            }
  
            #smprint.smprint-new table table { font-family: unset; width: 100%; } 

            #table-border tr td {border: 1px solid black !important; padding: 3px !important; }

            `}
            </style>
            <div id="smprint" className={'smprint-new'} >
                <div>
                    <table width={'790px'}>
                        <tr>
                            <td>
                                <img width="400px" src='https://storage.googleapis.com/thegardengallery-prod/company/logo.png' alt=""></img>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                                <table>
                                    <tr>
                                        <td><b style={{ fontSize: "18px" }}>DEALER ORDERBON</b></td>
                                    </tr>
                                    <tr>
                                        <td>Naam</td>
                                        <td>{order && order.user && order.user.name} {order && order.user && order.user.surname}</td>
                                    </tr>
                                    <tr>
                                        <td>Leverdatum</td>
                                        <td>{order && order.plannedDeliveryDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Ordernummer</td>
                                        <td>{order && order.id}</td>
                                    </tr>
                                    <tr>
                                        <td>Reference</td>
                                        <td>{order && order.reference}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ fontSize: "20px" }}>Order Details</td>
                        </tr>
                        <tr style={{ marginTop: "20px" }}>
                            <td colSpan={2} ><hr></hr></td>
                        </tr>

                        <tr>
                            <td colSpan={2}>

                                <table id="table-border">
                                    <thead style={{ fontWeight: "900" }}>
                                        <tr>
                                            <th colSpan={2}>Item</th>
                                            <th>Detail</th>
                                            <th colSpan={3}>QTY</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items && items.map((item, index) => (
                                            <>
                                                <tr key={index} style={{ fontWeight: "600", fontSize: "14px" }}>
                                                    <td width={"40%"} colSpan={2}>{item.name}</td>
                                                    <td width={"30%"}>{renderItem(item)}</td>
                                                    <td width={"30%"} colSpan={3} style={{ textAlign: "center" }}>{item.quantity}</td>
                                                </tr>
                                                {renderContentItem(item)}
                                            </>
                                        ))}
                                    </tbody>
                                </table>

                            </td>
                        </tr>

                    </table>
                </div>
            </div>
        </>
    );
};
export default OrderPrint;