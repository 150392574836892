// @flow
import types from './locationConstants';

const INIT_STATE = {
    countryList: [],
    cityList: [],
    phoneCodeList: []
};

const Location = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        case types.GET_COUNTRIES:
            return {
                ...state,
                countryList: action.payload
            };
        case types.GET_CITIES:
            return {
                ...state,
                cityList: action.payload
            };
        case types.GET_PHONE_CODES:
            return {
                ...state,
                phoneCodeList: action.payload
            };


        default:
            return state;
    }
};

export default Location;
