import clsx from 'clsx'
import { HeaderUserMenu } from '../../../partials'

import HeaderNotificationsMenu from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import HeaderCartMenu from '../../../partials/layout/header-menus/HeaderCartMenu'

import { useAuth } from '../../../../app/modules/auth'
import PolicyChecker from '../../../../_metronic/helpers/components/PolicyChecker';
import { enumPolicies } from '../../../../_metronic/helpers';


const Navbar = () => {
  const { currentUser } = useAuth()
  return (
    <div className='app-navbar flex-shrink-0'>

      <div className={clsx('app-navbar-item', 'ms-1 ms-md-4')}>

        <PolicyChecker policies={[enumPolicies.OrderCheckoutRead, enumPolicies.OrderCheckoutEdit, enumPolicies.OrderCheckoutWrite]}>
          <HeaderCartMenu />
        </PolicyChecker>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', 'ms-1 ms-md-4')}>
        <div
          className={clsx('cursor-pointer symbol', 'symbol-35px')}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={currentUser && currentUser.avatarLink} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

    </div>
  )
}

export { Navbar }
