import { useEffect, useState } from 'react';
import '../../assets/sass/components/scanner.css';
import { getAsync } from '../httpClient';
import { Modal } from 'react-bootstrap'
import Moment from 'react-moment'
import { useIntl } from 'react-intl'



const Audit = (props) => {

    const intl = useIntl()

    const [list, setList] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const onAuditClick = () => {

        getAsync(`/audits?table=${props.table}&identifier=${props.identifier}`).then((response) => {
            setList(response.data);
            setShowModal(true);
        })

    }

    const renderModal = () => {
        return (
            <Modal size="xl" centered show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.formatMessage({ id: 'AUDIT.TITLE' })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='table-responsive' style={{ overflowY: "auto", height: "500px" }}>
                        <table className='table table-bordered text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'AUDIT.FIELD.USER' })}</th>
                                    <th>{intl.formatMessage({ id: 'AUDIT.FIELD.OPERATION' })}</th>
                                    <th>{intl.formatMessage({ id: 'AUDIT.FIELD.DATE' })}</th>
                                    <th>{intl.formatMessage({ id: 'AUDIT.FIELD.DETAILS' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((item, i) => {
                                        return (
                                            <tr key={`audit_table_row_${item.id}`}>
                                                <td>{item.userName}</td>
                                                <td>{item.typeName}</td>
                                                <td><Moment>{item.date}</Moment></td>
                                                <td>
                                                    <table className='table table-striped table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>{intl.formatMessage({ id: 'AUDIT.FIELD.FIELD' })}</th>
                                                                <th>{intl.formatMessage({ id: 'AUDIT.FIELD.OLDVALUE' })}</th>
                                                                <th>{intl.formatMessage({ id: 'AUDIT.FIELD.NEWVALUE' })}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                item.details.map((detail, j) => {
                                                                    return (
                                                                        <tr key={`audit_detail_table_row_${item.id}`}>
                                                                            <td>{detail.fieldName}</td>
                                                                            <td>{detail.oldValue}</td>
                                                                            <td>{detail.newValue}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )

                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" onClick={() => setShowModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <>
            <div className="d-flex flex-end rounded-3" style={{ alignItems: "center", marginLeft: "5px" }}>
                <i title="Audit" className={`ki-duotone ki-information-4 ${props.iconSize ? props.iconSize : 'fs-3x'}`} onClick={() => onAuditClick()}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                </i>
            </div>

            {renderModal()}
        </>
    );
}

export { Audit };