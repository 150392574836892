// @flow
import types from './notificationConstants';
import { getAsync, patchAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getNotifications = () => async dispatch => {

    dispatch({
        type: types.GET_NOTIFICATIONS
    })

    const response = await getAsync(`/users/notifications`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_NOTIFICATIONS_SUCCESS,
            payload: response.data
        })
    } else {
        dispatch({
            type: types.GET_NOTIFICATIONS_FAILED,
            payload: response,
        })
    }
}

export const getUnreadNotifications = () => async dispatch => {

    dispatch({
        type: types.GET_NOTIFICATIONS_UNREAD
    })

    const response = await getAsync(`/users/notifications?isRead=false`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_NOTIFICATIONS_UNREAD_SUCCESS,
            payload: response.data
        })
    } else {
        dispatch({
            type: types.GET_NOTIFICATIONS_UNREAD_FAILED,
            payload: response,
        })
    }
}

export const patchNotification = (id) => async dispatch => {

    dispatch({
        type: types.PATCH_NOTIFICATION
    })

    const response = await patchAsync(`/users/notifications/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_NOTIFICATION_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_NOTIFICATION_FAILED,
            payload: response,
        })
    }
}
