// @flow
import types from '../dev-support/devSupportConstants';

const INIT_STATE = {
    devSupportTicketList: [],
    devSupportTicket: {},
    loading: false,
    success: null,
    error: null
};

const DevSupportTicket = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //List
        case types.GET_DEV_SUPPORT_TICKETS:
            return {
                ...state,
                loading: true
            };
        case types.GET_DEV_SUPPORT_TICKETS_SUCCESS:

            return {
                ...state,
                devSupportTicketList: action.payload.data.items,
                loading: false,
                error: null
            };
        case types.GET_DEV_SUPPORT_TICKETS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //Change State
        case types.PATCH_DEV_SUPPORT_TICKET_STATE:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_DEV_SUPPORT_TICKET_STATE_SUCCESS:

            var list = [...state.devSupportTicketList].map((item, index) => {
                if (item.id == action.payload.id) {
                    return {
                        ...item,
                        state: action.payload.state
                    }
                }
                return item;
            });

            return {
                ...state,
                devSupportTicketList: list,
                loading: false,
                success: 'DEV.SUPPORT.STATE.UPDATE.SUCESS',
                error: null
            };
        case types.PATCH_DEV_SUPPORT_TICKET_STATE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Change State

        //dev support ticket
        case types.GET_DEV_SUPPORT_TICKET:
            return {
                ...state,
                loading: true
            };
        case types.GET_DEV_SUPPORT_TICKET_SUCCESS:
            return {
                ...state,
                devSupportTicket: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_DEV_SUPPORT_TICKET_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case types.POST_DEV_SUPPORT_TICKET:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_DEV_SUPPORT_TICKET_SUCCESS:
            return {
                ...state,
                success: 'DEV.SUPPORT.TICKET.CREATED.SUCESS',
                loading: false,
                error: null
            };
        case types.POST_DEV_SUPPORT_TICKET_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        case types.PATCH_DEV_SUPPORT_TICKET:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_DEV_SUPPORT_TICKET_SUCCESS:
            return {
                ...state,
                success: 'DEV.SUPPORT.TICKET.UPDATED.SUCESS',
                loading: false,
                error: null
            };
        case types.PATCH_DEV_SUPPORT_TICKET_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //dev support ticket

        default:
            return state;
    }
};

export default DevSupportTicket;
