// @flow
import types from './productConstants';
import { deleteAsync, getAsync, patchAsync, postAsync, uploadAsync } from '../../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getProductList = (keyword = '') => async dispatch => {

    dispatch({
        type: types.GET_PRODUCTS
    })

    const response = await getAsync(`/products?keyword=${keyword}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_PRODUCTS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_PRODUCTS_FAILED,
            payload: response,
        })
    }
}

export const changeStatus = (id, isActive) => async dispatch => {

    dispatch({
        type: types.PATCH_PRODUCT_STATUS
    })

    var request = {
        isActive: isActive
    };

    const response = await patchAsync(`/products/${id}`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_PRODUCT_STATUS_SUCCESS,
            payload: { id, isActive }
        })
    } else {
        dispatch({
            type: types.PATCH_PRODUCT_STATUS_FAILED,
            payload: id,
        })
    }
}

export const getProduct = (id) => async dispatch => {
    dispatch({
        type: types.GET_PRODUCT
    })

    const response = await getAsync(`/products/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_PRODUCT_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_PRODUCT_FAILED,
            payload: response,
        })
    }
}

export const postProduct = (product) => async dispatch => {
    dispatch({
        type: types.POST_PRODUCT
    })

    const response = await postAsync(`/products`, product);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_PRODUCT_SUCCESS,
            payload: response.data,
        })
    } else {
        dispatch({
            type: types.POST_PRODUCT_FAILED,
            payload: response,
        })
    }
}

export const patchProduct = (id, product) => async dispatch => {
    dispatch({
        type: types.PATCH_PRODUCT
    })

    const response = await patchAsync(`/products/${id}`, product);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_PRODUCT_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_PRODUCT_FAILED,
            payload: response,
        })
    }
}

export const cloneProduct = (id) => async dispatch => {
    dispatch({
        type: types.POST_PRODUCT_CLONE
    })

    const response = await postAsync(`/products/${id}/clone`);

    if (response.isSuccess) {
        dispatch(getProductList())
        dispatch({
            type: types.POST_PRODUCT_CLONE_SUCCESS,
            payload: response.data,
        })
    } else {
        dispatch({
            type: types.POST_PRODUCT_CLONE_FAILED,
            payload: response,
        })
    }
}

//image
export const uploadImage = (productId, file) => async dispatch => {


    dispatch({
        type: types.POST_PRODUCT_IMAGE,
    })

    var request = {
        identifier: productId,
        fileName: file.name,
        fileExtension: file.extension,
        actionType: file.actionType
    };

    const response = await postAsync(`/signed-url/upload`, request);

    var data = {
        id: file.id,
        file: file.file
    }

    if (response.isSuccess) {
        await uploadAsync(response.data.url, data, async () => {
            var uploadResponse = await postAsync(`/products/${productId}/images`, {
                path: response.data.path,
                sequence: 1
            })

            if (uploadResponse.isSuccess) {

                dispatch({
                    type: types.POST_PRODUCT_IMAGE_SUCCESS,
                    payload: file.id
                })

                dispatch(getImages(productId))
            }
        });
    } else {
        dispatch({
            type: types.POST_PRODUCT_IMAGE_FAILED,
        })
    }
}


export const getImages = (productId) => async dispatch => {

    dispatch({
        type: types.GET_PRODUCT_IMAGES,
    })


    const response = await getAsync(`/products/${productId}/images`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_PRODUCT_IMAGES_SUCCESS,
            payload: response.data,
        })
    } else {
        dispatch({
            type: types.GET_PRODUCT_IMAGES_FAILED,
            payload: response,
        })
    }
}

export const deleteImage = (productId, imageId) => async dispatch => {

    dispatch({
        type: types.DELETE_PRODUCT_IMAGE,
    })

    const response = await deleteAsync(`/products/${productId}/images/${imageId}`);

    if (response.isSuccess) {
        dispatch({
            type: types.DELETE_PRODUCT_IMAGE_SUCCESS,
            payload: { id: imageId },
        })
    } else {
        dispatch({
            type: types.DELETE_PRODUCT_IMAGE_FAILED,
            payload: response,
        })
    }
}

export const patchImage = (productId, imageId, image) => async dispatch => {

    dispatch({
        type: types.PATCH_PRODUCT_IMAGE,
    })

    const response = await patchAsync(`/products/${productId}/images/${imageId}`, image);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_PRODUCT_IMAGE_SUCCESS
        })
    } else {
        dispatch({
            type: types.PATCH_PRODUCT_IMAGE_FAILED
        })
    }
}
//image


//variant
export const getVariants = (productId) => async dispatch => {
    dispatch({
        type: types.GET_VARIANTS
    })

    const response = await getAsync(`/products/${productId}/variants`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_VARIANTS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_VARIANTS_FAILED,
            payload: response,
        })
    }
}

export const postVariant = (productId, variant) => async dispatch => {
    dispatch({
        type: types.POST_VARIANT
    })

    const response = await postAsync(`/products/${productId}/variants`, variant);


    if (response.isSuccess) {
        dispatch({
            type: types.POST_VARIANT_SUCCESS
        })

        dispatch(getVariants(productId))
    } else {
        dispatch({
            type: types.POST_VARIANT_FAILED,
            payload: response,
        })
    }
}

export const patchVariant = (productId, variantId, variant) => async dispatch => {

    dispatch({
        type: types.PATCH_VARIANT
    })

    const response = await patchAsync(`/products/${productId}/variants/${variantId}`, variant);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_VARIANT_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_VARIANT_FAILED,
            payload: response,
        })
    }
}

//variant


export const getOptionValues = (id) => async dispatch => {

    const response = await getAsync(`/options/${id}/values`);
    dispatch({
        type: types.GET_OPTION_VALUES,
        payload: response,
    })
}

export const getCategories = () => async dispatch => {

    const response = await getAsync(`/categories`);
    dispatch({
        type: types.GET_CATEGORIES,
        payload: response,
    })
}

export const getSuppliers = () => async dispatch => {

    const response = await getAsync(`/suppliers`);
    dispatch({
        type: types.GET_SUPPLIERS,
        payload: response,
    })
}


export const getModels = () => async dispatch => {

    const response = await getAsync(`/products/models`);
    dispatch({
        type: types.GET_MODELS,
        payload: response,
    })
}

export const getLookupValues = (type) => async dispatch => {

    const response = await getAsync(`/lookup/${type}/values`);
    dispatch({
        type: types.GET_LOOKUP_VALUES,
        payload: response,
    })
}