// @flow

import { combineReducers } from 'redux';
import User from '../pages/user-management/userReducers';
import Profile from '../pages/profile/profileReducers';
import Product from '../pages/catalog-management/product/productReducers';
import Supplier from '../pages/catalog-management/supplier/supplierReducers';
import Notification from '../pages/notifications/notificationReducers';
import Location from '../pages/locations/locationReducers';
import Stock from '../pages/stock-management/stock/stockReducers';
import DevSupport from '../pages/dev-support/devSupportReducers';
import Price from '../pages/price-management/priceReducers';
import Checkout from '../pages/checkout/checkoutReducers';
import Order from '../pages/order/orderReducers';
import Localization from '../pages/localization/localizationReducers';
import Lead from '../pages/lead-management/leadReducers';

export default combineReducers({
    User,
    Profile,
    Product,
    Supplier,
    Notification,
    Location,
    Stock,
    DevSupport,
    Price,
    Checkout,
    Order,
    Localization,
    Lead
});
