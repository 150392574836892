export enum enumUnitPriceTypes {
    GlazenSchuifwandHelder = 1,
    GlazenSchuifwandGetint = 2,
    GlazenSchuifwandAccessoiresHelderGlasMeerprijs = 3,
    GlazenSchuifwandAccessoiresGetintGlasMeerprijs = 4,
    GlazenSchuifwandAccessoiresSetprijzen = 5,
    GlazenSchuifwandAccessoiresLosseOnderdelen = 6,
    GlazenSchuifwandAccessoiresLosseOnderdelenMaatwerk = 7,
    AccessoiresSpiePolycarbonaat = 8,
    AccessoiresGeheelPolycarbonaat = 9,
    AccessoiresAluminiumRabat = 10,
    VerandaAccesoiresLosseOnderdelen = 11,
    VerandaAccesoiresLedVerlichtingNormaal = 12,
    VerandaAccesoiresLedVerlichtingDimbaar = 13,
    VerandaAccesoiresSmartVerlichtingGoogle = 14,
    VerandaAccesoiresSmartVerlichtingSomfy = 15,
    VerandaAccesoiresGlasprijzen = 16,
    VerandaAccesoiresOverigeOnderdelen = 17,
    AccesoiresVastKozijn = 18,
    AccesoiresSpieGlas = 19,
    AccesoiresAluminiumSchuifpui = 20,
    AccesoiresGuillotineGlassysteem = 21
};