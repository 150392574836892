import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, KTIcon, Loader } from '../../../_metronic/helpers'
import Moment from 'react-moment';

import { clearState, getUserList, changeStatus } from './userActions';

const Dealers = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        userList,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getUserList,
        changeStatus
    } = props;


    const [keyword, setKeyword] = useState();
    const [type, setType] = useState(3);
    const [isActive, setIsActive] = useState('');
    const [isApprove, setIsApprove] = useState('');

    useEffect(() => {

        getUserList(keyword, type);

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        MenuComponent.reinitialization();
    }, [userList])

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])


    useEffect(() => {
        if (keyword != undefined) {
            const timeOutId = setTimeout(() => getUserList(keyword, type), 400);
            return () => clearTimeout(timeOutId);
        }
    }, [keyword]);

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'USER.MANAGEMENT.TITLE' }),
            path: '/user-management/users',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onSearch = (value) => {
        setKeyword(value);
    }

    const onStatusChange = (id, isActive) => {
        changeStatus(id, isActive);
    }

    const resetData = () => {
        setType('')
        setIsActive('')
        setIsApprove('')
        getUserList(keyword, type);
    }

    const filterData = () => {
        getUserList(keyword, type, isActive, isApprove);
    }

    return (
        <>

            <PageTitle breadcrumbs={usersBreadcrumbs} >{intl.formatMessage({ id: 'USER.LIST.BREADCRUMB' })}</PageTitle>

            <KTCard>

                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder={intl.formatMessage({ id: 'USER.LIST.SEARCH.USER' })} value={keyword} onChange={(e) => onSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className="card-toolbar">

                        <button
                            type='button'
                            className='btn btn-light-primary me-3'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' />
                            {intl.formatMessage({ id: 'USER.LIST.SEARCH.FILTER' })}
                        </button>
                        {/* end::Filter Button */}
                        {/* begin::SubMenu */}
                        <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                            {/* begin::Header */}
                            <div className='px-7 py-5'>
                                <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({ id: 'USER.LIST.SEARCH.FILTER' })}</div>
                            </div>
                            {/* end::Header */}

                            {/* begin::Separator */}
                            <div className='separator border-gray-200'></div>
                            {/* end::Separator */}

                            {/* begin::Content */}
                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                
                                {/* begin::Input group */}
                                <div className='mb-10'>
                                    <label className='form-label fs-6 fw-bold'>{intl.formatMessage({ id: 'USER.STATUS' })}:</label>
                                    <select
                                        className='form-select form-select-solid fw-bolder'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                        data-kt-user-table-filter='role'
                                        data-hide-search='true'
                                        onChange={(e) => setIsActive(e.target.value)}
                                        value={isActive}
                                    >
                                        <option value=''>{intl.formatMessage({ id: 'USER.TYPES.ALL' })}</option>
                                        <option value='true'>{intl.formatMessage({ id: 'USER.ACTIVE.ENABLED' })}</option>
                                        <option value='false'>{intl.formatMessage({ id: 'USER.ACTIVE.LOCKED' })}</option>
                                    </select>
                                </div>
                                {/* end::Input group */}

                                {/* begin::Input group */}
                                <div className='mb-10'>
                                    <label className='form-label fs-6 fw-bold'>{intl.formatMessage({ id: 'USER.APPROVE' })}:</label>
                                    <select
                                        className='form-select form-select-solid fw-bolder'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                        data-kt-user-table-filter='role'
                                        data-hide-search='true'
                                        onChange={(e) => setIsApprove(e.target.value)}
                                        value={isApprove}
                                    >
                                        <option value=''>{intl.formatMessage({ id: 'USER.TYPES.ALL' })}</option>
                                        <option value='true'>{intl.formatMessage({ id: 'USER.APPROVE.YES' })}</option>
                                        <option value='false'>{intl.formatMessage({ id: 'USER.APPROVE.NO' })}</option>
                                    </select>
                                </div>
                                {/* end::Input group */}

                                {/* begin::Actions */}
                                <div className='d-flex justify-content-end'>
                                    <button
                                        type='button'
                                        onClick={resetData}
                                        className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                                        data-kt-menu-dismiss='true'
                                        data-kt-user-table-filter='reset'
                                    >
                                        {intl.formatMessage({ id: 'GENERAL.RESET' })}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={filterData}
                                        className='btn btn-primary fw-bold px-6'
                                        data-kt-menu-dismiss='true'
                                        data-kt-user-table-filter='filter'
                                    >
                                        {intl.formatMessage({ id: 'GENERAL.APPLY' })}
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </div>
                            {/* end::Content */}
                        </div>

                        <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                            <Link to={`/user-management/users/0`} type="button" className="btn btn-success"><i className="ki-duotone ki-plus fs-2"></i>{intl.formatMessage({ id: 'USER.LIST.ADD.USER' })}</Link>
                        </div>
                    </div>
                </div>
                <KTCardBody className='table-responsive m-grid-responsive-md'>

                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>#</th>
                                <th>{intl.formatMessage({ id: 'USER.NAME' })}</th>
                                <th>{intl.formatMessage({ id: 'USER.ROLE' })}</th>
                                <th className='d-none d-md-table-cell'>{intl.formatMessage({ id: 'USER.LAST.LOGIN' })}</th>
                                <th className='d-none d-md-table-cell'>{intl.formatMessage({ id: 'USER.CREATED.ON' })}</th>
                                <th>{intl.formatMessage({ id: 'USER.ISACTIVE' })}</th>
                                <th>{intl.formatMessage({ id: 'USER.ISAPPROVE' })}</th>
                                <th>{intl.formatMessage({ id: 'USER.ACTION' })}</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {
                                userList && userList.map((user, index) => {
                                    return (
                                        (
                                            <tr key={index}>
                                                <td>
                                                    <div className='form-check form-check-custom form-check-solid'>
                                                        {++index}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                            <div className="symbol-label">
                                                                <img src={user.avatarLink} alt={user.name} className="w-100" />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <a className="text-gray-800 text-hover-primary mb-1">{user.contact && user.contact.company}</a>
                                                            <span style={{ wordBreak: 'break-all', minWidth: '185px' }}>{user.name} {user.surname}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{user.typeName}</td>
                                                <td className='d-none d-md-table-cell'><Moment>{user.lastLogin}</Moment></td>
                                                <td className='d-none d-md-table-cell'> <Moment>{user.createdOn}</Moment></td>
                                                <td>{user.isActive ? <div className="badge badge-light-success fw-bolder">{intl.formatMessage({ id: 'USER.ACTIVE.ENABLED' })}</div> : <div className="badge badge-light-danger">{intl.formatMessage({ id: 'USER.ACTIVE.LOCKED' })}</div>}</td>
                                                <td>{user.isApprove ? <div className="badge badge-light-success fw-bolder">{intl.formatMessage({ id: 'USER.APPROVE.YES' })}</div> : <div className="badge badge-light-danger">{intl.formatMessage({ id: 'USER.APPROVE.NO' })}</div>}</td>
                                                <td>
                                                    <a className='btn btn-light btn-active-light-primary btn-sm ' data-kt-menu-trigger="{default: 'click'}" data-kt-menu-placement='bottom-end'> {intl.formatMessage({ id: 'USER.ACTIONS' })}
                                                        <KTIcon iconName='down' className='fs-5 m-0' />
                                                    </a>
                                                    <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
                                                        <div className='menu-item px-3'>
                                                            <Link to={`/user-management/users/${user.id}`} className='menu-link px-3'> {intl.formatMessage({ id: 'USER.ACTIONS.EDIT' })} </Link>
                                                        </div>
                                                        <div className='menu-item px-3'>
                                                            {
                                                                user.isActive ?
                                                                    <a className='menu-link px-3' onClick={() => onStatusChange(user.id, false)}> {intl.formatMessage({ id: 'USER.ACTIONS.DEACTIVE' })} </a> :
                                                                    <a className='menu-link px-3' onClick={() => onStatusChange(user.id, true)}> {intl.formatMessage({ id: 'USER.ACTIONS.ACTIVE' })} </a>
                                                            }

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </KTCardBody>
            </KTCard >
            {loading && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        userList: state.User.userList,
        loading: state.User.loading,
        success: state.User.success
    };
};

const mapActionsToProps = {
    clearState, getUserList, changeStatus
}

export default connect(mapStateToProps, mapActionsToProps)(Dealers)