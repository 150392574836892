import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { ChartsWidget4 } from '../../../../_metronic/partials/widgets';
import { Loader, KTIcon } from '../../../../_metronic/helpers'
import { Button, Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import Barcode from 'react-barcode'
import Moment from 'react-moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Modal } from 'react-bootstrap'
import Resizer from "react-image-file-resizer";

import { clearState, getVariantStockList, getChartData, uploadImage, postCasualty } from './stockActions';

const Stock = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    const [inLoading, setInloading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCasualtyModal, setShowCasualtyModal] = useState(false);
    const [stockModel, setStockModal] = useState();

    //inputs from redux
    const {
        variant,
        chartData,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getVariantStockList,
        getChartData,
        uploadImage,
        postCasualty
    } = props;

    useEffect(() => {

        if (params.id > 0) {
            getVariantStockList(params.id);
            getChartData(params.id);
        }

        return () => {
            clearState()
        }
    }, []);

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'STOCK.MANAGEMENT.TITLE' }),
            path: '/stock-management/stocks',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    const casualityValues = { description: '', file: null, variantId: params.id, quantity: '' };
    const casualitySchema = Yup.object().shape({
        quantity: Yup.number()
            .min(1)
            .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        file: Yup.mixed()
            .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        description: Yup.string()
            .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
    })

    const formik = useFormik({
        initialValues: casualityValues,
        validationSchema: casualitySchema,
        onSubmit: async (values, { resetForm }) => {

            setInloading(true);

            var resizedFile = await resizeFile(values.file);
            resizedFile.name = values.file.name;

            var file = {
                id: values.variantId,
                file: resizedFile,
                name: resizedFile.name,
                actionType: 3,
                extension: resizedFile.type.split('/')[1],
            }

            var response = await uploadImage(values.variantId, file);
            if (response.isSuccess) {
                var request = {
                    variantId: values.variantId,
                    quantity: -values.quantity,
                    casualty: {
                        description: values.description,
                        variantId: values.variantId,
                        imagePath: response.data.path
                    }
                }
                response = await postCasualty(request);
                if (response.isSuccess) {
                    resetForm();

                    setShowModal(false);
                    setInloading(false);

                    getVariantStockList(params.id);
                    getChartData(params.id);
                }

            }


        },
    })


    const listColorClass = (color) => {
        if (color == 1)
            return "bg-opacity-10 bg-primary";

        if (color == -1)
            return "bg-opacity-10 bg-danger";

        return "bg-opacity-10 bg-success";
    }

    const onCasualtyModalClick = (item) => {
        setStockModal(item);
        setShowCasualtyModal(true);
    }

    const renderAddCasualtyModal = () => {
        return (
            <Modal centered show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.formatMessage({ id: 'STOCK.FIELD.ADDCASUALTY' })}</Modal.Title>
                </Modal.Header>
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
                    <Modal.Body>
                        <div className='fv-row mb-3'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'STOCK.FIELD.QUANTITY' })}</label>
                            <input
                                type='number'
                                min={1}
                                autoComplete='off'
                                {...formik.getFieldProps('quantity')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.quantity && formik.errors.quantity,
                                    },
                                    {
                                        'is-valid': formik.touched.quantity && !formik.errors.quantity,
                                    }
                                )}
                            />
                            {formik.touched.quantity && formik.errors.quantity && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.quantity}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-3'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'STOCK.FIELD.DESCRIPTION' })}</label>
                            <input
                                type='textarea'
                                autoComplete='off'
                                {...formik.getFieldProps('description')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.description && formik.errors.description,
                                    },
                                    {
                                        'is-valid': formik.touched.description && !formik.errors.description,
                                    }
                                )}
                            />
                            {formik.touched.description && formik.errors.description && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.description}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-3'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'STOCK.FIELD.FILE' })}</label>
                            <input
                                type='file'
                                accept="image/*"
                                onChange={(event) => formik.setFieldValue("file", event.currentTarget.files[0])}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.file && formik.errors.file,
                                    },
                                    {
                                        'is-valid': formik.touched.file && !formik.errors.file,
                                    }
                                )}
                            />
                            {formik.touched.file && formik.errors.file && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.file}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-light" onClick={() => setShowModal(false)}>Close</button>
                        <button type="submit" className="btn btn-primary" >{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    const renderStockModal = () => {
        if (stockModel && stockModel.casualty) {
            return (
                <Modal centered show={showCasualtyModal} onHide={() => setShowCasualtyModal(!showCasualtyModal)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Casualty</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'STOCK.FIELD.QUANTITY' })}</label>
                            <div className='col-lg-8'>
                                <span className='fw-bolder fs-6 text-dark'>{stockModel.quantity}</span>
                            </div>
                        </div>
                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'STOCK.FIELD.DESCRIPTION' })}</label>
                            <div className='col-lg-8'>
                                <span className='fw-bolder fs-6 text-dark'>{stockModel.casualty && stockModel.casualty.description}</span>
                            </div>
                        </div>
                        <div className='row mb-7'>
                            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'STOCK.FIELD.FILE' })}</label>
                            <div className='col-lg-8'>
                                <div className='symbol symbol-200px symbol-fixed position-relative'>
                                    <a className='symbol symbol-200px symbol-fixed position-relative' href={stockModel.casualty && stockModel.casualty.imagePath} target='_blank' download={true}>
                                        <img src={stockModel.casualty && stockModel.casualty.imagePath} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-light" onClick={() => setShowCasualtyModal(false)}>{intl.formatMessage({ id: 'GENERAL.CLOSE' })}</button>
                    </Modal.Footer>
                </Modal>
            )
        }
        return (
            <></>
        )
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'STOCK.ITEM.BREADCRUMB' })}</PageTitle>

            <Row>
                <Col>
                    <div className="card card-flush py-4">
                        <div className='card-header'>
                            <div className="card-title">
                                <h2>{intl.formatMessage({ id: 'STOCK.FIELD.VARIANT' })} - {variant.code}</h2>
                            </div>
                            <div className="card-toolbar">
                                <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                    {variant && variant.code ? <Barcode value={variant.code} /> : <span></span>}
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <Row>
                                <Col>
                                    <div className="d-flex justify-content-end mb-5" data-kt-user-table-toolbar="base">
                                        <button type="button" className="btn btn-light-primary m-1" onClick={() => setShowModal(!showModal)}>
                                            <KTIcon iconName='plus' className='fs-2x'></KTIcon> {intl.formatMessage({ id: 'STOCK.FIELD.ADDCASUALTY' })}
                                        </button>

                                        <Link to={`/stock-management/stocks-print/${variant && variant.id}`} className="btn btn-light-info m-1"> <KTIcon iconName='printer' className='fs-2x'></KTIcon> {intl.formatMessage({ id: 'GENERAL.PRINT' })}</Link>
                                        <Link to={`/stock-management/stocks-operation/${variant && variant.id}`} className="btn btn-light-info m-1"> <KTIcon iconName='scan-barcode' className='fs-2x'></KTIcon> {intl.formatMessage({ id: 'STOCK.FIELD.ADDDROPSTOCK' })}</Link>
                                    </div>
                                </Col>
                            </Row>

                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">{intl.formatMessage({ id: 'STOCK.FIELD.PRODUCT' })}</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{variant && variant.productName} - {variant && variant.productCode}</span>
                                </div>
                            </div>

                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">{intl.formatMessage({ id: 'STOCK.FIELD.VARIANT' })}</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{variant && variant.name} - {variant && variant.code} </span>
                                </div>
                            </div>

                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">{intl.formatMessage({ id: 'STOCK.FIELD.CATEGORY' })}</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-gray-800">{variant && variant.categoryName}</span>
                                </div>
                            </div>

                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">{intl.formatMessage({ id: 'STOCK.FIELD.STOCK' })}</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-white-800 badge badge-info">{variant && variant.stockQuantity}</span>
                                </div>
                            </div>

                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">{intl.formatMessage({ id: 'STOCK.FIELD.AVAILABLESTOCK' })}</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-white-800 badge badge-success">{variant && variant.availableStockQuantity}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">{intl.formatMessage({ id: 'STOCK.FIELD.REDTHRESHOLD' })}</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-white-800 badge badge-danger">{variant && variant.redThreshold}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-md-4 fw-semibold text-muted">{intl.formatMessage({ id: 'STOCK.FIELD.YELLOWTHRESHOLD' })}</label>
                                <div className="col-md-8">
                                    <span className="fw-bold fs-6 text-white-800 badge badge-warning">{variant && variant.yellowThreshold}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                </Col>
            </Row>
            <Card>
                <CardBody>
                    {
                        chartData && <ChartsWidget4 className='card-xl-stretch mb-5 mb-xl-8' chartData={chartData} />
                    }
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle>
                        <h2>{intl.formatMessage({ id: 'STOCK.FIELD.TRANSACTIONS' })}</h2>
                    </CardTitle>
                </CardHeader>
                <CardBody >
                    <Row>
                        <Col className='table-responsive m-grid-responsive-md'>
                            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-bordered text-center'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{intl.formatMessage({ id: 'STOCK.FIELD.QUANTITY' })}</th>
                                        <th>{intl.formatMessage({ id: 'STOCK.FIELD.PRICE' })}</th>
                                        <th>{intl.formatMessage({ id: 'STOCK.FIELD.CASUALTY' })}</th>
                                        <th>{intl.formatMessage({ id: 'STOCK.FIELD.TRANSACTIONTYPE' })}</th>
                                        <th>{intl.formatMessage({ id: 'STOCK.FIELD.FROM' })}</th>
                                        <th>{intl.formatMessage({ id: 'STOCK.FIELD.DATE' })}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        variant && variant.stockList && variant.stockList.map((item, index) => {
                                            return (
                                                <tr key={`stock_${index}`} className={listColorClass(item.transactionType)}>
                                                    <td>{++index}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.price}</td>
                                                    <td>{item.casualty && item.casualty.id > 0 ? <Button className='btn btn-icon btn-primary' onClick={() => onCasualtyModalClick(item)}><KTIcon iconName='information-3' className='fs-2x' /></Button> : <></>}</td>
                                                    <td>{item.transactionType == 1 ? `in` : `out`}</td>
                                                    <td>{item.identifierTypeName} - {item.identifierName} 
                                                        {item.orderId && <a href={`/order-management/orders/${item.orderId}`} className="text-gray-600 text-hover-primary">
                                                            - #{item.orderId}
                                                        </a>}
                                                    </td>
                                                    <td><Moment>{item.createdOn}</Moment></td>
                                                </tr>
                                            )
                                        })
                                    }


                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </CardBody>
            </Card>

            {renderAddCasualtyModal()}
            {renderStockModal()}

            {(loading || inLoading) && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        variant: state.Stock.variant,
        chartData: state.Stock.chartData,
        loading: state.Stock.loading,
        success: state.Stock.success
    };
};

const mapActionsToProps = {
    clearState, getVariantStockList, getChartData, uploadImage, postCasualty
}

export default connect(mapStateToProps, mapActionsToProps)(Stock)