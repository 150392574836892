// @flow
import types from './profileConstants';
import { getAsync, patchAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getProfile = (id) => async dispatch => {

    dispatch({
        type: types.GET_PROFILE
    })

    const response = await getAsync(`/users/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_PROFILE_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_PROFILE_FAILED,
            payload: response,
        })
    }
}

export const passwordChange = (id, model) => async dispatch => {

    dispatch({
        type: types.PATCH_PROFILE_PASSWORD
    })

    const response = await patchAsync(`/users/${id}/password`, model);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_PROFILE_PASSWORD_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_PROFILE_PASSWORD_FAILED,
            payload: response,
        })
    }
}
