import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../../app/modules/auth'
import { KTCard, KTCardBody, Loader, toAbsoluteUrl, KTIcon, enumUnitPriceTypes } from '../../../_metronic/helpers'
import { Button, Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import { Audit } from '../../../_metronic/helpers/components/Audit';
import { Content } from '../../../_metronic/helpers/components/Content';

import { clearState, getUnitPrices, getProductTypes, getColors, getUnitTypes, patchUnitPrices } from './priceActions';

const Unit = (props) => {
    //inputs from url
    const intl = useIntl();

    //inputs from redux
    const {
        unitPrices,
        productTypes,
        productColors,
        unitTypes,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        getUnitPrices,
        getProductTypes,
        getColors,
        getUnitTypes,
        patchUnitPrices
    } = props;

    const [uniqueUnitTypeList, setUniqueUnitTypeList] = useState([]);
    const [priceList, setPriceList] = useState([]);

    useEffect(() => {

        getUnitPrices();
        getProductTypes();
        getColors();
        getUnitTypes();

        return () => {
            clearState();
        }
    }, []);

    useEffect(() => {
        if (unitPrices) {
            setUniqueUnitTypeList([...new Set(unitPrices.map(s => s.type))]);
            setPriceList(unitPrices.map(s => { return { ...s } }))
        }

    }, [unitPrices])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'PRICE.BREADCRUMB.TITLE' }),
            path: '/price-management/unit',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const onCellChange = (item, e) => {
        if (!isNaN(e.target.value)) {
            var value = Number(e.target.value);
            setPriceList(() => {
                var index = priceList.findIndex(s => s.id == item.id);
                var list = [...priceList];
                list[index].price = value;
                return list;
            })
        }
    }

    const onCellSave = (item) => {
        var record = unitPrices.find(s => s.id == item.id);
        if (item.price != record.price) {
            patchUnitPrices(item)
        }
    }

    const renderLeds = (unitType, items) => {
        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.QUANTITY' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.COLOR' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.WATT' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.DIMBAAR' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.PRICE' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {

                                    var color = productColors.find(s => s.id == item.color);

                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{item.quantity}</td>
                                            <td>{color && color.name}</td>
                                            <td>{item.power}</td>
                                            <td>{item.isAdjustable ? "Yes" : "No"}</td>

                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const renderSmart = (unitType, items) => {
        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.NAME' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.QUANTITY' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.COLOR' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.WATT' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.DIMBAAR' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.PRICE' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {

                                    var color = productColors.find(s => s.id == item.color);

                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{item.name} <Content table="UnitPrice" identifier={item && item.id} field={"Name"} /></td>
                                            <td>{item.quantity}</td>
                                            <td>{color && color.name}</td>
                                            <td>{item.power}</td>
                                            <td>{item.isAdjustable ? "Yes" : "No"}</td>

                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const renderGlazenSchuifwand = (unitType, items) => {
        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.NAME' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.PRICE' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{item.name} <Content table="UnitPrice" identifier={item && item.id} field={"Name"} /></td>
                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const renderGlazenSchuifwandAccessoriesAdditionalCost = (unitType, items) => {
        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.WIDTH' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.HEIGHTPER50MM' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.ADDITIONALCOST' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{item.width}</td>
                                            <td>{item.heightRange}</td>
                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const renderGlazenSchuifwandAccessoriesSetPrice = (unitType, items) => {
        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.SETS' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.PRICE' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{item.name} <Content table="UnitPrice" identifier={item && item.id} field={"Name"} /></td>
                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const renderAccessoriesTable = (unitType, items) => {
        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.DEPTH' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.UNITPRICE' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{item.depth}</td>
                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const renderAccessoriesTableWithMaxHeight = (unitType, items, firstCol) => {
        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{firstCol}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.MAXHEIGHT' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.442GLASS' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{firstCol == 'Width' ? item.width : item.depth}</td>
                                            <td>{item.maxHeight}</td>
                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const renderGuillotine = (unitType, items) => {
        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.AFMETING' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.PRICE' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{item.unit}</td>
                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    const renderUnitPrices = (type) => {

        var items = priceList.filter(s => s.type == type);
        var unitType = unitTypes.find(s => s.value == type);
        var typeName = unitType && unitType.name;

        if (type == enumUnitPriceTypes.GlazenSchuifwandHelder || type == enumUnitPriceTypes.GlazenSchuifwandGetint) {
            return (renderGlazenSchuifwand(unitType, items))
        }

        if (type == enumUnitPriceTypes.GlazenSchuifwandAccessoiresHelderGlasMeerprijs || type == enumUnitPriceTypes.GlazenSchuifwandAccessoiresGetintGlasMeerprijs) {
            return (renderGlazenSchuifwandAccessoriesAdditionalCost(unitType, items))
        }

        if (type == enumUnitPriceTypes.GlazenSchuifwandAccessoiresSetprijzen) {
            return (renderGlazenSchuifwandAccessoriesSetPrice(unitType, items))
        }

        if (type == enumUnitPriceTypes.AccessoiresSpiePolycarbonaat ||
            type == enumUnitPriceTypes.AccessoiresGeheelPolycarbonaat ||
            type == enumUnitPriceTypes.AccessoiresAluminiumRabat ||
            type == enumUnitPriceTypes.AccesoiresSpieGlas) {
            return (renderAccessoriesTable(unitType, items))
        }

        if (type == enumUnitPriceTypes.AccesoiresVastKozijn) {
            return (renderAccessoriesTableWithMaxHeight(unitType, items, 'Depth'))
        }

        if (type == enumUnitPriceTypes.AccesoiresAluminiumSchuifpui) {
            return (renderAccessoriesTableWithMaxHeight(unitType, items, 'Width'))
        }

        if (type == enumUnitPriceTypes.VerandaAccesoiresLedVerlichtingNormaal) {
            return (renderLeds(unitType, items))
        }

        if (type == enumUnitPriceTypes.VerandaAccesoiresLedVerlichtingDimbaar ||
            type == enumUnitPriceTypes.VerandaAccesoiresSmartVerlichtingGoogle ||
            type == enumUnitPriceTypes.VerandaAccesoiresSmartVerlichtingSomfy) {
            return (renderSmart(unitType, items))
        }

        if (type == enumUnitPriceTypes.AccesoiresGuillotineGlassysteem) {
            return (renderGuillotine(unitType, items))
        }

        return (
            <Card >
                <CardHeader>
                    <CardTitle>{unitType && unitType.name} <Content table="LookupTypeValue" identifier={unitType && unitType.id} field={"Name"} /></CardTitle>
                </CardHeader>
                <CardBody>

                    <div className='table-responsive'>
                        <table className='table table-bordered table-striped align-middle text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.NAME' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.UNIT' })}</th>
                                    <th>{intl.formatMessage({ id: 'PRICE.FIELD.PRICE' })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <tr key={`item_${index}`}>
                                            <td>{item.name} <Content table="UnitPrice" identifier={item && item.id} field={"Name"} /></td>
                                            <td>{item.unit}</td>

                                            <td key={`price_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                    <input className="form-control" name="price" type="text" value={item.price} autoComplete='off' onChange={(e) => onCellChange(item, e)} />
                                                    <button className="input-group-text" onClick={() => onCellSave(item)}><i className="bi bi-save"></i></button>
                                                    <Audit table="UnitPrice" identifier={item.id} iconSize=" fs-1 " />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        )
    }

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'UNIT.BREADCRUMB.DESCRIPTION' })}</PageTitle>

            {
                uniqueUnitTypeList && uniqueUnitTypeList.map((type, index) => {

                    return (
                        <div key={`unit_card_${type}`}>
                            {renderUnitPrices(type)}
                        </div>
                    )
                })
            }

            {loading && <Loader />}
        </>
    );

};

const mapStateToProps = state => {
    return {
        unitPrices: state.Price.unitPrices,
        productTypes: state.Price.productTypes,
        productColors: state.Price.productColors,
        unitTypes: state.Price.unitTypes,
        success: state.Price.success,
        loading: state.Price.loading
    };
};

const mapActionsToProps = {
    clearState, getUnitPrices, getProductTypes, getColors, getUnitTypes, patchUnitPrices
}

export default connect(mapStateToProps, mapActionsToProps)(Unit)