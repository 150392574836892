import { useEffect } from 'react';
import Quagga from '@ericblade/quagga2';
import '../../assets/sass/components/scanner.css';


const Scanner = (props) => {

    var last_result = [];

    useEffect(() => {
        last_result = [];
        if (props.scanning) {
            start();
        } else {
            stop();
        }
    }, [props.scanning])

    const start = () => {
        console.log('started')
        Quagga.init(
            {
                inputStream: {
                    type: 'LiveStream',
                    target: document.querySelector('#barcode-scanner'),
                    constraints: {
                        facingMode: 'environment', // or user
                    },
                },
                numOfWorkers: 1,
                decoder: {
                    readers: [
                        "code_128_reader",
                        "code_39_reader",
                        "code_39_vin_reader"
                    ]
                }
            },
            function (err) {
                if (err) {
                    return console.log(err);
                }
                Quagga.start();
            }
        );

        Quagga.onDetected(function (result) {

            var last_code = result.codeResult.code;
            last_result.push(last_code);
            if (last_result.length > 20) {
                console.log(last_result);
                //when we reached the last scanned object take the most repeated is the correct one
                var code = mode(last_result);
                console.log(code + " Is the most valid one");
                props.onDetected(code);
                last_result = [];
            }
        });
    }

    const stop = () => {
        console.log('closed')
        Quagga.stop();
    }

    function mode(array) {
        if (array.length == 0)
            return null;
        var modeMap = {};
        var maxEl = array[0], maxCount = 1;
        for (var i = 0; i < array.length; i++) {
            var el = array[i];
            if (modeMap[el] == null)
                modeMap[el] = 1;
            else
                modeMap[el]++;
            if (modeMap[el] > maxCount) {
                maxEl = el;
                maxCount = modeMap[el];
            }
        }
        return maxEl;
    }

    return (
        <>
            {props.scanning ? <div id="barcode-scanner" className="viewport" /> : null}
        </>
    );
}

export { Scanner };