import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, KTIcon, Loader, enumPolicies } from '../../../../_metronic/helpers'
import PolicyChecker from '../../../../_metronic/helpers/components/PolicyChecker';

import { clearState, getSupplierList, changeStatus } from './supplierActions';

const Suppliers = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        supplierList,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getSupplierList,
        changeStatus
    } = props;


    const [keyword, setKeyword] = useState();

    useEffect(() => {

        getSupplierList();

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        MenuComponent.reinitialization();
    }, [supplierList])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])


    useEffect(() => {
        if (keyword != undefined) {
            const timeOutId = setTimeout(() => getSupplierList(keyword), 400);
            return () => clearTimeout(timeOutId);
        }
    }, [keyword]);

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CATALOG.MANAGEMENT.TITLE' }),
            path: '/catalog-management/suppliers',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onSearch = (value) => {
        setKeyword(value);
    }

    const onStatusChange = (id, isActive) => {
        changeStatus(id, isActive);
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'SUPPLIER.LIST.BREADCRUMB' })}</PageTitle>

            <KTCard>

                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder={intl.formatMessage({ id: 'SUPPLIER.LIST.SEARCH.SUPPLIER' })} value={keyword} onChange={(e) => onSearch(e.target.value)} />
                        </div>
                    </div>

                    <PolicyChecker policies={[enumPolicies.ProductWrite]}>
                        <div className="card-toolbar">
                            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                <Link to={`/catalog-management/suppliers/0`} type="button" className="btn btn-success"><i className="ki-duotone ki-plus fs-2"></i>{intl.formatMessage({ id: 'SUPPLIER.LIST.ADD.SUPPLIER' })}</Link>
                            </div>
                        </div>
                    </PolicyChecker>

                </div>
                <KTCardBody className='table-responsive m-grid-responsive-md'>

                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>#</th>
                                <th>{intl.formatMessage({ id: 'SUPPLIER.CODE' })}</th>
                                <th>{intl.formatMessage({ id: 'SUPPLIER.NAME' })}</th>
                                <th>{intl.formatMessage({ id: 'SUPPLIER.STATUS' })}</th>
                                <th>{intl.formatMessage({ id: 'SUPPLIER.ACTIONS' })}</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {
                                supplierList && supplierList.map((item, index) => {
                                    return (
                                        (
                                            <tr key={item.id}>
                                                <td>
                                                    {++index}
                                                </td>
                                                <td>{item.code}</td>
                                                <td>{item.name}</td>
                                                <td>{item.isActive ? <div className="badge badge-light-success fw-bolder">{intl.formatMessage({ id: 'SUPPLIER.ACTIVE.ENABLED' })}</div> : <div className="badge badge-light-danger">{intl.formatMessage({ id: 'SUPPLIER.ACTIVE.LOCKED' })}</div>}</td>

                                                <td>
                                                    <a className='btn btn-light btn-active-light-primary btn-sm ' data-kt-menu-trigger="{default: 'click'}" data-kt-menu-placement='bottom-end'> {intl.formatMessage({ id: 'SUPPLIER.ACTIONS' })}
                                                        <KTIcon iconName='down' className='fs-5 m-0' />
                                                    </a>
                                                    <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
                                                        <div className='menu-item px-3'>
                                                            <Link to={`/catalog-management/suppliers/${item.id}`} className='menu-link px-3'> {intl.formatMessage({ id: 'SUPPLIER.ACTIONS.EDIT' })} </Link>
                                                        </div>
                                                        <div className='menu-item px-3'>
                                                            {
                                                                item.isActive ?
                                                                    <a className='menu-link px-3' onClick={() => onStatusChange(item.id, false)}> {intl.formatMessage({ id: 'SUPPLIER.ACTIONS.DEACTIVE' })} </a> :
                                                                    <a className='menu-link px-3' onClick={() => onStatusChange(item.id, true)}> {intl.formatMessage({ id: 'SUPPLIER.ACTIONS.ACTIVE' })} </a>
                                                            }

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </KTCardBody>
            </KTCard >
            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        supplierList: state.Supplier.supplierList,
        loading: state.Supplier.loading,
        success: state.Supplier.success
    };
};

const mapActionsToProps = {
    clearState, getSupplierList, changeStatus
}

export default connect(mapStateToProps, mapActionsToProps)(Suppliers)