import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import Moment from 'react-moment';
import moment from 'moment';

import 'moment/locale/en-gb';
import 'moment/locale/tr';
import 'moment/locale/de';
import 'moment/locale/nl';

var lang = localStorage.getItem("language") as string;
Moment.globalLocal = true;
Moment.globalFormat = 'ddd DD MMM yy HH:mm';
Moment.globalLocale = lang;
moment.locale(lang);

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
