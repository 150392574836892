import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { useIntl } from 'react-intl'
import ReCAPTCHA from "react-google-recaptcha";
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

const initialValues = {
  email: '',
  captcha: ''
}


export function ForgotPassword() {
  const intl = useIntl();
  let captcha;

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [done, setDone] = useState(false)

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "VALIDATION.WRONG_FORMAT" }))
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    captcha: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      requestPassword(values.email, values.captcha)
        .then(({ data: { result } }) => {
          setHasErrors(false)
          setLoading(false)
          setDone(true)
          captcha.reset();
        })
        .catch(() => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus(intl.formatMessage({ id: "AUTH.LOGIN.FAILED" }))
          setDone(false)
          captcha.reset();
        })
    },
  })

  const setCaptchaRef = (ref) => {
    if (ref) {
      return captcha = ref;
    }
  };

  function onChange(value) {
    formik.setFieldValue('captcha', value);
  }

  const form = () => {
    return (
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: "AUTH.FORGOT.TITLE" })}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            {intl.formatMessage({ id: "AUTH.FORGOT.DESC" })}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({ id: "AUTH.FORGOT.FAILED" })}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS" })}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <ReCAPTCHA ref={(r) => setCaptchaRef(r)} className="mb-5" name="captcha" sitekey={siteKey} onChange={onChange} />

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.captcha}
          >
            {!loading && <span className='indicator-label'> {intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}  </span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'AUTH.LOGIN.SUBMIT.BUTTON.LOADING' })}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              {intl.formatMessage({ id: "AUTH.GENERAL.CANCEL_BUTTON" })}
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    )
  }

  const doneform = () => {
    return (
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: "AUTH.FORGOT.TITLE" })}</h1>
        </div>

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded text-center'>
            <div className='text-info'>{intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS" })}</div>
          </div>
        )}

        <div className='text-center'>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-primary w-100 mb-5'
            >
              Return To Login
            </button>
          </Link>
        </div>
      </form>
    )
  }

  return (
    done ? doneform() : form()
  )
}
