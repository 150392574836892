// @flow
import types from './stockConstants';

const INIT_STATE = {
    variantList: [],
    variant: {},
    variantPrint: {},
    suppliers:[],
    chartData: {},
    loading: false,
    success: null,
    error: null
};

const Stock = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //List
        case types.GET_VARIANTS:
            return {
                ...state,
                loading: true
            };
        case types.GET_VARIANTS_SUCCESS:

            return {
                ...state,
                variantList: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_VARIANTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //List
        case types.GET_STOCKS:
            return {
                ...state,
                loading: true
            };
        case types.GET_STOCKS_SUCCESS:
            return {
                ...state,
                variant: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_STOCKS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List
        //Chart data
        case types.GET_STOCKS_CHARTS:
            return {
                ...state,
                chartData: action.payload.data,
            };
        //Chart data
        //create stock
        case types.POST_STOCKS:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_STOCKS_SUCCESS:
            return {
                ...state,
                success: 'STOCK.CREATED.SUCESS',
                loading: false,
                error: null
            };
        case types.POST_STOCKS_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //create stock
        //get variant
        case types.GET_VARIANT:
            return {
                ...state,
                loading: true
            };
        case types.GET_VARIANT_SUCCESS:
            return {
                ...state,
                variantPrint: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_VARIANT_FAILED:
            return {
                ...state,
                variantPrint: {},
                error: action.payload,
                loading: false
            };
        case types.GET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.payload.data.items,
                error: null,
                loading: false
            };
        //get variant

        default:
            return state;
    }
};

export default Stock;
