export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)

  var firstLevel = current.split('/')[1];
  var secondLevel = current.split('/')[2];

  var modifiedCurrent = firstLevel;
  if (secondLevel)
    modifiedCurrent = firstLevel + "/" + secondLevel;

  var urlFirstLevel = url.split('/')[1];
  var urlSecondLevel = url.split('/')[2];

  var modifiedUrl = urlFirstLevel;
  if (urlSecondLevel)
    modifiedUrl = urlFirstLevel + "/" + urlSecondLevel


  // console.log("current: " + modifiedCurrent);
  // console.log("url: " + modifiedUrl);

  if (!modifiedCurrent || !modifiedUrl) {
    return false
  }

  if (modifiedCurrent === modifiedUrl) {
    return true
  }

  if(urlFirstLevel == firstLevel && !urlSecondLevel)
  {
    return true;
  }


  // if (modifiedCurrent.indexOf(modifiedUrl) > -1) {
  //   return true
  // }

  return false
}
