/* PRICE */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'PRICE/CLEAR_STATE',

    GET_VERANDA_TYPES: 'PRICE/GET_VERANDA_TYPES',
    GET_PRODUCT_TYPES: 'PRICE/GET_PRODUCT_TYPES',
    GET_PRODUCT_COLORS: 'PRICE/GET_PRODUCT_COLORS',
    GET_VERANDA_PRICES: 'PRICE/GET_VERANDA_PRICES',

    PATCH_VERANDA_PRICES: 'PRICE/PATCH_VERANDA_PRICES',
    PATCH_VERANDA_PRICES_SUCCESS: 'PRICE/PATCH_VERANDA_PRICES_SUCCESS',
    PATCH_VERANDA_PRICES_FAILED: 'PRICE/PATCH_VERANDA_PRICES_FAILED',

    GET_UNIT_PRICES: 'PRICE/GET_UNIT_PRICES',
    GET_UNIT_TYPES: 'PRICE/GET_UNIT_TYPES',

    PATCH_UNIT_PRICES: 'PRICE/PATCH_UNIT_PRICES',
    PATCH_UNIT_PRICES_SUCCESS: 'PRICE/PATCH_UNIT_PRICES_SUCCESS',
    PATCH_UNIT_PRICES_FAILED: 'PRICE/PATCH_UNIT_PRICES_FAILED',

});

export default actionTypes;