// @flow
import types from './priceConstants';
import { getAsync, patchAsync, postAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getVerandaTypes = () => async dispatch => {
    const response = await getAsync(`/lookup/4/values?sorting=1`);
    dispatch({
        type: types.GET_VERANDA_TYPES,
        payload: response,
    })
}

export const getProductTypes = () => async dispatch => {
    const response = await getAsync(`/lookup/3/values`);
    dispatch({
        type: types.GET_PRODUCT_TYPES,
        payload: response,
    })
}

export const getColors = () => async dispatch => {

    const response = await getAsync(`/options/1/values`);
    dispatch({
        type: types.GET_PRODUCT_COLORS,
        payload: response,
    })
}


export const getVerandaPrices = () => async dispatch => {
    const response = await getAsync(`/veranda-prices`);
    dispatch({
        type: types.GET_VERANDA_PRICES,
        payload: response,
    })
}

export const patchVerandaPrices = (item) => async dispatch => {

    dispatch({
        type: types.PATCH_VERANDA_PRICES
    })

    const response = await patchAsync(`/veranda-prices/${item.id}`, item);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_VERANDA_PRICES_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_VERANDA_PRICES_FAILED,
            payload: response,
        })
    }
}

export const patchVerandaPricesPercentage = (type, percentage) => async dispatch => {
    dispatch({
        type: types.PATCH_VERANDA_PRICES
    })

    const response = await patchAsync(`/veranda-prices`, { type, percentage });

    if (response.isSuccess) {
        dispatch(getVerandaPrices())
        dispatch({
            type: types.PATCH_VERANDA_PRICES_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_VERANDA_PRICES_FAILED,
            payload: response,
        })
    }
}


export const getUnitPrices = () => async dispatch => {
    const response = await getAsync(`/unit-prices`);
    dispatch({
        type: types.GET_UNIT_PRICES,
        payload: response,
    })
}

export const getUnitTypes = () => async dispatch => {
    const response = await getAsync(`/lookup/5/values?sorting=1`);
    dispatch({
        type: types.GET_UNIT_TYPES,
        payload: response,
    })
}

export const patchUnitPrices = (item) => async dispatch => {

    dispatch({
        type: types.PATCH_UNIT_PRICES
    })

    const response = await patchAsync(`/unit-prices/${item.id}`, item);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_UNIT_PRICES_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_UNIT_PRICES_FAILED,
            payload: response,
        })
    }
}
