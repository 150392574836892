import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import Moment from 'react-moment';
import { PageTitle } from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, KTIcon, Loader } from '../../../_metronic/helpers'

import { clearState, getDevSupportTicketList, changeState } from './devSupportActions';

const DevSupportTickets = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        devSupportTicketList,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getDevSupportTicketList,
        changeState
    } = props;


    const [keyword, setKeyword] = useState();

    useEffect(() => {

        getDevSupportTicketList();

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        MenuComponent.reinitialization();
    }, [devSupportTicketList])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])


    useEffect(() => {
        if (keyword != undefined) {
            const timeOutId = setTimeout(() => getDevSupportTicketList(keyword), 400);
            return () => clearTimeout(timeOutId);
        }
    }, [keyword]);

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'DEV.SUPPORT.TITLE' }),
            path: '/dev-support/tickets',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onSearch = (value) => {
        setKeyword(value);
    }

    const onStateChange = (id, newState) => {
        changeState(id, newState);
    }

    const toState = (state) => {
        switch (state) {
            case 0: return 'Created';
            case 1: return 'In Progress';
            case 2: return 'Resolved';
            case 3: return 'Rejected';
            case 4: return 'Deleted';
            case 5: return 'Closed';
            default: return '-';
        }
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.BREADCRUMB' })}</PageTitle>

            <KTCard>

                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder={intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.SEARCH.TICKET' })} value={keyword} onChange={(e) => onSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                            <Link to={`/dev-support/tickets/0`} type="button" className="btn btn-success"><i className="ki-duotone ki-plus fs-2"></i>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.ADD.TICKET' })}</Link>
                        </div>
                    </div>
                </div>
                <KTCardBody className='table-responsive m-grid-responsive-md'>

                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>#</th>
                                <th>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.ID' })}</th>
                                <th>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.TITLE' })}</th>
                                <th>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.STATE' })}</th>
                                <th>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.CREATEDBY' })}</th>
                                <th>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.CREATEDON' })}</th>
                                <th>{intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.ACTIONS' })}</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {
                                devSupportTicketList && devSupportTicketList.map((item, index) => {
                                    return (
                                        (
                                            <tr key={item.id}>
                                                <td>
                                                    {++index}
                                                </td>
                                                <td>{item.id}</td>
                                                <td>{item.title}</td>
                                                <td>{toState(item.state)}</td>
                                                <td>{item.createdByName}</td>
                                                <td><Moment>{item.createdOn}</Moment></td>
                                                <td>
                                                    <a className='btn btn-light btn-active-light-primary btn-sm ' data-kt-menu-trigger="{default: 'click'}" data-kt-menu-placement='bottom-end'> {intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.ACTIONS' })}
                                                        <KTIcon iconName='down' className='fs-5 m-0' />
                                                    </a>
                                                    <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
                                                        <div className='menu-item px-3'>
                                                            <Link to={`/dev-support/tickets/${item.id}`} className='menu-link px-3'> {intl.formatMessage({ id: 'DEV.SUPPORT.TICKETS.LIST.ACTIONS.EDIT' })} </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </KTCardBody>
            </KTCard >
            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        devSupportTicketList: state.DevSupport.devSupportTicketList,
        loading: state.DevSupport.loading,
        success: state.DevSupport.success
    };
};

const mapActionsToProps = {
    clearState, getDevSupportTicketList, changeState
}

export default connect(mapStateToProps, mapActionsToProps)(DevSupportTickets)