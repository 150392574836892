// @flow
import types from './notificationConstants';

const INIT_STATE = {
    notifications: [],
    unreadNotifications: [],
    loading: false,
    success: null
};

const Notification = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //Get Notifications
        case types.GET_NOTIFICATIONS:
            return {
                ...state,
                loading: true
            };
        case types.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload.items,
                loading: false,
                error: null
            };
        case types.GET_NOTIFICATIONS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case types.GET_NOTIFICATIONS_UNREAD:
            return {
                ...state,
                loading: true
            };
        case types.GET_NOTIFICATIONS_UNREAD_SUCCESS:
            return {
                ...state,
                unreadNotifications: action.payload.items,
                loading: false,
                error: null
            };
        case types.GET_NOTIFICATIONS_UNREAD_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Notifications

        //patch Notification
        case types.PATCH_NOTIFICATION:
            return {
                ...state,
                loading: true
            };
        case types.PATCH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case types.PATCH_NOTIFICATION_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Patch Notification

        default:
            return state;
    }
};

export default Notification;
