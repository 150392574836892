import { FC } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'



const Item2: FC<any> = (props) => {

  var item = props.item

  if (item) {
    return (
      <div className='timeline-item'>
        <div className='timeline-line w-40px'></div>

        <div className='timeline-icon symbol symbol-circle symbol-40px'>
          <div className='symbol-label bg-light'>
            <KTIcon iconName='pointers' className='fs-2 text-gray-500' />
          </div>
        </div>

        <div className='timeline-content mb-10 mt-n2'>
          <div className='overflow-auto pe-3'>
            <div className='fs-5 fw-bold mb-2'>
              <a href={item.redirect ? item.redirect : "#"}>
                {item.subject}
              </a>

            </div>

            <div className='d-flex align-items-center mt-1 fs-6'>
              <div className='text-muted me-2 fs-7'>{item.body}
              </div>
              <span className='badge badge-light fs-8' style={{ marginLeft: "10px" }}>{item.createdOnString}</span>
            </div>

          </div>
        </div>
      </div>
    )
  }

  return (<></>)

}

export { Item2 }
