import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify';
import { PageTitle } from '../../../../_metronic/layout/core'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { KTCard, KTCardBody, Loader } from '../../../../_metronic/helpers'
import { Button } from 'reactstrap';
import { Audit } from '../../../../_metronic/helpers/components/Audit';

import { clearState, getSupplier, postSupplier, patchSupplier } from './supplierActions';

const Supplier = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    const navigate = useNavigate();
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        supplier,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getSupplier,
        postSupplier,
        patchSupplier
    } = props;


    useEffect(() => {

        if (params.id > 0)
            getSupplier(params.id);

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (params.id === '0' && supplier && supplier.id > 0) {
            navigate(`/catalog-management/suppliers/${supplier.id}`);
        }

    }, [supplier])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CATALOG.MANAGEMENT.TITLE' }),
            path: '/catalog-management/supplier',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const validation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }
    
    const handleValidSubmit = (event, values) => {

        if (supplier.id > 0)
        {
            patchSupplier(supplier.id, { ...values })
        } else {
            postSupplier({ ...values })
        }
    }

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'SUPPLIER.ITEM.BREADCRUMB' })}</PageTitle>

            <KTCard>
                <div className="card-header">
                    <h3 className="card-title">{intl.formatMessage({ id: 'SUPPLIER.PAGE.TITLE' })}</h3>
                    <Audit table="Supplier" identifier={supplier.id} />
                </div>
                <KTCardBody>
                    <AvForm key={1} onValidSubmit={handleValidSubmit}>

                        <AvField name="name" label={intl.formatMessage({ id: 'SUPPLIER.NAME' })} type="text" value={supplier && supplier.name} validate={validation} />
                        <AvField name="code" label={intl.formatMessage({ id: 'SUPPLIER.CODE' })} type="text" value={supplier && supplier.code} validate={validation} />
                        
                        <div className='mb-3'>
                            <label htmlFor="isActive" className="form-label">{intl.formatMessage({ id: 'GENERAL.CHECKBOX.ISACTIVE' })}</label>
                            <AvField type="checkbox" name="isActive" checked={supplier && supplier.isActive} />
                        </div>

                        <AvField type="hidden" name="id" value={supplier && supplier.id} />
                        <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT.BUTTON' })}</Button>
                    </AvForm>
                </KTCardBody>
            </KTCard >

            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        supplier: state.Supplier.supplier,
        loading: state.Supplier.loading,
        success: state.Supplier.success
    };
};

const mapActionsToProps = {
    clearState, getSupplier, postSupplier, patchSupplier
}

export default connect(mapStateToProps, mapActionsToProps)(Supplier)