import { useState } from 'react';
import '../../assets/sass/components/scanner.css';
import { getAsync, putAsync } from '../httpClient';
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Loader } from '../../../_metronic/helpers'

const Content = (props) => {

    const intl = useIntl()

    const [list, setList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const onContentClick = () => {
        setLoading(true);
        getAsync(`/contents?table=${props.table}&identifier=${props.identifier}&field=${props.field}`).then((response) => {
            setList(response.data);
            setShowModal(true);
            setLoading(false);
        })
    }

    const onContentSave = (item) => {
        setLoading(true);
        putAsync(`/contents`, item).then((response) => {
            setLoading(false);
        })
    }

    const onValueChange = (e, item) => {
        var newList = list.map((x) => {
            if (x.languageCode === item.languageCode) {
                x.value = e;
            }
            return x;
        })
        setList(newList)
    }

    const renderModal = () => {
        return (
            <Modal size="xl" centered show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.formatMessage({ id: 'CONTENT.TITLE' })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='table-responsive' style={{ overflowY: "auto", height: "500px" }}>
                        <table className='table table-bordered text-center'>
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'CONTENT.FIELD.LANGUAGE' })}</th>
                                    <th>{intl.formatMessage({ id: 'CONTENT.FIELD.VALUE' })}</th>
                                    <th>#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list && list.map((item, i) => {
                                        return (
                                            <tr key={`content_table_row_${item.languageCode}`}>
                                                <td>{item.languageName}</td>
                                                <td> <input name="value" className='form-control' value={item.value} onChange={(e) => onValueChange(e.target.value, item)}></input>  </td>
                                                <td>
                                                    <button type="button" className="btn btn-light" onClick={() => onContentSave(item)}>{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</button>
                                                </td>
                                            </tr>
                                        )

                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" onClick={() => setShowModal(false)}>Close</button>
                </Modal.Footer>
                {loading && <Loader></Loader>}
            </Modal>
        )
    }

    return (
        <>
            <i title="Content" className={`ki-duotone ki-flag ${props.iconSize ? props.iconSize : 'fs-1'}`} style={props.style ? props.style : { float: "right", marginLeft: "10px" }} onClick={() => onContentClick()}>
                <span className="path1"></span>
                <span className="path2"></span>
            </i>

            {renderModal()}
        </>
    );
}

export { Content };