import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { KTIcon, Loader } from '../../../../_metronic/helpers'
import { Scanner } from '../../../../_metronic/helpers/components/Scanner';
import { Button, Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import Quagga from '@ericblade/quagga2';

import { clearState, postStock, getVariant } from './stockActions';

const StockOperation = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl();
    const navigate = useNavigate();

    const [lockInput, setLockInput] = useState(false);
    const [stock, setStock] = useState(0);
    const [variantCode, setVariantCode] = useState('');
    const [variantInfo, setVariantInfo] = useState('');
    const [decrease, setDecrease] = useState(1)
    const [increase, setIncrease] = useState(1)
    const [scanning, setScanning] = useState(false);

    //inputs from redux
    const {
        variant,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        postStock,
        getVariant
    } = props;

    useEffect(() => {

        if (params.id > 0) {
            getVariant(params.id);
            setLockInput(true);
        }

        return () => {
            setScanning(false);
            clearState();
        }
    }, []);

    useEffect(() => {

        if (variant && variant.id > 0) {
            getVariant(params.id);
            onResetClick();
        }

    }, [params.id]);

    useEffect(() => {
        if (variant && variant.id > 0) {
            setVariantCode(variant.code);
            setLockInput(true);
            setVariantInfo(`${variant.product && variant.product.name} / ${variant.name} / ${intl.formatMessage({ id: 'STOCK.FIELD.CURRENTSTOCKQUANTITY' })}: ${variant.stockQuantity} / ${intl.formatMessage({ id: 'STOCK.FIELD.POSITION' })} : ${variant.product.position}`)
        }
    }, [variant]);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
            onResetClick();
        }
    }, [success])

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'STOCK.MANAGEMENT.TITLE' }),
            path: '/stock-management/stocks',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onRadioButtonClick = (value) => {
        setIncrease(parseInt(value))
        setDecrease(parseInt(value))
    }

    const onDecreaseClick = () => {
        setStock(Number(stock) - Number(decrease));
    }

    const onIncreaseClick = () => {
        setStock(Number(stock) + Number(increase));
    }

    const onResetClick = () => {
        setStock(0);
        if (params.id == 0) {
            setVariantCode('');
            setVariantInfo('');
            setLockInput(false);
        }
    }

    const handleStockSubmit = () => {

        if (stock == 0) {
            toast.error(intl.formatMessage({ id: 'STOCK.SHOULDNOT.ZERO' }));
            return;
        }

        if (variant.id == 0) {
            toast.error(intl.formatMessage({ id: 'STOCK.VARIANT.NOTFOUND' }));
            return;
        }

        postStock(variant.id, stock);
    }

    const onStockQuantityChange = (e) => {
        setStock(Number(e.target.value));
    }

    const onVariantCodeChanged = (e) => {
        if (e.key == "Enter" && e.target.value) {
            getVariant(null, e.target.value);
        }
    }

    const renderRadioButtons = (label, name, value, index) => {
        return (
            <div className='col'>
                <input
                    type='radio'
                    className='btn-check'
                    name={name}
                    value={value}
                    id={`kt_account_team_size_select_1_${index}`}
                    onClick={(e) => onRadioButtonClick(e.target.value)}
                />
                <label
                    className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                    htmlFor={`kt_account_team_size_select_1_${index}`}
                >
                    <span className='fw-bolder fs-3'>{label}</span>
                </label>
            </div>

        )
    }


    const onCameraOpen = () => {
        setScanning(!scanning);
    }

    const onScanDetected = (result) => {
        setScanning(false);
        onResetClick();
        setVariantCode(result);
        getVariant(null, result);
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'STOCK.OPERATION.BREADCRUMB' })}</PageTitle>

            <Card>
                <CardBody>
                    <AvForm>
                        <button onClick={() => onCameraOpen()} type="button" className="btn btn-secondary mb-5">{scanning ? "Close" : "Open"}<KTIcon iconName='scan-barcode' className='fs-2x'></KTIcon></button>
                        <Row className='text-center mb-5'>
                            <Scanner scanning={scanning} onDetected={onScanDetected} />
                        </Row>

                        <div className='d-flex flex-column'>
                            <div className="text-center">
                                <h1 className="fs-2hx fw-bold mb-5">{intl.formatMessage({ id: 'STOCK.FIELD.ADDDROPSTOCK' })}</h1>
                                <div className="text-gray-600 fw-semibold">
                                    Type Variant Code <br></br> Scan Variant Barcode with Scanner
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-column'>
                            <div className="text-center">
                                <h1 className="fs-2hx fw-bold">
                                    <label className="form-label">{variantInfo ? <Link to={`/catalog-management/products/${variant.productId}`} >{variantInfo}</Link> : <></>}</label>
                                    {lockInput ?
                                        <AvField type='text' autoComplete="off" readOnly={true} name="variantCode" className='form-control form-control-solid text-center fs-2hx' value={variantCode} /> :
                                        <AvField type='text' autoComplete="off" name="variantCode" id="js-variant-code" className='form-control form-control-solid text-center fs-2hx' value={variantCode} onKeyUp={(e) => onVariantCodeChanged(e)} />
                                    }
                                </h1>
                            </div>
                        </div>

                        <div className='d-flex flex-column'>
                            <div className="text-center">
                                <h1 className="fs-2hx fw-bold">
                                    <label className="form-label">{intl.formatMessage({ id: 'STOCK.FIELD.INOUTQUANTITY' })}</label>
                                    <AvField type='number' name="stock" className='form-control form-control-solid text-center fs-2hx' value={stock} onKeyUp={(e) => onStockQuantityChange(e)} />
                                </h1>
                            </div>
                        </div>

                        <div className="row g-10">
                            <div className="col-6">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-5 px-5">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-gray-900 fw-bolder">{intl.formatMessage({ id: 'STOCK.FIELD.DECREASE' })}</h1>
                                        </div>
                                        <button className="btn btn-sm btn-danger fs-5x w-100" onClick={() => onDecreaseClick()} type='button'>-{decrease}</button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-5 px-5">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-gray-900 fw-bolder">{intl.formatMessage({ id: 'STOCK.FIELD.INCREASE' })}</h1>
                                        </div>
                                        <button className="btn btn-sm btn-primary fs-5x w-100" onClick={() => onIncreaseClick()} type='button'>+{increase}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <div className="col-md-12">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-5 px-5">
                                        <div className="mb-7 text-center">
                                            <h1 className="text-gray-900 fw-bolder">{intl.formatMessage({ id: 'STOCK.FIELD.MULTIPIER' })}</h1>
                                            <div className='row' data-kt-buttons='true'>
                                                {renderRadioButtons('x1', 'stockQuantity', 1, 1)}
                                                {renderRadioButtons('x5', 'stockQuantity', 5, 2)}
                                                {renderRadioButtons('x10', 'stockQuantity', 10, 3)}
                                                {renderRadioButtons('x50', 'stockQuantity', 50, 4)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <div className="d-flex h-100 align-items-center mb-10">
                                    <div className="w-100 d-flex flex-column flex-center">
                                        <button className="btn btn-sm btn-warning fs-3x w-100" onClick={() => onResetClick()} type='button'>{intl.formatMessage({ id: 'GENERAL.RESET' })}</button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="d-flex h-100 align-items-center">
                                    <div className="w-100 d-flex flex-column flex-center ">
                                        <button className="btn btn-sm btn-primary fs-3x w-100" type='button' onClick={() => handleStockSubmit()} disabled={!variantCode || !stock}>{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </CardBody>
            </Card>


            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        variant: state.Stock.variantPrint,
        loading: state.Stock.loading,
        success: state.Stock.success
    };

};

const mapActionsToProps = {
    clearState, postStock, getVariant
}

export default connect(mapStateToProps, mapActionsToProps)(StockOperation)