import axios from 'axios'
import { toast } from 'react-toastify';

let apiUrl = process.env.REACT_APP_API_URL;

export const getAsync = async (path, showError = true, headers = null) => {

    try {
        const response = await axios.get(`${apiUrl}${path}`);
        return { data: response.data, isSuccess: isResponseSuccess(response.status) };
    } catch (error) {
        throwError(error, showError);
        return { isSuccess: false, message: error && error.response && error.response.data && error.response.data.message };
    }
}

export const postAsync = async (path, data, showError = true) => {

    try {
        const response = await axios.post(`${apiUrl}${path}`, data);
        return { data: response.data, isSuccess: isResponseSuccess(response.status) };
    } catch (error) {
        throwError(error, showError);
        return { isSuccess: false, message: error && error.response && error.response.data && error.response.data.message };
    }
}

export const putAsync = async (path, data, showError = true) => {

    try {
        const response = await axios.put(`${apiUrl}${path}`, data);
        return { data: response.data, isSuccess: isResponseSuccess(response.status) };
    } catch (error) {
        throwError(error, showError);
        return { isSuccess: false, message: error && error.response && error.response.data && error.response.data.message };
    }
}

export const patchAsync = async (path, data, showError = true) => {

    try {
        const response = await axios.patch(`${apiUrl}${path}`, data);
        return { data: response.data, isSuccess: isResponseSuccess(response.status) };
    } catch (error) {
        throwError(error, showError);
        return { isSuccess: false, message: error && error.response && error.response.data && error.response.data.message };
    }
}

export const deleteAsync = async (path, showError = true) => {

    try {
        const response = await axios.delete(`${apiUrl}${path}`);
        return { data: response.data, isSuccess: isResponseSuccess(response.status) };
    } catch (error) {
        throwError(error, showError);
        return { isSuccess: false, message: error && error.response && error.response.data && error.response.data.message };
    }
    return { isSuccess: false };
}

export const uploadAsync = async (url, data, successFunc, errorFunc, showError = true) => {
    const config = {
        headers: {
            'Content-Type': data.file.type
        }
    }

    axios.put(url, data.file, config)
        .then(response => {
            if (isResponseSuccess(response.status))
                successFunc(data.id);
        })
        .catch(error => {
            //errorFunc(data.id)
            if (showError) {
                toast.error(`${error}`);
            }
        });
}

export const upload = async (url, data, showError = true) => {
    const config = {
        headers: {
            'Content-Type': data.file.type
        }
    }

    await axios.put(url, data.file, config)
        .then(response => {
            return response;
        })
        .catch(error => {
            if (showError) {
                toast.error(`${error}`);
            }
        });
}

const isResponseSuccess = (status) => {
    return status >= 200 && status < 300;
}

const throwError = (error, showError) => {
    var message = `${error && error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Bir Hata Oluştu'}`;
    if (showError) {
        toast.error(message);
    }
    else {
        console.log(message)
    }
}