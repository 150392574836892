import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../../app/modules/auth'
import { KTSVG, KTCardBody, Loader, toAbsoluteUrl, KTIcon, enumProductTypes, enumUnitPriceTypes, enumVerandaPriceTypes } from '../../../_metronic/helpers'
import { Button, Row, Col, CardBody, Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';

import { clearState, thanksPage } from './checkoutActions';


const CheckoutThanks = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const [inLoading, setInloading] = useState(false);

    //inputs from redux
    const {
        order,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        thanksPage
    } = props;

    useEffect(() => {
        thanksPage(params.id)
        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (success) {
        }
    }, [success])

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CHECKOUTSTHANKS.BREADCRUMB.TITLE' }),
            path: '/checkout-management/checkout-thanks',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]


    const renderThanks = () => {
        if (order && order.id > 0) {
            return (
                <Card>
                    <CardBody className='text-center'>
                        <i class="bi bi-check-circle fs-5hx text-primary"><span className='text-dark'>{intl.formatMessage({ id: 'CHECKOUTSTHANKS.FIELD.THANKS' })}</span></i>
                        <Row className='mt-10'></Row>
                        <h1>{intl.formatMessage({ id: 'CHECKOUTSTHANKS.FIELD.ORDERHASARRIVED' })}</h1>
                        <br></br>
                        <h3>{intl.formatMessage({ id: 'CHECKOUTSTHANKS.FIELD.ORDERNUMBER' })}: {params.id}</h3>
                        <Row>
                            <Col md={4}>
                            </Col>
                            <Col md={4}>
                                <button onClick={() => navigate(`/order-management/orders/${params.id}`)} type='submit' className='btn btn-primary mt-10'><span className='fs-2'>{intl.formatMessage({ id: 'CHECKOUTSTHANKS.FIELD.GOTOORDERS' })} </span></button>
                            </Col>
                            <Col md={4}>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            )
        }
    }

    const renderStatus = () => {
        if (order && order.message) {
            return (
                <Card>
                    <CardBody className='text-center'>
                        <i className="bi bi-x-circle fs-5hx text-danger"></i>
                        <Row className='mt-10'></Row>
                        <h1>{order.message}</h1>
                        <br></br>
                        <h3>{intl.formatMessage({ id: 'CHECKOUTSTHANKS.FIELD.ORDERNUMBER' })}: {params.id}</h3>
                        <Row>
                            <Col md={4}>
                            </Col>
                            <Col md={4}>
                                <button onClick={() => navigate(`/checkout-management/checkout-summary`)} type='submit' className='btn btn-danger mt-10'><span className='fs-3'>{intl.formatMessage({ id: 'CHECKOUTSTHANKS.FIELD.GOTOCHECKOUT' })} </span></button>
                            </Col>
                            <Col md={4}>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            )
        }
    }

    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'CHECKOUTSTHANKS.BREADCRUMB.DESCRIPTION' })}</PageTitle>

            {renderThanks()}
            {renderStatus()}

            {(loading || inLoading) && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.Checkout.loading,
        order: state.Checkout.order,
        success: state.Checkout.success
    };
};

const mapActionsToProps = {
    clearState, thanksPage
}

export default connect(mapStateToProps, mapActionsToProps)(CheckoutThanks)