import { useDraggable } from '@dnd-kit/core';

function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `draggable_${props.id}`,
    data: props.data
  });

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0px)`,
    position:'relative',
    zIndex: 99999
  } : undefined;

  return (
    <div ref={setNodeRef} style={{ ...style, display: props.data.show ? "" : "none" }} {...listeners} {...attributes} className={props.className}>
      {props.children}
    </div>
  );
}

export default Draggable;