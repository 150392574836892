/* DEV_SUPPORT */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'DEV_SUPPORT/CLEAR_STATE',

    GET_DEV_SUPPORT_TICKETS: 'DEV_SUPPORT/GET_DEV_SUPPORT_TICKETS',
    GET_DEV_SUPPORT_TICKETS_SUCCESS: 'DEV_SUPPORT/GET_DEV_SUPPORT_TICKETS_SUCCESS',
    GET_DEV_SUPPORT_TICKETS_FAILED: 'DEV_SUPPORT/GET_DEV_SUPPORT_TICKETS_FAILED',

    PATCH_DEV_SUPPORT_TICKET_STATE: 'DEV_SUPPORT/PATCH_DEV_SUPPORT_TICKET_STATE',
    PATCH_DEV_SUPPORT_TICKET_STATE_SUCCESS: 'DEV_SUPPORT/PATCH_DEV_SUPPORT_TICKET_STATE_SUCCESS',
    PATCH_DEV_SUPPORT_TICKET_STATE_FAILED: 'DEV_SUPPORT/PATCH_DEV_SUPPORT_TICKET_STATE_FAILED',

    GET_DEV_SUPPORT_TICKET: 'DEV_SUPPORT/GET_DEV_SUPPORT_TICKET',
    GET_DEV_SUPPORT_TICKET_SUCCESS: 'DEV_SUPPORT/GET_DEV_SUPPORT_TICKET_SUCCESS',
    GET_DEV_SUPPORT_TICKET_FAILED: 'DEV_SUPPORT/GET_DEV_SUPPORT_TICKET_FAILED',

    PATCH_DEV_SUPPORT_TICKET: 'DEV_SUPPORT/PATCH_DEV_SUPPORT_TICKET',
    PATCH_DEV_SUPPORT_TICKET_SUCCESS: 'DEV_SUPPORT/PATCH_DEV_SUPPORT_TICKET_SUCCESS',
    PATCH_DEV_SUPPORT_TICKET_FAILED: 'DEV_SUPPORT/PATCH_DEV_SUPPORT_TICKET_FAILED',

    POST_DEV_SUPPORT_TICKET: 'DEV_SUPPORT/POST_DEV_SUPPORT_TICKET',
    POST_DEV_SUPPORT_TICKET_SUCCESS: 'DEV_SUPPORT/POST_DEV_SUPPORT_TICKET_SUCCESS',
    POST_DEV_SUPPORT_TICKET_FAILED: 'DEV_SUPPORT/POST_DEV_SUPPORT_TICKET_FAILED',
});

export default actionTypes;