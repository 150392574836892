const Loader = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(45% )',
    left: 'calc(45% )',
    opacity: '1'
  }

  const xx = {
    backgroundColor: "#ffffff",
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    opacity: '85%'
  }

  return (
    <div style={{ ...xx, position: 'fixed' }}>
      <div style={{ ...styles, position: 'fixed', textAlign: 'center' }}>
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-gray-800 fs-3 fw-semibold" style={{ marginLeft: '10px' }}>Loading...</span>
      </div>
    </div>

  )



}

export { Loader }