import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTCard, KTCardBody, Loader, toAbsoluteUrl, KTIcon } from '../../../_metronic/helpers'
import { Button, Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { Audit } from '../../../_metronic/helpers/components/Audit';
import { Content } from '../../../_metronic/helpers/components/Content';

import { clearState, getVerandaTypes, getVerandaPrices, patchVerandaPrices, patchVerandaPricesPercentage } from './priceActions';

const Veranda = (props) => {
    //inputs from url
    const intl = useIntl();

    //inputs from redux
    const {
        verandaTypes,
        verandaPrices,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        getVerandaTypes,
        getVerandaPrices,
        patchVerandaPrices,
        patchVerandaPricesPercentage
    } = props;

    const [priceList, setPriceList] = useState([]);
    const [percentage, setPercentage] = useState({});

    useEffect(() => {

        getVerandaTypes();
        getVerandaPrices();

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (verandaPrices) {
            setPriceList(verandaPrices.map(s => { return { ...s } }))
        }

    }, [verandaPrices])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));

            if (success == 'VERANDA_TYPE.CREATED.SUCESS') {
                getVerandaTypes();
            }
        }
    }, [success])

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'PRICE.BREADCRUMB.TITLE' }),
            path: '/price-management/unit',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const onCellChange = (item, e) => {
        if (!isNaN(e.target.value)) {
            var value = Number(e.target.value);
            setPriceList(() => {
                var index = priceList.findIndex(s => s.id == item.id);
                var list = [...priceList];
                list[index].price = value;
                return list;
            })
        }
    }

    const onCellSave = (item) => {
        var record = verandaPrices.find(s => s.id == item.id);
        //if (item.price != record.price) {
        patchVerandaPrices(item)
        //}
    }

    const onPercentageClick = (type) => {
        var value = percentage[type];
        if (value)
            patchVerandaPricesPercentage(type, value);
    }

    const renderPriceTable = (type) => {
        var list = priceList && priceList.filter(s => s.type == type);
        if (!list || list.length == 0) return;

        var rows = [...new Set(list.map(s => s.depth))];
        if (!rows || rows.length == 0) return;

        var columns = [...new Set(list.map(s => s.width))];
        if (!columns || columns.length == 0) return;

        return (
            <>
                <Row>
                    <Col md={3}>
                        <div className='mb-3'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'VERANDA.FIELD.CHANGEPERCENTAGE' })} </label>
                            <input type='number' autoComplete='off' className='form-control bg-transparent' value={percentage[type]} onChange={(e) => setPercentage({ ...percentage, [type]: e.target.value })} />
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='mb-3'>
                            <button className='form-control btn btn-primary mt-6' onClick={() => onPercentageClick(type)}>{intl.formatMessage({ id: 'GENERAL.UPDATE' })}</button>
                        </div>
                    </Col>
                </Row>
                <div className='table-responsive'>
                    <table className='table table-bordered table-striped align-middle text-center'>
                        <thead>
                            <tr>
                                <th>€</th>
                                {columns.map((item, index) => {
                                    return (
                                        <th key={`columns_${index}`}>{item} cm</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => {
                                return (
                                    <tr key={`row_${index}`}>
                                        <td>{row} cm</td>
                                        {columns.map((column, index) => {
                                            var cell = list.find(s => s.width == column && s.depth == row);
                                            return (
                                                <td key={`cell_${index}`} style={{ textAlign: "-webkit-center" }}>
                                                    <div className="input-group input-group-sm" style={{ width: "120px" }}>
                                                        <input className="form-control" name="price" type="text" value={cell.price} autoComplete='off' onChange={(e) => onCellChange(cell, e)} />
                                                        <button className="input-group-text" onClick={() => onCellSave(cell)} ><i className="bi bi-save"></i></button>
                                                        <Audit table="VerandaPrice" identifier={cell.id} iconSize=" fs-1 " />
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        )

    }

    const renderPrices = () => {
        if (!verandaTypes) return;

        return (
            <div>
                {
                    verandaTypes.map((item, index) => {
                        return (
                            <Card key={`price_${index}`}>
                                <CardHeader>
                                    <CardTitle>{item.name} <Content table="LookupTypeValue" identifier={item.id} field={"Name"} /></CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {renderPriceTable(item.value)}
                                </CardBody>
                            </Card>
                        )
                    })
                }


            </div>

        )
    }

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'VERANDA.BREADCRUMB.DESCRIPTION' })}</PageTitle>

            {renderPrices()}

            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        verandaTypes: state.Price.verandaTypes,
        verandaPrices: state.Price.verandaPrices,
        success: state.Price.success,
        loading: state.Price.loading
    };
};

const mapActionsToProps = {
    clearState, getVerandaTypes, getVerandaPrices, patchVerandaPrices, patchVerandaPricesPercentage
}

export default connect(mapStateToProps, mapActionsToProps)(Veranda)