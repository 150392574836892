/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUser, changePass } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { useIntl } from 'react-intl'
import ReCAPTCHA from "react-google-recaptcha";
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

const initialValues = {
  email: '',
  tempPassword: '',
  password: '',
  captcha: ''
}

export function ChangePassword() {

  const intl = useIntl();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  let captcha;

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "VALIDATION.WRONG_FORMAT" }))
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    tempPassword: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    password: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    captcha: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {

      setLoading(true)
      try {
        const { data: auth } = await changePass(values.email, values.tempPassword, values.password, values.captcha)
        saveAuth(auth)
        const { data: user } = await getUser()
        setCurrentUser(user)
      } catch (error: any) {

        saveAuth(undefined)
        setStatus(intl.formatMessage({ id: "AUTH.LOGIN.FAILED" }))
        setSubmitting(false)
        setLoading(false)

        captcha.reset();

      }
    },
  })

  const setCaptchaRef = (ref) => {
    if (ref) {
      return captcha = ref;
    }
  };

  function onChange(value) {
    formik.setFieldValue('captcha', value);
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: 'AUTH.CHANGE_PASSWORD.TITLE' })}</h1>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'AUTH.INPUT.TEMP_PASSWORD' })}</label>
        <input
          placeholder='Temp Password'
          {...formik.getFieldProps('tempPassword')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.tempPassword && formik.errors.tempPassword },
            {
              'is-valid': formik.touched.tempPassword && !formik.errors.tempPassword,
            }
          )}
          type='password'
          name='tempPassword'
          autoComplete='off'
        />
        {formik.touched.tempPassword && formik.errors.tempPassword && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.tempPassword}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}</label>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-success'>
          {intl.formatMessage({ id: "AUTH.LOGIN.FORGOT_TITLE" })}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      <ReCAPTCHA ref={(r) => setCaptchaRef(r)} className="mb-5" name="captcha" sitekey={siteKey} onChange={onChange} />

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-success'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'> {intl.formatMessage({ id: 'AUTH.LOGIN.SUBMIT.BUTTON' })} </span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>

              {intl.formatMessage({ id: 'AUTH.LOGIN.SUBMIT.BUTTON.LOADING' })}

              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>

        <Link to='/auth/registration' className='link-primary'>

          {intl.formatMessage({ id: 'AUTH.LOGIN.SIGNUP.LINK' })}
        </Link>
      </div>

    </form>
  )
}
